import { Divider, Flex } from 'antd'
import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { CopySCIcon, CutSCIcon, DrawWallsSCIcon, ExitDrawModeSCIcon, LockUnlockWallSCIcon, MeasurementSCIcon, PasteSCIcon, RedoSCIcon, RotateSCIcon, SelectMultipleItemsSCIcon, SelectSCShortCut, SSSCIcon, UndoSCIcon, ActionSCShortcutIcon, DeleteSCIcon, GroupSCIcon, UngroupSCIcon, BringToFontSCIcon, BringToBackSCIcon, HFlipSCIcon, VFlipSCIcon, ZoomSCIcon, MoveSCIcon } from '../../common/icons/shortcutpanelIcons.js/scpanelIcons'
import Section from '../../common/section/section'





function ShortcutPanel({ handleVisible }) {
    const { t } = useTranslation();
    const firstCol = [
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 , whiteSpace:'nowrap'}}> {t('drawWalls')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <DrawWallsSCIcon />
                </div>
            </Flex>)
        },
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 , whiteSpace:'nowrap'}}> {t('Exit Draw Mode')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <ExitDrawModeSCIcon />
                </div>
            </Flex>)
        },
        {
            content: (
                < Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 60, whiteSpace:'nowrap' }}> {t('Lock/Unlock Wall')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }}>
                        <LockUnlockWallSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                < Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 12, whiteSpace:'nowrap' }}> {t('Select')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }}>
                        <SelectSCShortCut />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                < Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 12, whiteSpace:'nowrap' }}> {t('Select Multiple Items')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }}>
                        <SelectMultipleItemsSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 12, whiteSpace:'nowrap' }}> {t('delete')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }}>
                        <DeleteSCIcon />
                    </div>
                </Flex >
            )
        }
    ];

    const secondCol = [
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5 , whiteSpace:'nowrap' }}> {t('toActions')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <ActionSCShortcutIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 12.5 , whiteSpace:'nowrap'}}> {t('Group')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <GroupSCIcon />
                    </div>
                </Flex>
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 20 , whiteSpace:'nowrap'}}> {t('Ungroup')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <UngroupSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 10 , whiteSpace:'nowrap'}}> {t('undo')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <UndoSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 10 , whiteSpace:'nowrap'}}> {t('redo')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <RedoSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight: 50, whiteSpace:'nowrap' }}> {t('screenshot')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <SSSCIcon />
                    </div>
                </Flex >
            )
        },
    ];


    const thirdCol = [
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5, marginRight:40,whiteSpace:'nowrap', }}> {t('Show Measurements')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <MeasurementSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5,whiteSpace:'nowrap' }}> {t('Bring to Front')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <BringToFontSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (<Flex justify='space-between' align='center' >
                <span style={{ fontSize: 12.5 }}> {t('Bring to Back')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 5
                }}>
                    <BringToBackSCIcon />
                </div>
            </Flex >)
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5 }}> {t('Flip Horizontal')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <HFlipSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5 }}> {t('Flip Vertical')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <VFlipSCIcon />
                    </div>
                </Flex >
            )
        },
        {
            content: (
                <Flex justify='space-between' align='center' >
                    <span style={{ fontSize: 12.5 }}> {t('Rotate')} </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <RotateSCIcon />
                    </div>
                </Flex >
            )
        }
    ];

    const fourthCol = [
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 }}> {t('cut')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <CutSCIcon />
                </div>
            </Flex>)
        },
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 }}> {t('copy')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <CopySCIcon />
                </div>
            </Flex>)
        },
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 }}> {t('paste')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <PasteSCIcon />
                </div>
            </Flex>)
        },
        {
            content: (<Flex justify='space-between' align='center'>
                <span style={{ fontSize: 12.5 , whiteSpace:'nowrap' , marginRight:37}}> {t('Zoom')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <ZoomSCIcon />
                </div>
            </Flex>)
        },
        {
            content: (<Flex justify='space-between' align='center' gap={10}>
                <span style={{ fontSize: 12.5 }}> {t('Moving')} </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <MoveSCIcon />
                </div>
            </Flex>)
        },

    ];

    return (
        <Flex vertical style={{
            padding: '2px 0px',
            cursor: 'pointer',
            maxHeight: '20em'
        }}>
            {<Flex justify='space-between' style={{marginBottom:10, zIndex:1000}}>
                <span style={{
                    fontSize: 17,
                    fontWeight: 500
                }}>{t('Shortcuts')}</span>
                <CloseOutlined onClick={() => handleVisible(false)} height={15} width={15} style={{
                    marginRight: 5.5
                }} />
            </Flex>}
            {/* The Shortcut Sections */}
            <Section>
                <Flex gap={2} justify='space-between' align='flex-start'>
                    <Flex vertical gap={8} >
                        {firstCol.map((colVal) => {
                            return (
                                colVal.content
                            )
                        })}
                    </Flex>
                    <Divider type="vertical" style={{
                        height: '17vh'
                    }} />
                    <Flex vertical gap={8}>
                        {secondCol.map((colVal) => {
                            return (
                                colVal.content
                            )
                        })}
                    </Flex>
                    <Divider type="vertical" style={{
                        height: '17vh'
                    }} />
                    <Flex vertical gap={8}>
                        {thirdCol.map((colVal) => {
                            return (
                                colVal.content
                            )
                        })}
                    </Flex>
                    <Divider type="vertical" style={{
                        height: '17vh'
                    }} />
                    <Flex vertical gap={8}>
                        {fourthCol.map((colVal) => {
                            return (
                                colVal.content
                            )
                        })}
                    </Flex>
                </Flex>
            </Section>

        </Flex>
    )
}

export default ShortcutPanel
