import { createContext, useEffect, useReducer } from 'react';
import i18n from '../i18n'; // Import i18n for language switching


export const LanguageContext = createContext();

export const languageReducer = (state,action) => {
    switch(action.type){
        case 'SWITCH_LNG':
            return { ...state, languageCode: action.payload }
        default:
            return state;
    }
}

export const LanguageContextProvider = ({children}) => {
    const [state, languageDispatch] = useReducer(languageReducer, {
        languageCode: localStorage.getItem('languageCode') ?? "gb", // Default language set to 'gb' (English)
    });
    
    return (
        <LanguageContext.Provider value={{...state,languageDispatch}}>
            {children}
        </LanguageContext.Provider>
    )
}