import { Button, Card, Spin } from 'antd';
import React from 'react';
import config from '../../config/config.json';
import { useStripe, useElements, CardElement, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(config.REACT_APP_STRIPE_PUBLIC_KEY);


function StripeForm({ clientSecret, setPaymentSucceeded }) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) return;



        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
            },
        });

        if (error) {
            console.error('Payment failed:', error);
        } else if (paymentIntent.status === 'succeeded') {
            console.log('Payment succeeded!');
            setPaymentSucceeded(true);
        }
    };

    return (
        <Card
            title="Continue Payment"
            actions={[
                <Button
                    onClick={handleSubmit}
                    type="primary"
                    variant='filled'
                    style={{
                        width: '10vw'
                    }}
                > Submit </Button>
            ]}
        >
            <PaymentElement options={{ clientSecret }} />
        </Card>
    )
}


function PaymentForm({ clientSecret, setPaymentSucceeded }) {

    return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeForm clientSecret={clientSecret} setPaymentSucceeded ={setPaymentSucceeded}/>
        </Elements>
    )
}

export default PaymentForm
