import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../item-panel/item-panel.module.css'
import Panel from '../../common/panel';
import { Button, Col, Collapse, ColorPicker, Divider, Flex, Image, Radio, Row } from 'antd';
import Section from '../../common/section/section';
import sectionStyle from '../../common/section/section.module.css';
import collapseStyle from './floorPanel.module.css';
import floorImg1 from '../../../assets/Floors/Floor_01.png';
import floorImg2 from '../../../assets/Floors/Floor_02.png';
import floorImg3 from '../../../assets/Floors/Floor_03.png';
import floorImg4 from '../../../assets/Floors/Floor_04.png';
import floorImg5 from '../../../assets/Floors/Floor_05.png';
import floorImg6 from '../../../assets/Floors/Floor_06.png';
import floorImg7 from '../../../assets/Floors/Floor_07.png';
import floorImg8 from '../../../assets/Floors/Floor_08.png';
import floorImg9 from '../../../assets/Floors/Floor_09.png';
import floorImg10 from '../../../assets/Floors/Floor_10.png';
import floorImg11 from '../../../assets/Floors/Floor_11.png';
import floorImg12 from '../../../assets/Floors/Floor_12.png';
import floorImg13 from '../../../assets/Floors/Floor_13.png';
import floorImg14 from '../../../assets/Floors/Floor_14.png';
import floorImg15 from '../../../assets/Floors/Floor_15.png';

import { useFloorContext } from '../../../hooks/contextHooks/useFloorContext';
import { useSnapshotContext } from '../../../hooks/contextHooks/useSnapshotContext';
import { calculatePolygonArea } from '../../areaText/areaText';
import { useTranslation } from 'react-i18next';

function FloorPanel({ rightPanelOpen, selectedFloor, floors, setFloors }) {




    const [menuOpen, setMenuOpen] = useState(false);
    const { floorContext, floorDispatch } = useFloorContext();
    const { index, snapshotDispatch } = useSnapshotContext();
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const {t} = useTranslation();


    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const memoizedSelectedFloor = useMemo(() => {
        return floorContext.find((item) => item.id === selectedFloor);
    }, [selectedFloor]);

    const floorParquette = useMemo(() => {
        if (memoizedSelectedFloor) {
            switch (memoizedSelectedFloor.floor_type) {
                case '1':
                    return floorImg1;
                case '2':
                    return floorImg2;
                case '3':
                    return floorImg3;
                case '4':
                    return floorImg4;
                case '5':
                    return floorImg5;
                case '6':
                    return floorImg6;
                case '7':
                    return floorImg7;
                case '8':
                    return floorImg8;
                case '9':
                    return floorImg9;
                case '10':
                    return floorImg10;
                case '11':
                    return floorImg11;
                case '12':
                    return floorImg12;
                case '13':
                    return floorImg13;
                case '14':
                    return floorImg14;
                case '15':
                    return floorImg15;
                default:
                    return floorImg1;
            }
        }

    }, [selectedFloor])

    const area = useMemo(() => {
        let specificFloor = floorContext.filter(Boolean).find((item) => item.id === selectedFloor);
        let area = calculatePolygonArea(specificFloor?.pointList);
        return Math.floor(area) / 10000
    }, [floorContext]);

    const [selectedImg, setSelectedImg] = useState(floorParquette ?? floorImg1);
    const [selectedRoomType, setSelectedRoomType] = useState(memoizedSelectedFloor?.room_type ?? "Unnamed");
    const [selectedFloorType, setSelectedFloorType] = useState(memoizedSelectedFloor?.floor_type ?? "1");

    const rooms = [
        "Living Room",
        "Kitchen",
        "Bathroom",
        "Bedroom",
        "Children's Room",
        "Dining Room",
        "Office",
        "Meeting Room"
    ];

    const floorImgs = [
        floorImg1,
        floorImg2,
        floorImg3,
        floorImg4,
        floorImg5,
        floorImg6,
        floorImg7,
        floorImg8,
        floorImg9,
        floorImg10,
        floorImg11,
        floorImg12,
        floorImg13,
        floorImg14,
        floorImg15
    ]

    useEffect(() => {
        if (floorContext) {

            let updated = floorContext.map((item) => {
                if (item.id === selectedFloor) {
                    item.room_type = selectedRoomType;
                    item.floor_type = selectedFloorType;
                }
            })

            //floorDispatch({type:'GET_FLOORS',payload:updated});
            floorDispatch({ type: 'TRIGGER_FLOOR' });
        }

    }, [selectedRoomType, selectedFloorType]);


    const radioButtonList = () => {
        return (
            <Radio.Group 
                value={selectedRoomType} 
                onChange={(e) => setSelectedRoomType(e.target.value)} 
                onTouchStart={(e) => setSelectedRoomType(e.target.value)}
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}
            >
                {rooms.map((room, index) => (
                    <Flex vertical key={index} >
                        <Flex className={collapseStyle.menu}>
                            <span>{t(room)}</span>
                            <Radio value={t(room)} checked={selectedRoomType === room} />
                        </Flex>
                        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    </Flex>
                ))}
            </Radio.Group>
        );
    };

    const collapseItem = [
        {
            key: '1',
            label: <span>{t(selectedRoomType) === t('Unnamed') ? t('Room Not Selected') : t(selectedRoomType)}</span>,
            children: <>{radioButtonList()}</>,
        }
    ];

    const floorMenu = () => {
       
        return (
            <div style={{
                marginTop: 15
            }}>
                <Row gutter={[16, 16]}>
                    {floorImgs.map((img, index) => (
                        <Col span={screenWidth <= 1366 ? 8 : 6} value={index + 1} key={index}>
                            <Button icon={
                                <Image src={img} preview={false} style={{
                                    borderRadius: 3,
                                    display: 'flex',
                                    justify: 'center',
                                    height: 31,
                                    width: 33,

                                }} />
                            } style={{
                                height: 40,
                                width: 40,
                            }} onClick={() => {
                                setSelectedFloorType((index + 1).toString())
                                setSelectedImg(img)
                            }}
                                onTouchStart={() => {
                                    setSelectedFloorType((index + 1).toString())
                                    setSelectedImg(img)
                                }}
                                className={selectedImg === img ? collapseStyle.highlightedButton : ''}
                            >
                            </Button>
                        </Col>
                    ))}
                </Row>

            </div>
        )
    }

    const panel = () => {
        //const area = calculatePolygonArea(selectedFloor.points);
        
        return (
            <Flex vertical gap={10}>
                <Section className={sectionStyle.incrementer}>
                    <Flex vertical gap={5}>
                        <span style={{
                            marginBottom: 7
                        }}>{t('roomType')}</span>

                        <Collapse
                            items={collapseItem}
                            bordered={true}
                            className={collapseStyle.collapse}
                            expandIconPosition={'end'}
                            defaultActiveKey={['1']}
                            style={{
                                marginBottom: 5
                            }}
                        />
                        <Flex style={{
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <span>{t('roomSize')}</span>
                            <span>{area > 1
                                ? area.toFixed(0) + " m\u00B2"
                                : area > 0.1
                                    ? area.toFixed(1) + " m\u00B2"
                                    : area > 0.01
                                        ? area.toFixed(2) + " m\u00B2"
                                        : area.toFixed(3) + " m\u00B2"}</span>
                        </Flex>
                    </Flex>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Flex className={sectionStyle.flexbox}>
                        <span> {t('floor')} </span>
                        <Button icon={
                            <Image src={selectedImg} preview={false} style={{
                                borderRadius: 3,
                                display: 'flex',
                                justify: 'center',
                                height: 24,
                                width: 24
                            }} />
                        } onClick={() => setMenuOpen(!menuOpen)}>
                        </Button>
                    </Flex>
                    {menuOpen && floorMenu()}
                </Section>

            </Flex>
        )
    }

    return (
        <Panel className={(rightPanelOpen) ? styles.itemPanel : styles.itemPanelClosed}>
            {rightPanelOpen &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={styles.title}> {t('Room Settings')} </span>
                    {panel()}
                </div>
            }
        </Panel>
    )
}

export default FloorPanel