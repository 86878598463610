import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GroupShortcut, HFlip, RotateShortcut, UngroupShortcut, VFlip, ZIndexDecreaseShortcut, ZIndexIncreaseShortcut } from '../common/icons/shortcutIcons/shortcutIcons';
import { LockUnlockWallIconShortcut, ShortcutShortCut } from '../common/icons/icons';
import { getMenuItemColor } from '../../functions/menuaction/menuactions';
import { Divider } from 'antd';
import styles from './actionMenu.css';
import { detectItemCollision } from '../../functions/zoneFinder';
function ActionMenu({ selectedItem, selectedItemIndexes, transform, handleLockWalls, lockedWallsState, items, groupedItems, ActionMenuActions }) {

    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(-1);

    const collidedItems = useMemo(() => {
        let arr = [];
        if (selectedItem) {
            items.forEach((item) => {
                if (detectItemCollision(item, selectedItem) && item.id !== selectedItem.id) {
                    arr.push(item);
                }
            });
        }
        return arr;
    }, [items, selectedItem, transform]);

    const isSelectedItemOnTop = useMemo(() => {
        if (selectedItem && collidedItems.length > 0) {
            // Find the maximum zIndex among the collidedItems
            const maxZIndex = Math.max(...collidedItems.map(item => item.zIndex));

            if (selectedItem.zIndex > maxZIndex) {
                return true; // Selected item has a higher zIndex
            } else if (selectedItem.zIndex === maxZIndex) {
                // If zIndexes are equal, compare their index positions in the items array
                const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
                const maxZIndexItems = collidedItems.filter(item => item.zIndex === maxZIndex);
                const maxZIndexItemIndex = Math.max(...maxZIndexItems.map(item => items.findIndex(i => i.id === item.id)));

                return selectedItemIndex > maxZIndexItemIndex;
            } else {
                return false; // Selected item has a lower zIndex
            }
        }
        return false;
    }, [selectedItem, collidedItems, items]);

    const isSelectedItemOnBottom = useMemo(() => {
        if (selectedItem && collidedItems.length > 0) {
            // Find the minimum zIndex among the collidedItems
            const minZIndex = Math.min(...collidedItems.map(item => item.zIndex));

            if (selectedItem.zIndex < minZIndex) {
                return true; // Selected item has a lower zIndex
            } else if (selectedItem.zIndex === minZIndex) {
                // If zIndexes are equal, compare their index positions in the items array
                const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
                const minZIndexItems = collidedItems.filter(item => item.zIndex === minZIndex);
                const minZIndexItemIndex = Math.min(...minZIndexItems.map(item => items.findIndex(i => i.id === item.id)));

                return selectedItemIndex < minZIndexItemIndex;
            } else {
                return false; // Selected item has a higher zIndex
            }
        }
        return false;
    }, [selectedItem, collidedItems, items]);

    const isItemInGroup = useMemo(() => {
        let id = selectedItem?.id || "";
        let bool = true;
        for (let group of groupedItems) {
            if (group.includes(id)) {
                bool = false;
            }
        }
        return bool;
    }, [selectedItem, groupedItems]);


    const actionMenuItems = useMemo(() => {
        return [
            { text: t("Flip Horizontal"), func: () => ActionMenuActions.hFlip(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<VFlip color={hoverIndex === 0 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))} />) },
            { text: t("Flip Vertical"), func: () => ActionMenuActions.vFlip(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<HFlip color={hoverIndex === 1 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))} />) },
            { text: t("Rotate"), func: () => ActionMenuActions.rotate(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<RotateShortcut color={hoverIndex === 2 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))} />) },
            { text: "Divider" },
            { text: t("Bring to Front"), func: () => ActionMenuActions.zUp(), disabled: (isSelectedItemOnTop || collidedItems.length <= 0), icon: (<ZIndexIncreaseShortcut color={hoverIndex === 4 ? 'white' : getMenuItemColor((isSelectedItemOnTop || collidedItems.length <= 0))} />) },
            { text: t("Send to Back"), func: () => ActionMenuActions.zDown(), disabled: (isSelectedItemOnBottom || collidedItems.length <= 0), icon: (<ZIndexDecreaseShortcut color={hoverIndex === 5 ? 'white' : getMenuItemColor(((isSelectedItemOnBottom || collidedItems.length <= 0)))} />) },
            { text: "Divider" },
            { text: t("Group"), func: () => ActionMenuActions.group(), disabled: selectedItemIndexes?.length < 2 || !isItemInGroup, icon: (<GroupShortcut color={hoverIndex === 7 ? 'white' : getMenuItemColor(selectedItemIndexes?.length < 2 || !isItemInGroup)} />) },
            { text: t("Ungroup"), func: () => ActionMenuActions.ungroup(), disabled: groupedItems.length === 0 || selectedItemIndexes.length < 2, icon: (<UngroupShortcut color={hoverIndex === 8 ? 'white' : getMenuItemColor(groupedItems.length === 0 || selectedItemIndexes.length < 2)} />) },
            { text: "Divider" },
            { text: lockedWallsState ? t("Unlock Wall") : t("Lock Wall"), func: () => handleLockWalls(), disabled: false, icon: (<LockUnlockWallIconShortcut color={hoverIndex === 10 ? 'white' : getMenuItemColor(false)} />) },
            { text: "Divider" },
            { text: t("Shortcuts"), func: () => ActionMenuActions.shortcut(), disabled: false, icon: (<ShortcutShortCut color={hoverIndex === 12 ? 'white' : getMenuItemColor(false)} />) },
        ]
    }, [selectedItem, selectedItemIndexes, t, transform, hoverIndex,groupedItems,lockedWallsState]);

    return (
        <div style={{
            width: '206px',
        }} onMouseLeave={() => ActionMenuActions.close()}>
            {actionMenuItems.map((item, index) => {
                if (item.text === 'Divider') {
                    return (
                        <Divider style={{
                            margin: 2
                        }} />
                    )
                }
                else {
                    return (
                        <div style={{
                            display: 'flex',
                            padding: '10px 20px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                        }}
                            className={!item.disabled ? 'menuItemCustom' : 'menuItemCustomDisabled'}
                            onMouseEnter={() => setHoverIndex(index)}
                            onMouseLeave={() => {
                                setHoverIndex(-1);
                            }}
                            onClick={()  => {
                                item.func();
                                ActionMenuActions.close()
                            }}
                        >
                            <span style={{
                                fontSize:12,
                                marginRight:4
                            }}>{item.text}</span>
                            {item.icon}
                        </div>

                    )
                }
            })}
        </div>
    )
}

export default ActionMenu
