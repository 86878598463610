import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { Card, Flex, Avatar, Tooltip, Popconfirm, Input, Image } from 'antd'
import { ThreeDotIcon } from '../../common/icons/icons';
import styles from './projectcard.module.css'
import IconButton from '../../common/buttons/iconButton'
import { useProjectContext } from '../../../hooks/contextHooks/useProjectContext';
import { useDebounce } from '../../../hooks/asyncHooks/useDebounce';
import { useProjectAPI } from '../../../hooks/apiHooks/projectHooks/useProjectAPI';
import moment from 'moment';
import ScreenshotModal from '../../modals/screenshotModal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useLngContext } from '../../../hooks/contextHooks/useLngContext';

export default function ProjectCard({ projectId, img, title, description, item, sideImages = [], setSideImages }) {
    const { updateProject, deleteProject, createProject, getLastStateImage } = useProjectAPI();
    const { pDispatch } = useProjectContext();
    const [archived, setArchived] = useState(item.is_archived);
    const [rename, setRename] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const cardRef = useRef(null);
    const [lastImg, setLastImg] = useState(null);
    const timeoutRef = useRef(null);
    const { languageCode } = useLngContext();
    const { t } = useTranslation();


    let itemsArchived = [
        {
            tag: <span style={{ fontSize: 13 }}>{t('openInNewTab')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('openNewTabQuestion')
            },
            action: () => window.open('/project/' + item._id)
        },
        {
            tag: <span style={{ fontSize: 13 }}>{t('duplicate')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('copyQuestion')
            },
            action: () => handleCreateProject()
        },
        {
            tag: <span style={{ fontSize: 13 }}>{t('rename')}</span>,
            popconfirm: null,
            action: () => setRename(true)
        },
        {
            tag: <span style={{ fontSize: 13 }}>{t('archiveAction')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('archiveQuestion')
            },
            action: () => handleDropdown(item._id, { is_archived: true })
        },
        {
            tag: <span style={{ fontSize: 13 }}>{t('delete')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('deleteQuestion')
            },
            action: () => handleDeleteProject(item._id)
        }
    ];

    let itemsUnarchived = [
        {
            tag: <span style={{ fontSize: 13 }}>{t('restore')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('unarchiveQuestion')
            },
            action: () => handleDropdown(item._id, { is_archived: false })
        },
        {
            tag: <span style={{ fontSize: 13 }}>{t('delete')}</span>,
            popconfirm: {
                placement: 'bottom',
                title: t('warning'),
                description: t('deleteQuestion')
            },
            action: () => handleDeleteProject(item._id)
        }
    ];


    const dropdownItems = useMemo(() => {
        if (!archived) {
            return itemsArchived;
        }
        else {
            return itemsUnarchived
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archived, item]);


    const fetchLastImage = useCallback(async () => {
        const lastImage = await getLastStateImage(projectId);
        const pic = lastImage?.data?.img?.data;
    
        if (pic) {
            const base64String = btoa(
                new Uint8Array(pic.data)
                    .reduce((pic, byte) => pic + String.fromCharCode(byte), '')
            );
            const mimeType = pic.contentType; // Adjust this based on your actual image format
            const newVal = `data:${mimeType};base64,${base64String}`;
            setLastImg(newVal);
        }
    }, [projectId,item]);

    const handleDropdown = async (id, obj) => {
        await updateProject(id, obj);
        setArchived(obj.is_archived)
        setToggle(false);
    }

    const handleCreateProject = async () => {
        const result = await createProject({
            user_id: item.user_id,
            title: item.title + " (" + t('copyNoun') + ")",
            room_type: item.room_type,
            is_archived: item.is_archived,
            room_shape: item.room_shape,
            room_points: item.room_points,
            room_items: item.room_items,
            room_floors: item.room_floors
        })
        pDispatch({ type: 'TRIGGER' })
        let projectId = result.data._id;
        window.location.href = '/project/' + projectId;
    }

    const handleDeleteProject = async (id) => {
        await deleteProject(id);
        setToggle(false);
    }

    const handleRenameSave = useDebounce(async (value) => {
        await updateProject(item._id, { title: value });
        setRename(false);
        setToggle(false);
    }, 1000);

    const handleChange = (event) => {
        const { value } = event.target;
        handleRenameSave(value);
    };

    const handleProjectClick = (e) => {
        if (e.target.matches('h3')) {
            setClickCount(prev => prev + 1);

            if (clickCount === 1) {
                // Double-click detected
                clearTimeout(timeoutRef.current);
                setRename(true);
                setClickCount(0); // Reset click count
            } else {
                // Set timeout for single click
                timeoutRef.current = setTimeout(() => {
                    window.location.href = "/project/" + item._id;
                    setClickCount(0); // Reset click count
                }, 400);
            }
        } else {
            window.location.href = "/project/" + item._id;
        }
    };

    const handleClickOutside = (event) => {
        const isClickInsidePopover = event.target.closest('.ant-popover-content');
        if (cardRef.current && !cardRef.current.contains(event.target) && !isClickInsidePopover) {
            setToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside,true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside,true);
        };
    }, []);

    const { Meta } = Card;
    const [toggle, setToggle] = useState("");

    function updateMomentLocale(languageCode) {
        moment.updateLocale(languageCode, {
            relativeTime: {
                future: i18next.t('in %s'),
                past: i18next.t('%s ago'),
                s: i18next.t('a few seconds'),
                ss: i18next.t('%d seconds'),
                m: i18next.t('a minute'),
                mm: i18next.t('%d minutes'),
                h: i18next.t('an hour'),
                hh: i18next.t('%d hours'),
                d: i18next.t('a day'),
                dd: i18next.t('%d days'),
                M: i18next.t('a month'),
                MM: i18next.t('%d months'),
                y: i18next.t('a year'),
                yy: i18next.t('%d years')
            }
        });
    }

    useEffect(() => {
        // Initialize language and update Moment's locale on language change
        i18next.on('languageChanged', (lng) => {
            updateMomentLocale(lng);
        });

        // Initial setup
        updateMomentLocale(i18next.language);
    }, [languageCode]);

    useEffect(() => {
        if (clickCount === 2) {
            setRename(true);
            setClickCount(0);
        }
    }, [clickCount]);

    useEffect(() => {
        fetchLastImage();
    }, [projectId,item]);

    const memoizedSideImages = useMemo(() => {
        if (sideImages !== undefined) {
            return sideImages.filter((item) => item.project_id === projectId);
        }
        else {
            return [{ src: img }]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, sideImages]);

    return (
        <Card
            ref={cardRef}
            size="default"
            bordered={true}
            hoverable
            style={{ width: 350, backgroundColor: 'rgba(200, 200, 200, 0.15)' }}
            cover={
                <div style={{ position: 'relative' }}>
                    {<Image preview={false} alt="example" src={lastImg || img} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={(e) => handleProjectClick(e)} />}
                    <div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}>
                        <IconButton className={styles.cardButton} clickFunction={() => setToggle(!toggle)}>
                            <ThreeDotIcon />
                        </IconButton>
                        {toggle && (
                            <div className={styles.dropdownContent}>
                                {dropdownItems.map((item, index) => (
                                    (item.popconfirm ? (
                                        <Popconfirm
                                            placement={item.popconfirm.placement}
                                            title={item.popconfirm.title}
                                            key={index}
                                            description={item.popconfirm.description}
                                            okText={t('yes')}
                                            cancelText={t("no")}
                                            onConfirm={() => item.action(item._id)}
                                        >
                                            <div className={styles.dropdownTag} onClick={(e) => e.stopPropagation()}>
                                                {item.tag}
                                            </div>
                                        </Popconfirm>
                                    ) : (
                                        <div
                                           
                                            key={index}
                                            className={styles.dropdownTag}
                                            onClick={() => item.action(item._id)}
                                        >
                                            {item.tag}
                                        </div>
                                    ))
                                ))}

                            </div>
                        )}
                    </div>
                </div>
            }

        >
            <Flex justify='space-between' align='center'>
                {!rename && (
                    <>
                        <div onClick={(e) => handleProjectClick(e)}>
                            <h3 onDoubleClick={() => setClickCount(2)} style={{ gap: '0.5px', marginTop: '2px', cursor: 'pointer', width: '15em' }}>{title}</h3>
                            <Meta description={description} style={{ color: 'black', cursor: 'pointer' }} />
                        </div>
                        <div style={{
                            display: 'none'
                        }}>
                            <ScreenshotModal screenshots={memoizedSideImages} setScreenshots={setSideImages} />
                        </div>
                        <Avatar.Group justify="middle" style={{ gap: 2 }} maxCount={1}>
                            {memoizedSideImages.map((item, index) => (
                                <Tooltip title={moment(item.createdAt).fromNow()} placement="top" key={index}>
                                    <Avatar
                                        style={{ backgroundColor: '#12c062', borderColor: '#c4c4c4' }}
                                        src={item.src}
                                        onClick={() => {
                                            // This triggers the preview of the image
                                            document.getElementById(`hidden-img-${item._id.toString()}`).click();
                                        }}
                                    >
                                    </Avatar>
                                </Tooltip>
                            ))}
                            {/*memoizedSideImages.length === 0 && (
                                <Avatar style={{ backgroundColor: '#12c062' }}>
                                    {user.username.charAt(0)}
                                </Avatar>
                            )*/}
                        </Avatar.Group>


                    </>

                )}
                {rename && (
                    <Input
                        placeholder={t('enterName')}
                        onChange={(e) => handleChange(e)}
                        defaultValue={title}
                        style={{
                            marginRight: 10,
                            background: '#C8C8C833',
                            padding: 10
                        }}
                    />
                )}
            </Flex>
        </Card>
    )
}
