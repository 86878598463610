import { createContext, useEffect, useReducer, useState } from 'react';
import { fetchLocation } from '../functions/fetchers/fetchLocation';
import { useLngContext } from '../hooks/contextHooks/useLngContext';

export const SettingsContext = createContext();

export const settingsReducer = (state, action) => {
    switch (action.type) {
        case 'GET_MEASUREMENT_SYSTEM':
            return { ...state, measurementSystem: state.measurementSystem }
        case 'SET_MEASUREMENT_SYSTEM':
            return { ...state, measurementSystem: action.payload }
        default:
            return state;
    }
}

export const SettingsContextProvider = ({ children }) => {

    const [location, setLocation] = useState({});
    const [state, settingsDispatch] = useReducer(settingsReducer, {
        measurementSystem: 'metric'
    });
    const {languageDispatch} = useLngContext();


    useEffect(() => {
        const fetchAndSetLocation = async () => {
            try {
                if(!localStorage.getItem('languageCode')){
                    const loc = await fetchLocation();
                    localStorage.setItem('languageCode',loc.country.toLowerCase());
                    languageDispatch({type:'SWITCH_LNG',payload:loc.country.toLowerCase()});
                    setLocation(loc);
                }  
            } catch (error) {
                console.error("Error fetching location:", error);
            }
        };

        fetchAndSetLocation();  // Call the async function
        
    }, []);  // Empty dependency array means it runs once on mount

    useEffect(() => {
        if (location.country === 'USA' || location.country === 'Canada') {
            settingsDispatch({ type: 'SET_MEASUREMENT_SYSTEM', payload: 'ft' })
        }
    }, [location]);

    return (
        <SettingsContext.Provider value={{ ...state, settingsDispatch }}>
            {children}
        </SettingsContext.Provider>
    )
}