import { useContext } from "react"
import { LanguageContext } from "../../contexts/LanguageContext";


export const useLngContext = () => {
    const context = useContext(LanguageContext);
    if(!context){
        throw Error('useLngContext must be used inside an LanguageContext Provider ')
    }
    return context;

}