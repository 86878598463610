import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Form, Input, Flex, Button, Divider, Select } from 'antd'
import styles from './settingsForm.module.css';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';
import { useDebounce } from '../../../hooks/asyncHooks/useDebounce';
import DeleteUserModal from '../../modals/deleteUserModal/deleteUserModal';
import UploadButton from '../../common/buttons/uploadButton/uploadButton';
import ProfileAvatar from '../../common/avatars/profileAvatar';
import { useSettingContext } from '../../../hooks/contextHooks/useSettingsContext';
import DeleteForm from '../deleteForm';
import { useTranslation } from 'react-i18next';
import { useLngContext } from '../../../hooks/contextHooks/useLngContext';
import { languages } from '../../../data/languages/languages';

export default function SettingsForm() {
    // Hooks & Contexts
    const { user, dispatch } = useAuthContext();
    const { updateUser, getUserPhoto } = useUserAPI();
    const [username, setUsername] = useState(user.username ?? "");
    const [openDeleteUser, setOpenDeleteUser] = useState(false);
    const { settingsDispatch } = useSettingContext();
    const [deletePageOpen, setDeletePageOpen] = useState(false);
    const { t } = useTranslation();
    const {  languageCode ,languageDispatch } = useLngContext();

    const logout = () => {
        localStorage.removeItem('user');
        dispatch({ type: 'LOGOUT' })
    }


    const handleSave = useDebounce(async (title, value) => {
        if (title === "username") {
            await updateUser(user.userIdMongo, { username: value });
            let newData = { ...user, username: value }
            dispatch({ type: 'LOGIN', payload: newData });
            localStorage.setItem('user', JSON.stringify(newData));
        }
    }, 1000);


    const handleMeasurementSave = async (value) => {
        await updateUser(user.userIdMongo, { measurement_system: value });
        let newData = { ...user, measurement_system: value }
        dispatch({ type: 'LOGIN', payload: newData });
        localStorage.setItem('user', JSON.stringify(newData));
    }

    const handleUsernameChange = (e) => {
        const { value } = e.target;
        setUsername(value);
        handleSave("username", value)
    }

    const handleMeasurementChange = async (measurement) => {
        settingsDispatch({ type: 'SET_MEASUREMENT_SYSTEM', payload: measurement })
        await handleMeasurementSave(measurement);
    }

    useEffect(() => {
        if (username === "" && user.username) {
            setUsername(user.username);
        }
    }, [username]);


    const fetchProfilePic = useCallback(async () => {
        const res = await getUserPhoto();
        if (res.data.data) {
            let item = (res.data.data);
            let data = item.img.data.data;
            const base64String = btoa(
                new Uint8Array(data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            const mimeType = res.headers['content-type']; // Make sure your backend sets this header
            let newVal = `data:${mimeType};base64,${base64String}`;
            dispatch({ type: 'GET_PROFILE_PIC', payload: newVal });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        fetchProfilePic();
    }, []);


   
    return (
        <>
            <span> {deletePageOpen ? t("deleteuser") : t("settings")} </span>
            <Divider style={{ margin: 7 }} />
            {!deletePageOpen &&
                <Form style={{ maxWidth: 600 }} layout='vertical'>
                    {<Form.Item>
                        <Flex justify='flex-start' align='center' gap={10}>
                            <ProfileAvatar letter={user.username.charAt(0)} size={60} />
                            <UploadButton />
                        </Flex>
                    </Form.Item>}
                    <Form.Item label={t("username")}>
                        <Input placeholder={username} className={styles.inputField} onChange={(e) => handleUsernameChange(e)} />
                    </Form.Item>
                    <Form.Item label={t("email")}>
                        <Input placeholder={user.email} disabled className={styles.inputField} />
                    </Form.Item>
                   
                    <Form.Item label={t("deleteaccount")}>
                        <Flex gap={10} align='center'>
                            <span style={{
                                maxWidth:'100%'
                            }}>{t('deletewarning')}</span>
                            <Button 
                                type="text" 
                                onClick={() => setOpenDeleteUser(true)}
                                className={styles.customButton}
                            > {t('delete')} </Button>
                        </Flex>
                    </Form.Item>
                    <Form.Item>
                        <Flex gap={2} align='center'>
                            <Button type='default' size='large' onClick={() => logout()}> {t('logout')} </Button>
                        </Flex>
                    </Form.Item>
                    {<DeleteUserModal open={openDeleteUser} activate={() => setDeletePageOpen(true)} cancel={() => setOpenDeleteUser(false)} />}
                </Form>}
            {deletePageOpen && <DeleteForm cancel={() => setDeletePageOpen(false)} />}
        </>
    )
}
