import React from 'react'
import styles from './topbarswitch.module.css';


function Topbarswitch({switchFunction,showMeasurements}) {

    // Toggle the switch state
    const handleToggle = () => {
        switchFunction(prev => !prev);
    };
    return (
        <label className={styles.switch}>
            
            <input 
                type="checkbox" 
                checked={showMeasurements} 
                onChange={handleToggle} 
                className={styles.checkbox}
            />
            <span className={styles.slider}>
               
            </span>
        </label>
    )
}

export default Topbarswitch
