import React, { useEffect } from 'react'
import SigninForm from '../../components/forms/signinForm/signinForm'
import { Divider, Flex, Layout } from 'antd'
import InteractiveSphere from '../../components/common/interactive-images/interactivesphere/interactiveSphere';
import { fetchLocation } from '../../functions/fetchers/fetchLocation';
import { fetchEntryData } from '../../functions/fetchers/fetchEntryData';
import { getUTMData } from '../../functions/utm/utmFunctions';
import { v4 as uuidv4 } from 'uuid';
import { configConsumerProps } from 'antd/es/config-provider';
import { useTranslation } from 'react-i18next';



export default function SignIn() {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const hideComponents = screenWidth > 700;
    const {t} = useTranslation();

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const fetchEntryDatas = async () => {
            try {
                // Check if sessionId exists in sessionStorage
                let sessionId = sessionStorage.getItem('sessionId');

                // If it doesn't exist, generate a new one and save it
                if (!sessionId) {
                    sessionId = uuidv4(); // Generate a new session ID
                    sessionStorage.setItem('sessionId', sessionId); // Store it in sessionStorage
                    const loc = await fetchLocation();
                    const url = window.location.href;
                    const utmData = getUTMData(url);
                    const result = await fetchEntryData(sessionId, url, loc, utmData);
                }
            } catch (e) {
                console.error("Error fetching entry data:", e);
            }
        };
        if (window.location.hostname === 'app.decobot.ai' && window.location.pathname.includes('/sign-in') && !window.location.pathname.includes('/dashboard')) {
            fetchEntryDatas();
        }
    }, []);

    return (
        <Layout>
            <Flex justify='flex-start' align='center' style={{ height: "100vh" }}>
                {hideComponents && (
                    <InteractiveSphere />
                )}


                <Flex vertical align='center' justify='flex-start' style={{
                    height: "100vh",
                    width: hideComponents ? "50vw" : "100vw",
                    gap: 20,
                    padding: '7vh',
                }}>
                    <SigninForm popupVisible={!(screenWidth > 1024)} />
                    <Flex style={{
                        marginTop: 'auto',  // This pushes the flex containing spans to the bottom
                        bottom: 0,
                    }} gap={2} justify='center' align='center'>
                        <a style={{
                            color:'#7B7B7B',
                            textAlign:'center'
                        }} href='https://www.decobot.ai'>{t('Our Features')}</a>
                        <Divider type="vertical" style={{borderColor:'#7B7B7B', marginTop:5}}/>
                        <a style={{
                            color:'#7B7B7B',
                            textAlign:'center'
                        }} href='https://www.decobot.ai/#how-it-works'>{t('How It Works')}</a>
                        <Divider type="vertical"  style={{borderColor:'#7B7B7B', marginTop:5}}/>
                        <a style={{
                            color:'#7B7B7B',
                            textAlign:'center'
                        }}  href='https://www.decobot.ai/blog'>{t('Blog')}</a>
                        <Divider type="vertical"  style={{borderColor:'#7B7B7B', marginTop:5}}/>
                        <a style={{
                            color:'#7B7B7B',
                            textAlign:'center'
                        }} href='https://www.decobot.ai/#contact-us'>{t('Contact Us')}</a>
                    </Flex>
                </Flex>

            </Flex>


        </Layout>
    )
}
