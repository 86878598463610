import React, { useEffect } from 'react'
import Section from '../../section/section';
import Panel from '../../panel/panel';
import panelstyle from '../../panel/panel.module.css'
import { Divider, Row, Col, Flex } from 'antd';
import { CustomShape, LShaped, RectShaped, TShaped, ZShaped } from '../../icons/icons';
import roomshapestyle from './roomshapedialog.module.css';
import { useTranslation } from 'react-i18next';



const ShapeBox = ({ id, name, shapeComponent, style, onClick }) => {
    return (
        <div className={roomshapestyle.shapeBox} style={style} onClick={() => onClick(id)}>
            <div className={roomshapestyle.shapeComponent}>
                {shapeComponent}
            </div>
            <div>{name}</div>
        </div>
    );
};

export default function RoomShapeDialog({ select }) {
    const {t} = useTranslation();

    const shapeItems = [
        {
            id: 'custom',
            shapeComponent: (<CustomShape />),
            name: t('Custom Shape')
        },
        {
            id: 'T',
            shapeComponent: (<TShaped />),
            name: t('T Shape')
        },
        {
            id: 'Z',
            shapeComponent: (<ZShaped />),
            name: t('Z Shape')
        },
        {
            id: 'L',
            shapeComponent: (<LShaped />),
            name: t('L Shape')
        },
        {
            id: 'R',
            shapeComponent: (<RectShaped />),
            name: t('Rectangular')
        },
    ];

    return (
        <div className={roomshapestyle.modal}>
            <Panel className={panelstyle.itemPanel}>
                <Section style={{
                    padding: '30px',
                    width: '600px',
                }}>
                    <span> {t('Create a Room Shape')}</span>
                    <Divider style={{ marginTop: 5 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
                        <ShapeBox {...shapeItems[0]} style={{ flex: '2 2 0px' }} onClick={select} />
                        <div style={{ display: 'flex', flex: '1 1 0px', flexDirection: 'column', gap: '16px' }}>
                            <ShapeBox {...shapeItems[1]} onClick={select} />
                            <ShapeBox {...shapeItems[3]} onClick={select} />
                        </div>
                        <div style={{ display: 'flex', flex: '1 1 0px', flexDirection: 'column', gap: '16px' }}>
                            <ShapeBox {...shapeItems[2]} onClick={select} />
                            <ShapeBox {...shapeItems[4]} onClick={select} />
                        </div>
                    </div>
                </Section>
            </Panel>
        </div>
    )
};
