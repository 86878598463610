import { Alert, Input, Modal } from 'antd'
import React, { useState } from 'react'
import VStack from '../../stack/vStack/vStack'
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';
import { useTranslation } from 'react-i18next';

export default function DeleteUserModal({ open, activate, cancel }) {
    const [input, setInput] = useState("");
    const { user } = useAuthContext();
    const { error,message } = useUserAPI();
    const {t} = useTranslation();


    const handleDeleteForm = async () => {
        if (user.username === input) {
            await activate();
            await cancel();
        }
    }

    return (
        <Modal okType='danger' cancelText={t('Cancel')} okText={t('delete')} title={t("warning")} open={open} onOk={() => handleDeleteForm()} onCancel={cancel}>
            <VStack>
                <Alert
                    type='warning'
                    message={t('Are you sure you want to delete the user')}
                />

                <Input placeholder={t('Please enter the username to confirm deletion')} onChange={(e) => setInput(e.target.value)} />
            </VStack>
            {error && <Alert type='danger' message={message}/>}
        </Modal>
    )
}
