import React, { useEffect } from 'react'
import Row from '../../grid/row';
import Col from '../../grid/col';
import ProjectCard from '../../cards/projectcard'
import styles from './cardPanel.module.css';
import { Divider, Empty } from 'antd';
import moment from 'moment';
import defaultImg from '../../../assets/default-card/defaultproject.png'
import { useProjectContext } from '../../../hooks/contextHooks/useProjectContext';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { t } from 'i18next';


export default function CardPanel({ title, items, screenshots,setScreenshots }) {
    const {pDispatch} = useProjectContext();
    const {user} = useAuthContext();
    
    const hasDataTitle = (title, index) => {
        if(title && title !== 'Project Name' && title !== ""){
            return title;
        }
        else {
            return t('projectName') + index 
        }
    }



    useEffect(() => {
        pDispatch({type:'TRIGGER'});
    },[]);



    return (
        <div className={styles.mainPanel}>
            <span className={styles.title}> {title} </span>
            <Divider style={{ margin: 5 }} />
            {items && items.length > 0 && (
                <div className={styles.fullList}>
                    <Row colsPerRow={4}>
                        {items.map((data, index) => (
                            <Col key={index}>
                                {<ProjectCard
                                    projectId={data._id}
                                    img={defaultImg}
                                    title={hasDataTitle(data.title,index + 1)}
                                    description={t('edited') + ": " + moment(data.updatedAt).fromNow()}
                                    item={data}
                                    sideImages={screenshots}
                                    setSideImages = {setScreenshots}
                                />}
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
            {items.length === 0 && (
                <div className={styles.emptyList}>
                    <Empty
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                {title === t('tutorials') ? t('noTutorials') : t('noProjects')}
                            </span>
                        }
                    ></Empty>
                </div>

            )}
        </div>
    )
}
