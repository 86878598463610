import { Divider, Flex, Segmented, Tooltip, Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../item-panel/item-panel.module.css';
import Panel from '../../common/panel';
import Renders from '../../common/renders/renders';
import { useParams } from 'react-router-dom';
import Section from '../../common/section/section';
import { useSettingContext } from '../../../hooks/contextHooks/useSettingsContext';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useTranslation } from 'react-i18next';



export default function RoomPanel({ thickness, rightPanelOpen, screenshots, setScreenshots }) {
    const [ceiling, setCeiling] = useState(320);
    const {t} = useTranslation();
    const { id } = useParams();
    const { user } = useAuthContext();
    const { measurementSystem, settingsDispatch } = useSettingContext();
    const [metricVal, setMetricVal] = useState(measurementSystem !== 'ft' ? 'm' : 'ft');
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    const videos = [
        {id:1,title:t('keyfeaturesOverview'),video:"https://www.youtube.com/embed/vL1coZwC7Tk?si=ayuD9y_uWnTKap87"},
        {id:2,title:t('creatingANewProject'),video:"https://www.youtube.com/embed/b-aRgQHyaNU?si=IZKiqQMqt72buDNJ"},
        {id:3,title:t("beginnersGuideToDrawARoom"), video:"https://www.youtube.com/embed/W6CA-xSGSUQ?si=cU6iKA7DYFnTe4JU"},
        {id:4,title:t("decoratingYourHouse"), video:"https://www.youtube.com/embed/s45U-Z78faE?si=tYDgXTBY7kndh5J3"}
    ]

    const { updateUser } = useUserAPI();

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
       setMetricVal(t(metricVal))
    },[]);

    const panel = () => {
        return (
            <div>

                <span style={{
                    fontSize: '17px',
                    color: '#C3C3C3'
                }}> Please select an Item </span>



                { /*<Section>
                    <span style={{
                        fontSize:'12px'
                    }}>Select Draw Mode to Start Drawing Lines. You can use D for shortcut </span>
                </Section>
                <Section>
                    <span style={{
                        fontSize:'12px'
                    }}>Select Selection Mode to be able to Drag & Drop Items or Edit Lines. You can use S for shortcut </span>
                </Section> 
                <Section>
                    <span style={{
                        fontSize:'12px'
                    }}> Tutorial </span>
                </Section>
                
                */}


            </div>

        )
    }

    const optionsWithTooltips = [
        {
            label: (
                <Tooltip title={t('m')} placement='bottom'>
                    {'m'}
                </Tooltip>
            ),
            value: 'm'
        },
        {
            label: (
                <Tooltip title={t('ft')} placement='bottom'>
                    {'ft'}
                </Tooltip>
            ),
            value: 'ft'
        }
    ];

    return (
        <Panel className={rightPanelOpen ? styles.itemPanel : styles.itemPanelClosed}>
            {/*
            <Collapse
                items={collapseItem}
                bordered={false}
                className={collapseStyle.collapse}
                expandIconPosition={'end'}
                defaultActiveKey={['1']}
            />
            */}
            <Typography style={{
                color: '#000233',
                fontSize: '16px',
            }}> {t('properties')} </Typography>
            <Divider style={{ marginTop: 3, marginBottom: 3 }} />
            <Section>
                <Flex align='center' justify='space-between' gap={5}>
                    <span style={{
                        fontSize: 13,
                        marginTop:2
                    }}>{t('measuringUnit')}</span>
                    <Segmented
                        value={metricVal}
                        size={screenWidth <= 1024 ? 'small' : 'medium'}
                        options={optionsWithTooltips}
                        onChange={async (value) => {
                            if (value === 'm') {
                                settingsDispatch({ type: 'SET_MEASUREMENT_SYSTEM', payload: 'metric' });
                                setMetricVal('m')
                                await updateUser(user.userIdMongo, { measurement_system: 'metric' })
                            }
                            else {
                                settingsDispatch({ type: 'SET_MEASUREMENT_SYSTEM', payload: 'ft' });
                                setMetricVal('ft')
                                await updateUser(user.userIdMongo, { measurement_system: 'ft' })
                            }
                        }}
                    />
                </Flex>

            </Section>
            {/*<div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'center',
                justifyContent:'center',
                flex:'1 1 0px',
                marginBottom:'50px'                
            }}>{panel()}</div>*/}

            {screenshots.length > 0 && <Renders
                projectId={id}
                screenshots={screenshots}
                setScreenshots={setScreenshots}
            />}
            <Section>
                <Typography variant='h5'>{videos[0].title}</Typography>
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <iframe
                    className={styles.videoSection}
                    src={videos[0].video}
                    title="Video player"
                    controls // Adding controls so the user can play manually
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </Section>
            <Section>
                <Typography variant='h5'>{videos[3].title}</Typography>
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <iframe
                    className={styles.videoSection}
                    src={videos[3].video}
                    title="Video player"
                    controls // Adding controls so the user can play manually
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </Section>
        </Panel>

    )
}
