import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Group, Text } from 'react-konva'
import { useFloorContext } from '../../hooks/contextHooks/useFloorContext';
import { useSettingContext } from '../../hooks/contextHooks/useSettingsContext';
import { useTranslation } from 'react-i18next';


export function calculatePolygonArea(points,measurementSystem) {
    
    let n = points.length / 2;
    let area = 0;
    

    for (let i = 0; i < n; i++) {
        let x1 = points[2 * i];
        let y1 = points[2 * i + 1];
        let x2 = points[2 * ((i + 1) % n)];
        let y2 = points[2 * ((i + 1) % n) + 1];

        area += x1 * y2 - y1 * x2;
    }

    if(measurementSystem === 'ft'){
        return (Math.abs(area) * 10.76)/ 2
    }
    return Math.abs(area) / 2;
}

function calculateCentroid(points) {
    let n = points.length / 2;
    let cx = 0, cy = 0;
    let area = 0;

    for (let i = 0; i < n; i++) {
        let x1 = points[2 * i];
        let y1 = points[2 * i + 1];
        let x2 = points[2 * ((i + 1) % n)];
        let y2 = points[2 * ((i + 1) % n) + 1];
        let a = x1 * y2 - x2 * y1;

        area += a;
        cx += (x1 + x2) * a;
        cy += (y1 + y2) * a;
    }

    area = area / 2;
    cx = cx / (6 * area);
    cy = cy / (6 * area);

    return { x: cx - 25, y: cy };
}


function AreaText({ index, points, floor, scale,setSelectedFloor }) {
    const [area, setArea] = useState(0);
    const [centroid, setCentroid] = useState({});
    const {measurementSystem} = useSettingContext();
    const { floorContext } = useFloorContext();
    const roomTypeRef = useRef(null);
    const areaTitleRef = useRef(null);
    const [offsetX, setOffsetX] = useState(0);
    const {t} = useTranslation();
    let areaTitle = Math.floor(area) / 10000;

    const memoizedFloor = useMemo(() => {
        return floorContext.filter(Boolean).find((element) => element.id === floor.id);
    }, [floor, floorContext]);

    useEffect(() => {
        if (roomTypeRef.current && areaTitleRef.current) {
            const roomTypeWidth = roomTypeRef.current.getTextWidth() * (scale < 1 ? 1 : 1 / scale);
            const areaTitleWidth = areaTitleRef.current.getTextWidth() * (scale < 1 ? 1 : 1 / scale);
            const diff = (roomTypeWidth - areaTitleWidth) / 2;
            setOffsetX(diff);
        }
    }, [memoizedFloor, areaTitle, scale]);

    useEffect(() => {
        setArea(calculatePolygonArea(points,measurementSystem));
        let center = calculateCentroid(points);
        setCentroid(center);
    }, [points,measurementSystem]);


    const areaText = useMemo(() => {
        if(measurementSystem !== 'ft'){
            return " m\u00B2";
        }
        else {
            return " ft\u00B2";
        }
    },[measurementSystem]);

    return (
        <Group onClick={() => setSelectedFloor(floor.id)}>
            {memoizedFloor && (
                <Text
                    ref={roomTypeRef}
                    text={t(memoizedFloor.room_type) ?? t("Unnamed")}
                    scaleY={scale < 1 ? -1 : -1 / scale}
                    scaleX={scale < 1 ? 1 : 1 / scale}
                    x={(centroid.x)}
                    y={centroid.y + (20 / (scale < 1 ? 1 : scale))}
                />
            )}
            <Text
                ref={areaTitleRef}
                text={
                    areaTitle > 1
                        ? areaTitle.toFixed(0) +  areaText
                        : areaTitle > 0.1
                        ? areaTitle.toFixed(1) +  areaText
                        : areaTitle > 0.01
                        ? areaTitle.toFixed(2) + areaText
                        : areaTitle.toFixed(3) + areaText
                }
                scaleY={scale < 1 ? -1 : -1 / scale}
                scaleX={scale < 1 ? 1 : 1 / scale}
                x={centroid.x + offsetX}
                y={centroid.y}
            />
        </Group>
    );
}

export default AreaText