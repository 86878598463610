import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import styles from './App.module.css';
import Hubspot from './components/hubspot/hubspot';
import config from './config/config.json';
import "@fontsource/inter";
import Dashboard from './pages/dashboard';
import Whiteboard from './pages/whiteboard/whiteboard';
import SignIn from './pages/signin/signin';
import { useAuthContext } from './hooks/contextHooks/useAuthContext';

import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useFetchCampaignData } from './hooks/fetchHooks/useFetchCampaignData';
import { useLngContext } from './hooks/contextHooks/useLngContext';
import i18n from './i18n';





function PageTracker() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);

    return null;
}


function App() {
    // Hooks & Context
    const { user, loaded } = useAuthContext();
    const { fetchCampaignData} = useFetchCampaignData();

    useEffect(() => {
        // Check if the entry URL is already stored
        if (!localStorage.getItem('entryUrl')) {
            // Get the current full URL
            const entryUrl = window.location.href;

            // Store the full URL in localStorage
            localStorage.setItem('entryUrl', entryUrl);

            console.log('Initial URL stored:', entryUrl);
        }

        // Get the current hostname to initialize Google Analytics
        const currentUrl = window.location.hostname;
        if (currentUrl === 'app.decobot.ai') {
            ReactGA.initialize(config.REACT_APP_G_ANALYSIS_CODE);
            fetchCampaignData();
            console.log('Google Analytics initialized.');
        } else {
            console.log('URL does not match. Google Analytics not initialized.');
        }

    }, []);

    return (
        <div className={styles.app}>
            <BrowserRouter>
                <Hubspot />
                {/* Google Analytics Page Tracking */}
                <PageTracker />
                <Routes>
                    {loaded && (
                        <Route
                            path='/project/:id'
                            element={user ? <Whiteboard /> : <Navigate to={'/sign-in'} />}
                        />
                    )}
                    <Route
                        path='/'
                        element={<Navigate to={'/sign-in'} />}
                    />
                    <Route
                        path='/dashboard'
                        element={user ? (
                            <div>
                                <Dashboard />

                            </div>) : <Navigate to={'/sign-in'} />}
                    />
                    <Route
                        path='/sign-in'
                        element={!user ? <SignIn /> : <Navigate to={'/dashboard'} />}
                    />
                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
