import React, { useState } from 'react';
import { Flex, Button, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons'
import styles from './actionPanel.module.css';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useProjectContext } from '../../../hooks/contextHooks/useProjectContext';
import { useProjectAPI } from '../../../hooks/apiHooks/projectHooks/useProjectAPI';
import { useTranslation } from 'react-i18next';
import { useLngContext } from '../../../hooks/contextHooks/useLngContext';
import Languages from '../../../data/languages/languages';

export default function ActionPanel({ title, description }) {
    // Hooks
    const { user } = useAuthContext();
    const { pDispatch } = useProjectContext();
    const { createProject } = useProjectAPI();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { languageCode, languageDispatch } = useLngContext();
    const {languages} = Languages()

    // Handlers
    const handleConfirm = async () => {
        try {
            setLoading(true);
            const result = await createProject({ user_id: user.userIdMongo });
            pDispatch({ type: 'TRIGGER' })
            let projectId = result.data._id;
            window.location.href = '/project/' + projectId;
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    const onSelectLanguageChange = (value) => {
        console.log("Language Change ", value);
        localStorage.setItem('languageCode', value);
        languageDispatch({ type: 'SWITCH_LNG', payload: value })
    };

    return (
        <Flex justify='space-between' align='center' gap={10}>
            <div>
                <span className={styles.title}> {title} </span>
                <p className={styles.desc}>{description}</p>
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10
            }}>

                <Select
                    value={localStorage.getItem('languageCode') || languageCode}
                    placeholder="Select a Language"
                    onChange={(value) => onSelectLanguageChange(value)}
                    options={languages}
                    style={{
                        display:'flex',
                        justifyContent:'space-evenly',
                        minWidth:'5vw',
                        height:'49px',
                    }}
                >
                </Select>


                <Button
                    type="secondary"
                    className={`${styles.button} custom-button`}
                    icon={<PlusCircleOutlined />}
                    loading={loading} // Set loading state to true/false based on the value of the loading state
                    onClick={handleConfirm} // Call handleConfirm when the button is clicked
                >
                    {t('newproject')}
                </Button>
            </div>
            {/* No Popconfirm */}

        </Flex>
    )
}
