import Flag from 'react-world-flags';


import React, { useEffect } from 'react'

function Languages() {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const languages = [
        {
            value: 'tr',
            label: <span style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-evenly',
                gap: 4
            }}><Flag code='tr' width={22} height={22}/>{screenWidth >= 1440 ? "TR" : ""}</span>,
        },
        {
            value: 'gb',
            label: <span style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-evenly',
                gap: 4
            }}><Flag code='gb' width={22} height={22}/> {screenWidth >= 1440 ? "EN" : ""} </span>,
        }
    ]

    return {
        languages
    }

}

export default Languages;