import { ConstructionIcon, ElectronicsIcon, FurnitureIcon, LightingIcon, WindowIcon, BathroomIcon,KitchenIconItemMenu} from "../../components/common/icons/icons";
import { Image } from 'antd';

import window1Panel from '../../assets/Windows/Windows_01_A.png';
import window2Panel from '../../assets/Windows/Windows_02_A.png';
import window3Panel from '../../assets/Windows/Windows_03_A.png';
import window4Panel from '../../assets/Windows/Windows_04_A.png';
import window1Board from '../../assets/Windows/window_01_remastered.png';
import window2Board from '../../assets/Windows/window_01_remastered.png';
import window3Board from '../../assets/Windows/window_01_remastered.png';
import window4Board from '../../assets/Windows/window_01_remastered.png';

//import singleDoorBoard from '../../assets/Doors/door.png';
import doubleDoorBoard from '../../assets/Doors/double_door.png';
import dutchDoorBoard from '../../assets/Doors/Dutch-door_01.png';
import singleDoor1Board from '../../assets/Doors/single door_01.png';
import singleDoor2Board from '../../assets/Doors/single door_02.png';

import door1Panel from '../../assets/Doors/Doors_01_A.png';
import door2Panel from '../../assets/Doors/Doors_02_A.png';
import door3Panel from '../../assets/Doors/Doors_03_A.png';
import door4Panel from '../../assets/Doors/Dutch-door_02.jpg';

import ceilingLight1Panel from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_01_B.png';
import ceilingLight2Panel from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_02_B.png';
import ceilingLight3Panel from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_03_B.png';
import ceilingLight4Panel from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_04_B.png';
import ceilingLight5Panel from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_05_B.png';

import ceilingLight1Board from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_01_A.png';
import ceilingLight2Board from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_02_A.png';
import ceilingLight3Board from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_03_A.png';
import ceilingLight4Board from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_04_A.png';
import ceilingLight5Board from '../../assets/Lighting/Ceiling Lights/Ceiling-Lights_05_A.png';

import floorLamp1Board from '../../assets/Lighting/Floor Lamps/Floor-Lamps_01_A.png';
import floorLamp2Board from '../../assets/Lighting/Floor Lamps/Floor-Lamps_02_A.png';
import floorLamp3Board from '../../assets/Lighting/Floor Lamps/Floor-Lamps_03_A.png';
import floorLamp4Board from '../../assets/Lighting/Floor Lamps/Floor-Lamps_04_A.png';
import floorLamp5Board from '../../assets/Lighting/Floor Lamps/Floor-Lamps_05_A.png';

import floorLamp1Panel from '../../assets/Lighting/Floor Lamps/Floor-Lamps_01_B.png';
import floorLamp2Panel from '../../assets/Lighting/Floor Lamps/Floor-Lamps_02_B.png';
import floorLamp3Panel from '../../assets/Lighting/Floor Lamps/Floor-Lamps_03_B.png';
import floorLamp4Panel from '../../assets/Lighting/Floor Lamps/Floor-Lamps_04_B.png';
import floorLamp5Panel from '../../assets/Lighting/Floor Lamps/Floor-Lamps_05_B.png';

import tableLamp1Panel from '../../assets/Lighting/Table Lamps/Table-Lamps_01_B.png';
import tableLamp2Panel from '../../assets/Lighting/Table Lamps/Table-Lamps_02_B.png';
import tableLamp3Panel from '../../assets/Lighting/Table Lamps/Table-Lamps_03_B.png';
import tableLamp4Panel from '../../assets/Lighting/Table Lamps/Table-Lamps_04_B.png';

import tableLamp1Board from '../../assets/Lighting/Table Lamps/Table-Lamps_01_A.png';
import tableLamp2Board from '../../assets/Lighting/Table Lamps/Table-Lamps_02_A.png';
import tableLamp3Board from '../../assets/Lighting/Table Lamps/Table-Lamps_03_A.png';
import tableLamp4Board from '../../assets/Lighting/Table Lamps/Table-Lamps_04_A.png';

import wallLamp1Panel from '../../assets/Lighting/Wall Lamps/Wall-Lamps_01_B.png';
import wallLamp2Panel from '../../assets/Lighting/Wall Lamps/Wall-Lamps_02_B.png';
import wallLamp3Panel from '../../assets/Lighting/Wall Lamps/Wall-Lamps_03_B.png';
import wallLamp4Panel from '../../assets/Lighting/Wall Lamps/Wall-Lamps_04_B.png';
import wallLamp5Panel from '../../assets/Lighting/Wall Lamps/Wall-Lamps_05_B.png';

import wallLamp1Board from '../../assets/Lighting/Wall Lamps/Wall-Lamps_01_A.png';
import wallLamp2Board from '../../assets/Lighting/Wall Lamps/Wall-Lamps_02_A.png';
import wallLamp3Board from '../../assets/Lighting/Wall Lamps/Wall-Lamps_03_A.png';
import wallLamp4Board from '../../assets/Lighting/Wall Lamps/Wall-Lamps_04_A.png';
import wallLamp5Board from '../../assets/Lighting/Wall Lamps/Wall-Lamps_05_A.png';


import airconditioner1Panel from '../../assets/Electronics/Air Conditioners/Air-Conditioners_01_B.png';
import airconditioner2Panel from '../../assets/Electronics/Air Conditioners/Air-Conditioners_02_B.png';
import airconditioner3Panel from '../../assets/Electronics/Air Conditioners/Air-Conditioners_03_B.png';
import airconditioner4Panel from '../../assets/Electronics/Air Conditioners/Air-Conditioners_04_B.png';

import airconditioner1Board from '../../assets/Electronics/Air Conditioners/Air-Conditioners_01_A.png';
import airconditioner2Board from '../../assets/Electronics/Air Conditioners/Air-Conditioners_02_A.png';
import airconditioner3Board from '../../assets/Electronics/Air Conditioners/Air-Conditioners_03_A.png';
import airconditioner4Board from '../../assets/Electronics/Air Conditioners/Air-Conditioners_04_A.png';

import computer1Panel from '../../assets/Electronics/Computers/Computers_01_B.png';
import computer2Panel from '../../assets/Electronics/Computers/Computers_02_B.png';
import computer3Panel from '../../assets/Electronics/Computers/Computers_03_B.png';
import computer4Panel from '../../assets/Electronics/Computers/Computers_04_B.png';
import computer5Panel from '../../assets/Electronics/Computers/Computers_05_B.png';
import computer6Panel from '../../assets/Electronics/Computers/Computers_06_B.png';
import computer7Panel from '../../assets/Electronics/Computers/Computers_07_B.png';

import computer1Board from '../../assets/Electronics/Computers/Computers_01_A.png';
import computer2Board from '../../assets/Electronics/Computers/Computers_02_A.png';
import computer3Board from '../../assets/Electronics/Computers/Computers_03_A.png';
import computer4Board from '../../assets/Electronics/Computers/Computers_04_A.png';
import computer5Board from '../../assets/Electronics/Computers/Computers_05_A.png';
import computer6Board from '../../assets/Electronics/Computers/Computers_06_A.png';
import computer7Board from '../../assets/Electronics/Computers/Computers_07_A.png';


import radiator1Board from '../../assets/Electronics/Radiators/Radiators_01_A.png';
import radiator2Board from '../../assets/Electronics/Radiators/Radiators_02_A.png';
//import radiator3Board from '../../assets/Electronics/Radiators/Radiators_03_A.png';
import radiator4Board from '../../assets/Electronics/Radiators/Radiators_04_A.png';

import radiator1Panel from '../../assets/Electronics/Radiators/Radiators_01_B.png';
import radiator2Panel from '../../assets/Electronics/Radiators/Radiators_02_B.png';
//import radiator3Panel from '../../assets/Electronics/Radiators/Radiators_03_B.png';
import radiator4Panel from '../../assets/Electronics/Radiators/Radiators_04_B.png';

import television1Board from '../../assets/Electronics/Televisions/Televisions_01_A.png';
import television2Board from '../../assets/Electronics/Televisions/Televisions_02_A.png';
import television3Board from '../../assets/Electronics/Televisions/Televisions_03_A.png';

import television1Panel from '../../assets/Electronics/Televisions/Televisions_01_B.png';
import television2Panel from '../../assets/Electronics/Televisions/Televisions_02_B.png';
import television3Panel from '../../assets/Electronics/Televisions/Televisions_03_B.png';

import whiteGoods1Board from '../../assets/Electronics/White Goods/Air-Fryer_01_A.png';
import whiteGoods2Board from '../../assets/Electronics/White Goods/Dishwasher_01_A.png';
import whiteGoods3Board from '../../assets/Electronics/White Goods/Microwave_01_A.png';
import whiteGoods4Board from '../../assets/Electronics/White Goods/Refrigerator_01_A.png';
import whiteGoods5Board from '../../assets/Electronics/White Goods/Refrigerator_02_A.png';
import whiteGoods6Board from '../../assets/Electronics/White Goods/washing-machine_01_A.png';

import whiteGoods1Panel from '../../assets/Electronics/White Goods/Air-Fryer_01_B.png';
import whiteGoods2Panel from '../../assets/Electronics/White Goods/Dishwasher_01_B.png';
import whiteGoods3Panel from '../../assets/Electronics/White Goods/Microwave_01_B.png';
import whiteGoods4Panel from '../../assets/Electronics/White Goods/Refrigerator_01_B.png';
import whiteGoods5Panel from '../../assets/Electronics/White Goods/Refrigerator_02_B.png';
import whiteGoods6Panel from '../../assets/Electronics/White Goods/washing-machine_01_B.png';

import otherElectronics1Board from '../../assets/Electronics/Others/Coffee-Machine_01_A.png';
import otherElectronics2Board from '../../assets/Electronics/Others/Coffee-Machine_02_A.png';
import otherElectronics3Board from '../../assets/Electronics/Others/office-phone_01_A.png';
import otherElectronics4Board from '../../assets/Electronics/Others/Sound-box_01_A.png';
import otherElectronics5Board from '../../assets/Electronics/Others/Sweeping-robot_01_A.png';
import otherElectronics6Board from '../../assets/Electronics/Others/Vacuum-cleaner_01_A.png';

import otherElectronics1Panel from '../../assets/Electronics/Others/Coffee-Machine_01_B.png';
import otherElectronics2Panel from '../../assets/Electronics/Others/Coffee-Machine_02_B.png';
import otherElectronics3Panel from '../../assets/Electronics/Others/office-phone_01_B.png';
import otherElectronics4Panel from '../../assets/Electronics/Others/Sound-box_01_B.png';
import otherElectronics5Panel from '../../assets/Electronics/Others/Sweeping-robot_01_B.png';
import otherElectronics6Panel from '../../assets/Electronics/Others/Vacuum-cleaner_01_B.png';


import diningRoomAccessoriesPanel1 from '../../assets/Dining Room/Accessories/Accessories_01_B.png';
import diningRoomAccessoriesPanel2 from '../../assets/Dining Room/Accessories/Accessories_02_B.png';
import diningRoomAccessoriesPanel3 from '../../assets/Dining Room/Accessories/Garbage-can_01_B.png';
import diningRoomAccessoriesPanel4 from '../../assets/Dining Room/Accessories/Screens_01_B.png';

import diningRoomAccessoriesBoard1 from '../../assets/Dining Room/Accessories/Accessories_01_A.png';
import diningRoomAccessoriesBoard2 from '../../assets/Dining Room/Accessories/Accessories_02_A.png';
import diningRoomAccessoriesBoard3 from '../../assets/Dining Room/Accessories/Garbage-can_01_A.png';
import diningRoomAccessoriesBoard4 from '../../assets/Dining Room/Accessories/Screens_01_A.png';

import barSeat1Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_01_B.png';
import barSeat2Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_02_B.png';
import barSeat3Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_03_B.png';
import barSeat4Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_04_B.png';
import barSeat5Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_05_B.png';
import barSeat6Panel from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_06_B.png';

import barSeat1Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_01_A.png';
import barSeat2Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_02_A.png';
import barSeat3Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_03_A.png';
import barSeat4Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_04_A.png';
import barSeat5Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_05_A.png';
import barSeat6Board from '../../assets/Dining Room/Bar & Counter Seats/Bar-&-Counter-Seats_06_A.png';

import diningChair1Panel from '../../assets/Dining Room/Chairs/Chairs_01_B.png';
import diningChair2Panel from '../../assets/Dining Room/Chairs/Chairs_02_B.png';
import diningChair3Panel from '../../assets/Dining Room/Chairs/Chairs_03_B.png';
import diningChair4Panel from '../../assets/Dining Room/Chairs/Chairs_04_B.png';
import diningChair5Panel from '../../assets/Dining Room/Chairs/Chairs_05_B.png';

import diningChair1Board from '../../assets/Dining Room/Chairs/Chairs_01_A.png';
import diningChair2Board from '../../assets/Dining Room/Chairs/Chairs_02_A.png';
import diningChair3Board from '../../assets/Dining Room/Chairs/Chairs_03_A.png';
import diningChair4Board from '../../assets/Dining Room/Chairs/Chairs_04_A.png';
import diningChair5Board from '../../assets/Dining Room/Chairs/Chairs_05_A.png';

import diningCurtain1Panel from '../../assets/Dining Room/Curtains/Curtains_01_B.png';
import diningCurtain2Panel from '../../assets/Dining Room/Curtains/Curtains_02_B.png';
import diningCurtain3Panel from '../../assets/Dining Room/Curtains/Curtains_03_B.png';
import diningCurtain4Panel from '../../assets/Dining Room/Curtains/Curtains_04_B.png';
import diningCurtain5Panel from '../../assets/Dining Room/Curtains/Curtains_05_B.png';

import diningCurtain1Board from '../../assets/Dining Room/Curtains/Curtains_01_A.png';
import diningCurtain2Board from '../../assets/Dining Room/Curtains/Curtains_02_A.png';
import diningCurtain3Board from '../../assets/Dining Room/Curtains/Curtains_03_A.png';
import diningCurtain4Board from '../../assets/Dining Room/Curtains/Curtains_04_A.png';
import diningCurtain5Board from '../../assets/Dining Room/Curtains/Curtains_05_A.png';

import diningSeat1Panel from '../../assets/Dining Room/Dining Sets/Dining-Sets_01_B.png';
import diningSeat2Panel from '../../assets/Dining Room/Dining Sets/Dining-Sets_02_B.png';
import diningSeat3Panel from '../../assets/Dining Room/Dining Sets/Dining-Sets_03_B.png';
import diningSeat1Board from '../../assets/Dining Room/Dining Sets/Dining-Sets_01_A.png';
import diningSeat2Board from '../../assets/Dining Room/Dining Sets/Dining-Sets_02_A.png';
import diningSeat3Board from '../../assets/Dining Room/Dining Sets/Dining-Sets_03_A.png';

import foodUtensil1Panel from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_01_B.png';
import foodUtensil2Panel from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_02_B.png';
import foodUtensil3Panel from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_03_B.png';
import foodUtensil1Board from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_01_A.png';
import foodUtensil2Board from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_02_A.png';
import foodUtensil3Board from '../../assets/Dining Room/Food & Utensils/Food-&-Utensils_03_A.png';

import diningMirror1Panel from '../../assets/Dining Room/Mirrors/Mirrors_01_B.png';
//import diningMirror2Panel from '../../assets/Dining Room/Mirrors/Mirrors_02_B.png';
import diningMirror3Panel from '../../assets/Dining Room/Mirrors/Mirrors_03_B.png';
import diningMirror4Panel from '../../assets/Dining Room/Mirrors/Mirrors_04_B.png';
import diningMirror5Panel from '../../assets/Dining Room/Mirrors/Mirrors_05_B.png';
import diningMirror1Board from '../../assets/Dining Room/Mirrors/Mirrors_01_A.png';
import diningMirror2Board from '../../assets/Dining Room/Mirrors/Mirrors_02_A.png';
import diningMirror3Board from '../../assets/Dining Room/Mirrors/Mirrors_03_A.png';
import diningMirror4Board from '../../assets/Dining Room/Mirrors/Mirrors_04_A.png';
import diningMirror5Board from '../../assets/Dining Room/Mirrors/Mirrors_05_A.png';

import diningTable1Panel from '../../assets/Dining Room/Tables/Tables_01_B.png';
import diningTable2Panel from '../../assets/Dining Room/Tables/Tables_02_B.png';
import diningTable3Panel from '../../assets/Dining Room/Tables/Tables_03_B.png';
import diningTable4Panel from '../../assets/Dining Room/Tables/Tables_04_B.png';
import diningTable5Panel from '../../assets/Dining Room/Tables/Tables_05_B.png';
import diningTable1Board from '../../assets/Dining Room/Tables/Tables_01_A.png';
import diningTable2Board from '../../assets/Dining Room/Tables/Tables_02_A.png';
import diningTable3Board from '../../assets/Dining Room/Tables/Tables_03_A.png';
import diningTable4Board from '../../assets/Dining Room/Tables/Tables_04_A.png';
import diningTable5Board from '../../assets/Dining Room/Tables/Tables_05_A.png';

import officeChair1Panel from '../../assets/Office/Chairs/Chairs_01_B.png';
import officeChair2Panel from '../../assets/Office/Chairs/Chairs_02_B.png';
import officeChair3Panel from '../../assets/Office/Chairs/Chairs_03_B.png';
import officeChair4Panel from '../../assets/Office/Chairs/Chairs_04_B.png';
import officeChair5Panel from '../../assets/Office/Chairs/Chairs_05_B.png';
import officeChair6Panel from '../../assets/Office/Chairs/Chairs_06_B.png';
import officeChair1Board from '../../assets/Office/Chairs/Chairs_01_A.png';
import officeChair2Board from '../../assets/Office/Chairs/Chairs_02_A.png';
import officeChair3Board from '../../assets/Office/Chairs/Chairs_03_A.png';
import officeChair4Board from '../../assets/Office/Chairs/Chairs_04_A.png';
import officeChair5Board from '../../assets/Office/Chairs/Chairs_05_A.png';
import officeChair6Board from '../../assets/Office/Chairs/Chairs_06_A.png';

import officeDesk1Panel from '../../assets/Office/Desks/Desks_01_B.png';
import officeDesk2Panel from '../../assets/Office/Desks/Desks_02_B.png';
import officeDesk3Panel from '../../assets/Office/Desks/Desks_03_B.png';
import officeDesk4Panel from '../../assets/Office/Desks/Desks_04_B.png';
import officeDesk5Panel from '../../assets/Office/Desks/Desks_05_B.png';
import officeDesk6Panel from '../../assets/Office/Desks/Desks_06_B.png';
import officeDesk7Panel from '../../assets/Office/Desks/Desks_07_B.png';
import officeDesk1Board from '../../assets/Office/Desks/Desks_01_A.png';
import officeDesk2Board from '../../assets/Office/Desks/Desks_02_A.png';
import officeDesk3Board from '../../assets/Office/Desks/Desks_03_A.png';
import officeDesk4Board from '../../assets/Office/Desks/Desks_04_A.png';
import officeDesk5Board from '../../assets/Office/Desks/Desks_05_A.png';
import officeDesk6Board from '../../assets/Office/Desks/Desks_06_A.png';
import officeDesk7Board from '../../assets/Office/Desks/Desks_07_A.png';


import officeFileCabinet1Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_01_B.png';
import officeFileCabinet2Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_02_B.png';
import officeFileCabinet3Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_03_B.png';
import officeFileCabinet4Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_04_B.png';
/*
import officeFileCabinet5Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_05_B.png';
import officeFileCabinet6Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_06_B.png';
import officeFileCabinet7Panel from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_07_B.png';
*/
import officeFileCabinet1Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_01_A.png';
import officeFileCabinet2Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_02_A.png';
import officeFileCabinet3Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_03_A.png';
import officeFileCabinet4Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_04_A.png';
/*
import officeFileCabinet5Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_05_A.png';
import officeFileCabinet6Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_06_A.png';
import officeFileCabinet7Board from '../../assets/Office/File Cabinets & Storage/File-Cabinets-&-Storage_07_A.png';
*/

import officeAccessory1Panel from '../../assets/Office/Office Accessories/Office-Accessories_01_B.png';
import officeAccessory2Panel from '../../assets/Office/Office Accessories/Office-Accessories_02_B.png';
import officeAccessory3Panel from '../../assets/Office/Office Accessories/Office-Accessories_03_B.png';
import officeAccessory4Panel from '../../assets/Office/Office Accessories/Office-Accessories_04_B.png';
import officeAccessory1Board from '../../assets/Office/Office Accessories/Office-Accessories_01_A.png';
import officeAccessory2Board from '../../assets/Office/Office Accessories/Office-Accessories_02_A.png';
import officeAccessory3Board from '../../assets/Office/Office Accessories/Office-Accessories_03_A.png';
import officeAccessory4Board from '../../assets/Office/Office Accessories/Office-Accessories_04_A.png';
/*
import officeBookcase1Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_01_B.png';
import officeBookcase2Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_02_B.png';
import officeBookcase3Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_03_B.png';
import officeBookcase4Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_04_B.png';
import officeBookcase5Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_05_B.png';
import officeBookcase6Panel from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_06_B.png';
import officeBookcase1Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_01_A.png';
import officeBookcase2Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_02_A.png';
import officeBookcase3Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_03_A.png';
import officeBookcase4Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_04_A.png';
import officeBookcase5Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_05_A.png';
import officeBookcase6Board from '../../assets/Office/Shelves & Bookcases/Shelves-&-Bookcases_06_A.png';
*/

import bedroomAccessoriesPanel1 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_01_B.png';
import bedroomAccessoriesPanel2 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_02_B.png';
import bedroomAccessoriesPanel3 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_03_B.png';
import bedroomAccessoriesPanel4 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_04_B.png';
import bedroomAccessoriesBoard1 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_01_A.png';
import bedroomAccessoriesBoard2 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_02_A.png';
import bedroomAccessoriesBoard3 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_03_A.png';
import bedroomAccessoriesBoard4 from '../../assets/Bedroom/Bedroom Accessories/Bedroom-Accessories_04_A.png';

import bed1Panel from '../../assets/Bedroom/Beds/Beds_01_B.png';
import bed2Panel from '../../assets/Bedroom/Beds/Beds_02_B.png';
import bed3Panel from '../../assets/Bedroom/Beds/Beds_03_B.png';
import bed4Panel from '../../assets/Bedroom/Beds/Beds_04_B.png';
import bed5Panel from '../../assets/Bedroom/Beds/Beds_05_B.png';
import bed1Board from '../../assets/Bedroom/Beds/Beds_01_A.png';
import bed2Board from '../../assets/Bedroom/Beds/Beds_02_A.png';
import bed3Board from '../../assets/Bedroom/Beds/Beds_03_A.png';
import bed4Board from '../../assets/Bedroom/Beds/Beds_04_A.png';
import bed5Board from '../../assets/Bedroom/Beds/Beds_05_A.png';


import singleBed1Board from '../../assets/Bedroom/Single Bed/Single-Bed_01_B.png';
import singleBed2Board from '../../assets/Bedroom/Single Bed/Single-Bed_02_B.png';
import singleBed3Board from '../../assets/Bedroom/Single Bed/Single-Bed_03_B.png';
import singleBed4Board from '../../assets/Bedroom/Single Bed/Single-Bed_04_B.png';

import singleBed1Panel from '../../assets/Bedroom/Single Bed/Single-Bed_01_A.png';
import singleBed2Panel from '../../assets/Bedroom/Single Bed/Single-Bed_02_A.png';
import singleBed3Panel from '../../assets/Bedroom/Single Bed/Single-Bed_03_A.png';
import singleBed4Panel from '../../assets/Bedroom/Single Bed/Single-Bed_04_A.png';

import bunkBedPanel1 from '../../assets/Bedroom/Bunk Beds/bund-bed_01_B.png';
import bunkBedPanel2 from '../../assets/Bedroom/Bunk Beds/bund-bed_02_B.png';
import bunkBedPanel3 from '../../assets/Bedroom/Bunk Beds/bund-bed_03_B.png';
import bunkBedPanel4 from '../../assets/Bedroom/Bunk Beds/bund-bed_04_B.png';
import bunkBedBoard1 from '../../assets/Bedroom/Bunk Beds/bund-bed_01_A.png';
import bunkBedBoard2 from '../../assets/Bedroom/Bunk Beds/bund-bed_02_A.png';
import bunkBedBoard3 from '../../assets/Bedroom/Bunk Beds/bund-bed_03_A.png';
import bunkBedBoard4 from '../../assets/Bedroom/Bunk Beds/bund-bed_04_A.png';

import bedroomDresser1Panel from '../../assets/Bedroom/Dressers/Dressers_01_B.png';
import bedroomDresser2Panel from '../../assets/Bedroom/Dressers/Dressers_02_B.png';
import bedroomDresser3Panel from '../../assets/Bedroom/Dressers/Dressers_03_B.png';
import bedroomDresser4Panel from '../../assets/Bedroom/Dressers/Dressers_04_B.png';
import bedroomDresser1Board from '../../assets/Bedroom/Dressers/Dressers_01_A.png';
import bedroomDresser2Board from '../../assets/Bedroom/Dressers/Dressers_02_A.png';
import bedroomDresser3Board from '../../assets/Bedroom/Dressers/Dressers_03_A.png';
import bedroomDresser4Board from '../../assets/Bedroom/Dressers/Dressers_04_A.png';

import dressingTable1Panel from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_01_B.png';
import dressingTable2Panel from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_02_B.png';
import dressingTable3Panel from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_03_B.png';
import dressingTable4Panel from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_04_B.png';
import dressingTable1Board from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_01_A.png';
import dressingTable2Board from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_02_A.png';
import dressingTable3Board from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_03_A.png';
import dressingTable4Board from '../../assets/Bedroom/Dressing Tables/Dressing-Tables_04_A.png';

import kidsBed1Panel from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_01_B.png";
import kidsBed2Panel from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_02_B.png";
import kidsBed3Panel from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_03_B.png";
import kidsBed1Board from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_01_A.png";
import kidsBed2Board from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_02_A.png";
import kidsBed3Board from "../../assets/Bedroom/Kids' Beds/Kids'-Beds_03_A.png";

import wardrobePanel1 from '../../assets/Bedroom/Wardrobes/Wardrobes_01_B.png';
import wardrobePanel2 from '../../assets/Bedroom/Wardrobes/Wardrobes_02_B.png';
import wardrobePanel3 from '../../assets/Bedroom/Wardrobes/Wardrobes_03_B.png';
import wardrobePanel4 from '../../assets/Bedroom/Wardrobes/Wardrobes_04_B.png';
import wardrobePanel5 from '../../assets/Bedroom/Wardrobes/Wardrobes_05_B.png';
import wardrobePanel6 from '../../assets/Bedroom/Wardrobes/Wardrobes_06_B.png';
import wardrobeBoard1 from '../../assets/Bedroom/Wardrobes/Wardrobes_01_A.png';
import wardrobeBoard2 from '../../assets/Bedroom/Wardrobes/Wardrobes_02_A.png';
import wardrobeBoard3 from '../../assets/Bedroom/Wardrobes/Wardrobes_03_A.png';
import wardrobeBoard4 from '../../assets/Bedroom/Wardrobes/Wardrobes_04_A.png';
import wardrobeBoard5 from '../../assets/Bedroom/Wardrobes/Wardrobes_05_A.png';
import wardrobeBoard6 from '../../assets/Bedroom/Wardrobes/Wardrobes_06_A.png';


import nightTablePanel1 from '../../assets/Bedroom/Night Tables/Night-Tables_01_B.png';
import nightTablePanel2 from '../../assets/Bedroom/Night Tables/Night-Tables_02_B.png';
import nightTablePanel3 from '../../assets/Bedroom/Night Tables/Night-Tables_03_B.png';
import nightTablePanel4 from '../../assets/Bedroom/Night Tables/Night-Tables_04_B.png';
import nightTableBoard1 from '../../assets/Bedroom/Night Tables/Night-Tables_01_A.png';
import nightTableBoard2 from '../../assets/Bedroom/Night Tables/Night-Tables_02_A.png';
import nightTableBoard3 from '../../assets/Bedroom/Night Tables/Night-Tables_03_A.png';
import nightTableBoard4 from '../../assets/Bedroom/Night Tables/Night-Tables_04_A.png';

import officeAccessoryPanel1 from '../../assets/Meeting Room/Accessories/Office-Accessories_01_B.png';
import officeAccessoryPanel2 from '../../assets/Meeting Room/Accessories/Office-Accessories_02_B.png';
//import officeAccessoryPanel3 from '../../assets/Meeting Room/Accessories/Office-Accessories_03_B.png';
import officeAccessoryBoard1 from '../../assets/Meeting Room/Accessories/Office-Accessories_01_A.png';
import officeAccessoryBoard2 from '../../assets/Meeting Room/Accessories/Office-Accessories_02_A.png';
//import officeAccessoryBoard3 from '../../assets/Meeting Room/Accessories/Office-Accessories_03_A.png';

import chairPanel1 from '../../assets/Meeting Room/Chairs/Chairs_01_B.png';
import chairPanel2 from '../../assets/Meeting Room/Chairs/Chairs_02_B.png';
import chairPanel3 from '../../assets/Meeting Room/Chairs/Chairs_03_B.png';
import chairPanel4 from '../../assets/Meeting Room/Chairs/Chairs_04_B.png';
import chairPanel5 from '../../assets/Meeting Room/Chairs/Chairs_05_B.png';
import chairPanel6 from '../../assets/Meeting Room/Chairs/Chairs_06_B.png';
import chairBoard1 from '../../assets/Meeting Room/Chairs/Chairs_01_A.png';
import chairBoard2 from '../../assets/Meeting Room/Chairs/Chairs_02_A.png';
import chairBoard3 from '../../assets/Meeting Room/Chairs/Chairs_03_A.png';
import chairBoard4 from '../../assets/Meeting Room/Chairs/Chairs_04_A.png';
import chairBoard5 from '../../assets/Meeting Room/Chairs/Chairs_05_A.png';
import chairBoard6 from '../../assets/Meeting Room/Chairs/Chairs_06_A.png';

import storagePanel1 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_01_B.png';
import storagePanel2 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_02_B.png';
import storagePanel3 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_03_B.png';
import storagePanel4 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_04_B.png';
/*
import storagePanel5 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_05_B.png';
import storagePanel6 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_06_B.png';
import storagePanel7 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_07_B.png';
*/
import storageBoard1 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_01_A.png';
import storageBoard2 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_02_A.png';
import storageBoard3 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_03_A.png';
import storageBoard4 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_04_A.png';
/*
import storageBoard5 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_05_A.png';
import storageBoard6 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_06_A.png';
import storageBoard7 from '../../assets/Meeting Room/File Cabinets & Storage/File-Cabinets-&-Storage_07_A.png';
*/
/*
import shelvePanel1 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_01_B.png';
import shelvePanel2 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_02_B.png';
import shelvePanel3 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_03_B.png';
import shelvePanel4 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_04_B.png';
import shelvePanel5 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_05_B.png';
import shelvePanel6 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_06_B.png';
import shelvePanel7 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_07_B.png';
import shelveBoard1 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_01_A.png';
import shelveBoard2 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_02_A.png';
import shelveBoard3 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_03_A.png';
import shelveBoard4 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_04_A.png';
import shelveBoard5 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_05_A.png';
import shelveBoard6 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_06_A.png';
import shelveBoard7 from '../../assets/Meeting Room/Shelves & Bookcases/Shelves-&-Bookcases_07_A.png';
*/

import tablePanel1 from '../../assets/Meeting Room/Tables/Tables_01_B.png';
import tablePanel2 from '../../assets/Meeting Room/Tables/Tables_02_B.png';
import tablePanel3 from '../../assets/Meeting Room/Tables/Tables_03_B.png';
import tablePanel4 from '../../assets/Meeting Room/Tables/Tables_04_B.png';
import tablePanel5 from '../../assets/Meeting Room/Tables/Tables_05_B.png';
import tablePanel6 from '../../assets/Meeting Room/Tables/Tables_06_B.png';
import tablePanel7 from '../../assets/Meeting Room/Tables/Tables_07_B.png';
import tablePanel8 from '../../assets/Meeting Room/Tables/Tables_08_B.png';
import tablePanel9 from '../../assets/Meeting Room/Tables/Tables_09_B.png';
import tableBoard1 from '../../assets/Meeting Room/Tables/Tables_01_A.png';
import tableBoard2 from '../../assets/Meeting Room/Tables/Tables_02_A.png';
import tableBoard3 from '../../assets/Meeting Room/Tables/Tables_03_A.png';
import tableBoard4 from '../../assets/Meeting Room/Tables/Tables_04_A.png';
import tableBoard5 from '../../assets/Meeting Room/Tables/Tables_05_A.png';
import tableBoard6 from '../../assets/Meeting Room/Tables/Tables_06_A.png';
import tableBoard7 from '../../assets/Meeting Room/Tables/Tables_07_A.png';
import tableBoard8 from '../../assets/Meeting Room/Tables/Tables_08_A.png';
import tableBoard9 from '../../assets/Meeting Room/Tables/Tables_09_A.png';


import livingRoomArtPosterBoard1 from '../../assets/Living room/Art & Posters/Art-&-Posters_01_A.png';
import livingRoomArtPosterBoard2 from '../../assets/Living room/Art & Posters/Art-&-Posters_02_A.png';
import livingRoomArtPosterBoard3 from '../../assets/Living room/Art & Posters/Art-&-Posters_03_A.png';
import livingRoomArtPosterPanel1 from '../../assets/Living room/Art & Posters/Art-&-Posters_01_B.png';
import livingRoomArtPosterPanel2 from '../../assets/Living room/Art & Posters/Art-&-Posters_02_B.png';
import livingRoomArtPosterPanel3 from '../../assets/Living room/Art & Posters/Art-&-Posters_03_B.png';

import livingRoomCarpet1Panel from '../../assets/Living room/Carpets/Carpets_01_B.png';
import livingRoomCarpet1Board from '../../assets/Living room/Carpets/Carpets_01_A.png';

import livingRoomCarpet2Panel from '../../assets/Living room/Carpets/Carpets_02_B.png';
import livingRoomCarpet2Board from '../../assets/Living room/Carpets/Carpets_02_A.png';

import livingRoomCarpet3Panel from '../../assets/Living room/Carpets/Carpets_03_B.png';
import livingRoomCarpet3Board from '../../assets/Living room/Carpets/Carpets_03_A.png';

import livingRoomCarpet4Panel from '../../assets/Living room/Carpets/Carpets_04_B.png';
import livingRoomCarpet4Board from '../../assets/Living room/Carpets/Carpets_04_A.png';

import livingRoomCarpet5Panel from '../../assets/Living room/Carpets/Carpets_05_B.png';
import livingRoomCarpet5Board from '../../assets/Living room/Carpets/Carpets_05_A.png';

import livingRoomCarpet6Panel from '../../assets/Living room/Carpets/Carpets_06_B.png';
import livingRoomCarpet6Board from '../../assets/Living room/Carpets/Carpets_06_A.png';

import livingRoomChaisesLongues1Panel from '../../assets/Living room/Chaises Longues/Chaises-Longues_01_B.png';
import livingRoomChaisesLongues1Board from '../../assets/Living room/Chaises Longues/Chaises-Longues_01_A.png';

import livingRoomChaisesLongues2Panel from '../../assets/Living room/Chaises Longues/Chaises-Longues_02_B.png';
import livingRoomChaisesLongues2Board from '../../assets/Living room/Chaises Longues/Chaises-Longues_02_A.png';

import livingRoomChaisesLongues3Panel from '../../assets/Living room/Chaises Longues/Chaises-Longues_03_B.png';
import livingRoomChaisesLongues3Board from '../../assets/Living room/Chaises Longues/Chaises-Longues_03_A.png';

import livingRoomChaisesLongues4Panel from '../../assets/Living room/Chaises Longues/Chaises-Longues_04_B.png';
import livingRoomChaisesLongues4Board from '../../assets/Living room/Chaises Longues/Chaises-Longues_04_A.png';

import livingRoomChaisesLongues5Panel from '../../assets/Living room/Chaises Longues/Chaises-Longues_05_B.png';
import livingRoomChaisesLongues5Board from '../../assets/Living room/Chaises Longues/Chaises-Longues_05_A.png';

import livingRoomCoffeeTable1Panel from '../../assets/Living room/Coffee Tables/Coffee-Tables_01_B.png';
import livingRoomCoffeeTable1Board from '../../assets/Living room/Coffee Tables/Coffee-Tables_01_A.png';

import livingRoomCoffeeTable2Panel from '../../assets/Living room/Coffee Tables/Coffee-Tables_02_B.png';
import livingRoomCoffeeTable2Board from '../../assets/Living room/Coffee Tables/Coffee-Tables_02_A.png';

import livingRoomCoffeeTable3Panel from '../../assets/Living room/Coffee Tables/Coffee-Tables_03_B.png';
import livingRoomCoffeeTable3Board from '../../assets/Living room/Coffee Tables/Coffee-Tables_03_A.png';

import livingRoomCoffeeTable4Panel from '../../assets/Living room/Coffee Tables/Coffee-Tables_04_B.png';
import livingRoomCoffeeTable4Board from '../../assets/Living room/Coffee Tables/Coffee-Tables_04_A.png';

import livingRoomCoffeeTable5Panel from '../../assets/Living room/Coffee Tables/Coffee-Tables_05_B.png';
import livingRoomCoffeeTable5Board from '../../assets/Living room/Coffee Tables/Coffee-Tables_05_A.png';

import livingRoomConsoleTable1Panel from '../../assets/Living room/Console Tables/Console-Tables_01_B.png';
import livingRoomConsoleTable1Board from '../../assets/Living room/Console Tables/Console-Tables_01_A.png';

import livingRoomConsoleTable2Panel from '../../assets/Living room/Console Tables/Console-Tables_02_B.png';
import livingRoomConsoleTable2Board from '../../assets/Living room/Console Tables/Console-Tables_02_A.png';

import livingRoomConsoleTable3Panel from '../../assets/Living room/Console Tables/Console-Tables_03_B.png';
import livingRoomConsoleTable3Board from '../../assets/Living room/Console Tables/Console-Tables_03_A.png';

import livingRoomConsoleTable4Panel from '../../assets/Living room/Console Tables/Console-Tables_04_B.png';
import livingRoomConsoleTable4Board from '../../assets/Living room/Console Tables/Console-Tables_04_A.png';

import livingRoomFireplace1Panel from '../../assets/Living room/Fireplaces/Fireplaces_01_B.png';
import livingRoomFireplace1Board from '../../assets/Living room/Fireplaces/Fireplaces_01_A.png';

import livingRoomFireplace2Panel from '../../assets/Living room/Fireplaces/Fireplaces_02_B.png';
import livingRoomFireplace2Board from '../../assets/Living room/Fireplaces/Fireplaces_02_A.png';

import livingRoomFireplace3Panel from '../../assets/Living room/Fireplaces/Fireplaces_03_B.png';
import livingRoomFireplace3Board from '../../assets/Living room/Fireplaces/Fireplaces_03_A.png';

import livingRoomFireplace4Panel from '../../assets/Living room/Fireplaces/Fireplaces_04_B.png';
import livingRoomFireplace4Board from '../../assets/Living room/Fireplaces/Fireplaces_04_A.png';

import livingRoomFireplace5Panel from '../../assets/Living room/Fireplaces/Fireplaces_05_B.png';
import livingRoomFireplace5Board from '../../assets/Living room/Fireplaces/Fireplaces_05_A.png';

import livingRoomHutchBuffet1Panel from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_01_B.png';
import livingRoomHutchBuffet1Board from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_01__A.png';

import livingRoomHutchBuffet2Panel from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_02_B.png';
import livingRoomHutchBuffet2Board from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_02_A.png';

import livingRoomHutchBuffet3Panel from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_03_B.png';
import livingRoomHutchBuffet3Board from '../../assets/Living room/Hutches & Buffets/Hutches-&-Buffets_03_A.png';

import livingRoomLoungeChair1Panel from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_01_B.png';
import livingRoomLoungeChair1Board from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_01_A.png';

import livingRoomLoungeChair2Panel from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_02_B.png';
import livingRoomLoungeChair2Board from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_02_A.png';

import livingRoomLoungeChair3Panel from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_03_B.png';
import livingRoomLoungeChair3Board from '../../assets/Living room/Lounge Chairs/Lounge-Chairs_03_A.png';

import livingRoomLoveseat1Panel from '../../assets/Living room/Loveseats/loveseat_01_B.png';
import livingRoomLoveseat1Board from '../../assets/Living room/Loveseats/loveseat_01_A.png';
import livingRoomLoveseat2Panel from '../../assets/Living room/Loveseats/loveseat_02_B.png';
import livingRoomLoveseat2Board from '../../assets/Living room/Loveseats/loveseat_02_A.png';
import livingRoomLoveseat3Panel from '../../assets/Living room/Loveseats/loveseat_03_B.png';
import livingRoomLoveseat3Board from '../../assets/Living room/Loveseats/loveseat_03_A.png';
import livingRoomLoveseat4Panel from '../../assets/Living room/Loveseats/loveseat_04_B.png';
import livingRoomLoveseat4Board from '../../assets/Living room/Loveseats/loveseat_04_A.png';


import livingRoomMediaUnit1Panel from '../../assets/Living room/Media Units/Media-Units_01_B.png';
import livingRoomMediaUnit1Board from '../../assets/Living room/Media Units/Media-Units_01_A.png';

import livingRoomMediaUnit2Panel from '../../assets/Living room/Media Units/Media-Units_02_B.png';
import livingRoomMediaUnit2Board from '../../assets/Living room/Media Units/Media-Units_02_A.png';

import livingRoomMediaUnit3Panel from '../../assets/Living room/Media Units/Media-Units_03_B.png';
import livingRoomMediaUnit3Board from '../../assets/Living room/Media Units/Media-Units_03_A.png';

import livingRoomMediaUnit4Panel from '../../assets/Living room/Media Units/Media-Units_04_B.png';
import livingRoomMediaUnit4Board from '../../assets/Living room/Media Units/Media-Units_04_A.png';

import livingRoomMediaUnit5Panel from '../../assets/Living room/Media Units/Media-Units_05_B.png';
import livingRoomMediaUnit5Board from '../../assets/Living room/Media Units/Media-Units_05_A.png';

import livingRoomOttomanBench1Panel from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_01_B.png';
import livingRoomOttomanBench1Board from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_01_A.png';

import livingRoomOttomanBench2Panel from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_02_B.png';
import livingRoomOttomanBench2Board from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_02_A.png';

import livingRoomOttomanBench3Panel from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_03_B.png';
import livingRoomOttomanBench3Board from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_03_A.png';

import livingRoomOttomanBench4Panel from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_04_B.png';
import livingRoomOttomanBench4Board from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_04_A.png';

import livingRoomOttomanBench5Panel from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_05_B.png';
import livingRoomOttomanBench5Board from '../../assets/Living room/Ottomans & Benches/Ottomans-&-Benches_05_A.png';

import livingRoomScreenDivider1Panel from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_01_B.png';
import livingRoomScreenDivider1Board from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_01_A.png';

import livingRoomScreenDivider2Panel from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_02_B.png';
import livingRoomScreenDivider2Board from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_02_A.png';

import livingRoomScreenDivider3Panel from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_03_B.png';
import livingRoomScreenDivider3Board from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_03_A.png';

import livingRoomScreenDivider4Panel from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_04_B.png';
import livingRoomScreenDivider4Board from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_04_A.png';

import livingRoomScreenDivider5Panel from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_05_B.png';
import livingRoomScreenDivider5Board from '../../assets/Living room/Screens & Dividers/Screens-&-Dividers_05_A.png';

import livingRoomSideTable1Panel from '../../assets/Living room/Side Tables/Side-Tables_01_B.png';
import livingRoomSideTable1Board from '../../assets/Living room/Side Tables/Side-Tables_01_A.png';

import livingRoomSideTable2Panel from '../../assets/Living room/Side Tables/Side-Tables_02_B.png';
import livingRoomSideTable2Board from '../../assets/Living room/Side Tables/Side-Tables_02_A.png';

import livingRoomSideTable3Panel from '../../assets/Living room/Side Tables/Side-Tables_03_B.png';
import livingRoomSideTable3Board from '../../assets/Living room/Side Tables/Side-Tables_03_A.png';

import livingRoomSideTable4Panel from '../../assets/Living room/Side Tables/Side-Tables_04_B.png';
import livingRoomSideTable4Board from '../../assets/Living room/Side Tables/Side-Tables_04_A.png';

import livingRoomSideTable5Panel from '../../assets/Living room/Side Tables/Side-Tables_05_B.png';
import livingRoomSideTable5Board from '../../assets/Living room/Side Tables/Side-Tables_05_A.png';

import livingRoomSingleSofa1Panel from '../../assets/Living room/Single Sofa/Single-Sofa_01_B.png';
import livingRoomSingleSofa1Board from '../../assets/Living room/Single Sofa/Single-Sofa_01_A.png';

import livingRoomSingleSofa2Panel from '../../assets/Living room/Single Sofa/Single-Sofa_02_B.png';
import livingRoomSingleSofa2Board from '../../assets/Living room/Single Sofa/Single-Sofa_02_A.png';

import livingRoomSingleSofa3Panel from '../../assets/Living room/Single Sofa/Single-Sofa_03_B.png';
import livingRoomSingleSofa3Board from '../../assets/Living room/Single Sofa/Single-Sofa_03_A.png';

import livingRoomSingleSofa4Panel from '../../assets/Living room/Single Sofa/Single-Sofa_04_B.png';
import livingRoomSingleSofa4Board from '../../assets/Living room/Single Sofa/Single-Sofa_04_A.png';

import livingRoomSingleSofa5Panel from '../../assets/Living room/Single Sofa/Single-Sofa_05_B.png';
import livingRoomSingleSofa5Board from '../../assets/Living room/Single Sofa/Single-Sofa_05_A.png';

import sofa6Board from '../../assets/Living room/Sofas/sofa_06_A.png';
import sofa6Panel from '../../assets/Living room/Sofas/sofa_06_B.png';

import sofa7Board from '../../assets/Living room/Sofas/sofa_07_A.png';
import sofa7Panel from '../../assets/Living room/Sofas/sofa_07_B.png';

import sofa8Board from '../../assets/Living room/Sofas/sofa_08_A.png';
import sofa8Panel from '../../assets/Living room/Sofas/sofa_08_B.png';

import sofa9Board from '../../assets/Living room/Sofas/sofa_09_A.png';
import sofa9Panel from '../../assets/Living room/Sofas/sofa_09_B.png';

import sofa10Board from '../../assets/Living room/Sofas/sofa_10_A.png';
import sofa10Panel from '../../assets/Living room/Sofas/sofa_10_B.png';


import livingRoomSofaSet1Panel from '../../assets/Living room/Sofa Sets/Sofa-Set_01_B.png';
import livingRoomSofaSet1Board from '../../assets/Living room/Sofa Sets/Sofa-Set_01_A.png';

import livingRoomSofaSet2Panel from '../../assets/Living room/Sofa Sets/Sofa-Set_02_B.png';
import livingRoomSofaSet2Board from '../../assets/Living room/Sofa Sets/Sofa-Set_02_A.png';

import livingRoomSofaSet3Panel from '../../assets/Living room/Sofa Sets/Sofa-Set_03_B.png';
import livingRoomSofaSet3Board from '../../assets/Living room/Sofa Sets/Sofa-Set_03_A.png';

import livingRoomSofaSet4Panel from '../../assets/Living room/Sofa Sets/Sofa-Set_04_B.png';
import livingRoomSofaSet4Board from '../../assets/Living room/Sofa Sets/Sofa-Set_04_A.png';

import livingRoomSofa1Panel from '../../assets/Living room/Sofas/Sofa_01_B.png';
import livingRoomSofa1Board from '../../assets/Living room/Sofas/Sofa_01_A.png';

import livingRoomSofa2Panel from '../../assets/Living room/Sofas/Sofa_02_B.png';
import livingRoomSofa2Board from '../../assets/Living room/Sofas/Sofa_02_A.png';

import livingRoomSofa3Panel from '../../assets/Living room/Sofas/Sofa_03_B.png';
import livingRoomSofa3Board from '../../assets/Living room/Sofas/Sofa_03_A.png';

import livingRoomSofa4Panel from '../../assets/Living room/Sofas/Sofa_04_B.png';
import livingRoomSofa4Board from '../../assets/Living room/Sofas/Sofa_04_A.png';

import livingRoomSofa5Panel from '../../assets/Living room/Sofas/Sofa_05_B.png';
import livingRoomSofa5Board from '../../assets/Living room/Sofas/Sofa_05_A.png';

import livingRoomLeatherSofa1Panel from '../../assets/Living room/Sofas/LEATHER-SOFA_01_B.png';
import livingRoomLeatherSofa1Board from '../../assets/Living room/Sofas/LEATHER-SOFA_01_A.png';

import livingRoomCloverSofa1Panel from '../../assets/Living room/Sofas/clover-Sofa_01_B.png';
import livingRoomCloverSofa1Board from '../../assets/Living room/Sofas/clover-Sofa_01_A.png';



import livingRoomStorage1Panel from '../../assets/Living room/Storage/Storage_01_B.png';
import livingRoomStorage1Board from '../../assets/Living room/Storage/Storage_01_A.png';

//import livingRoomStorage2Panel from '../../assets/Living room/Storage/Storage_02_B.png';
//import livingRoomStorage2Board from '../../assets/Living room/Storage/Storage_02_A.png';

import livingRoomStorage3Panel from '../../assets/Living room/Storage/Storage_03_B.png';
import livingRoomStorage3Board from '../../assets/Living room/Storage/Storage_03_A.png';

import livingRoomDecorativeShelf1Panel from '../../assets/Living room/Storage/Decorative-Shelf_01_B.png';
import livingRoomDecorativeShelf1Board from '../../assets/Living room/Storage/Decorative-Shelf_01_A.png';

import livingRoomBookshelf2Panel from '../../assets/Living room/Storage/Bookshelf_02_B.png';
import livingRoomBookshelf2Board from '../../assets/Living room/Storage/Bookshelf_02_A.png';

import livingRoomBookshelf1Panel from '../../assets/Living room/Storage/Bookshelf_01_B.png';
import livingRoomBookshelf1Board from '../../assets/Living room/Storage/Bookshelf_01_A.png';

import livingRoomBookshelf3Panel from '../../assets/Living room/Storage/Bookshelf_03_B.png';
import livingRoomBookshelf3Board from '../../assets/Living room/Storage/Bookshelf_03_A.png';

import livingRoomBookshelf4Panel from '../../assets/Living room/Storage/Bookshelf_04_B.png';
import livingRoomBookshelf4Board from '../../assets/Living room/Storage/Bookshelf_04_A.png';

import livingRoomBookshelf5Panel from '../../assets/Living room/Storage/Bookshelf_05_B.png';
import livingRoomBookshelf5Board from '../../assets/Living room/Storage/Bookshelf_05_A.png';


import kitchenAvenuePiece1Board from '../../assets/Kitchen/Cabinet set/Avenue_piece_01_A.png';
import kitchenAvenuePiece2Board from '../../assets/Kitchen/Cabinet set/Avenue_piece_02_A.png';
import kitchenAvenuePiece1Panel from '../../assets/Kitchen/Cabinet set/Avenue_piece_01_B.png';
import kitchenAvenuePiece2Panel from '../../assets/Kitchen/Cabinet set/Avenue_piece_02_B.png';
import kitchencabinetSet1Panel from '../../assets/Kitchen/Cabinet set/Cabinet-set_01_B.png';
import kitchencabinetSet1Board from '../../assets/Kitchen/Cabinet set/Cabinet-set_01_A.png';
import kitchen1Panel from '../../assets/Kitchen/Cabinet set/Kitchen-01_B.png';
import kitchen1Board from '../../assets/Kitchen/Cabinet set/Kitchen-01_A.png';
import kitchenIsland1Panel from '../../assets/Kitchen/Cabinet set/Kitchen-Island_01_B.png';
import kitchenSet1Panel from '../../assets/Kitchen/Cabinet set/Kitchen-01_B.png';
import kitchenSet2Panel from '../../assets/Kitchen/Cabinet set/Kitchen-set_02_B.png';
import kitchenSet3Panel from '../../assets/Kitchen/Cabinet set/Kitchen-set_03_B.png';
import kitchenSet4Panel from '../../assets/Kitchen/Cabinet set/Kitchen-set_04_B.png';
import kitchenIsland1Board from '../../assets/Kitchen/Cabinet set/Kitchen-Island_01_A.png';
import kitchenSet1Board from '../../assets/Kitchen/Cabinet set/Kitchen-01_A.png';
import kitchenSet2Board from '../../assets/Kitchen/Cabinet set/Kitchen-set_02_A.png';
import kitchenSet3Board from '../../assets/Kitchen/Cabinet set/Kitchen-set_03_A.png';
import kitchenSet4Board from '../../assets/Kitchen/Cabinet set/Kitchen-set_04_A.png';

import kitchenCabinetUnit1Panel from '../../assets/Kitchen/Cabinet unit/Cabinet-unit_01_B.png';
import kitchenCabinetUnit2Panel from '../../assets/Kitchen/Cabinet unit/Cabinet-unit_02_B.png';
import kitchenCabinetUnit1Board from '../../assets/Kitchen/Cabinet unit/Cabinet-unit_01_A.png';
import kitchenCabinetUnit2Board from '../../assets/Kitchen/Cabinet unit/Cabinet-unit_02_A.png';

import kitchenCountertops1Panel from '../../assets/Kitchen/Countertops/Countertops_01_B.png';
import kitchenCountertops1Board from '../../assets/Kitchen/Countertops/Countertops_01_A.png';

import kitchenCountertops2Panel from '../../assets/Kitchen/Countertops/Countertops_Stainless Steel - 60cm_02_B.png';
import kitchenCountertops2Board from '../../assets/Kitchen/Countertops/Countertops_Stainless Steel - 60cm_02_A.png';

import kitchenSink1Panel from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_01_B.png';
import kitchenSink1Board from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_01_A.png';

import kitchenSink2Panel from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_02_B.png';
import kitchenSink2Board from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_02_A.png';

import kitchenSink3Panel from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_03_B.png';
import kitchenSink3Board from '../../assets/Kitchen/Kitchen Sink/Kitchen-Sink_03_A.png';

import kitchenCooktops1Panel from '../../assets/Kitchen/Ranges & Cooktops/Cooktops_01_B.png';
import kitchenCooktops1Board from '../../assets/Kitchen/Ranges & Cooktops/Cooktops_01_A.png';

import kitchenCooktops2Panel from '../../assets/Kitchen/Ranges & Cooktops/Cooktops_02_B.png';
import kitchenCooktops2Board from '../../assets/Kitchen/Ranges & Cooktops/Cooktops_02_A.png';

import kitchenWallHood1Panel from '../../assets/Kitchen/Ranges & Cooktops/Wall-Hood_01_B.png';
import kitchenWallHood1Board from '../../assets/Kitchen/Ranges & Cooktops/Wall-Hood_01_A.png';

import kitchenWallHood2Panel from '../../assets/Kitchen/Ranges & Cooktops/Wall-Hood_02_B.png';
import kitchenWallHood2Board from '../../assets/Kitchen/Ranges & Cooktops/Wall-Hood_02_A.png';

import kitchenRanges1Panel from '../../assets/Kitchen/Ranges & Cooktops/Ranges_01_B.png';
import kitchenRanges1Board from '../../assets/Kitchen/Ranges & Cooktops/Ranges_01_A.png';

import kitchenUtensils1Panel from '../../assets/Kitchen/Utensils/Utensils_01_B.png';
import kitchenUtensils1Board from '../../assets/Kitchen/Utensils/Utensils_01_A.png';

import kitchenUtensils2Panel from '../../assets/Kitchen/Utensils/Utensils_02_B.png';
import kitchenUtensils2Board from '../../assets/Kitchen/Utensils/Utensils_02_A.png';

import kitchenUtensils3Panel from '../../assets/Kitchen/Utensils/Utensils_03_B.png';
import kitchenUtensils3Board from '../../assets/Kitchen/Utensils/Utensils_03_A.png';

import kitchenWallOvens1Panel from '../../assets/Kitchen/Wall Ovens/Wall-Ovens_01_B.png';
import kitchenWallOvens1Board from '../../assets/Kitchen/Wall Ovens/Wall-Ovens_01_A.png';

import kitchenGasDoubleOvenRange1Panel from '../../assets/Kitchen/Wall Ovens/Gas-Double-Oven-Range_01_B.png';
import kitchenGasDoubleOvenRange1Board from '../../assets/Kitchen/Wall Ovens/Gas-Double-Oven-Range_01_A.png';

import kidsFurniturePanel1 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_01_B.png';
import kidsFurniturePanel2 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_02_B.png';
import kidsFurniturePanel3 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_03_B.png';
import kidsFurniturePanel4 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_04_B.png';
import kidsFurniturePanel5 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_05_B.png';
import kidsFurniturePanel6 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_06_B.png';
import kidsFurniturePanel7 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_07_B.png';
import kidsFurniturePanel8 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_08_B.png';
import kidsFurniturePanel9 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_09_B.png';
import kidsFurniturePanel10 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_10_B.png';
import kidsFurniturePanel11 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_11_B.png';
import kidsFurnitureBoard1 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_01_A.png';
import kidsFurnitureBoard2 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_02_A.png';
import kidsFurnitureBoard3 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_03_A.png';
import kidsFurnitureBoard4 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_04_A.png';
import kidsFurnitureBoard5 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_05_A.png';
import kidsFurnitureBoard6 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_06_A.png';
import kidsFurnitureBoard7 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_07_A.png';
import kidsFurnitureBoard8 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_08_A.png';
import kidsFurnitureBoard9 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_09_A.png';
import kidsFurnitureBoard10 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_10_A.png';
import kidsFurnitureBoard11 from '../../assets/Children\'s Room/Kids Furniture/Kids-Furniture_11_A.png';

import nurseryPanel1 from '../../assets/Children\'s Room/Nursery/Nursery_01_B.png';
import nurseryPanel2 from '../../assets/Children\'s Room/Nursery/Nursery_02_B.png';
import nurseryPanel3 from '../../assets/Children\'s Room/Nursery/Nursery_03_B.png';
import nurseryPanel4 from '../../assets/Children\'s Room/Nursery/Nursery_04_B.png';
import nurseryPanel5 from '../../assets/Children\'s Room/Nursery/Nursery_05_B.png';
import nurseryBoard1 from '../../assets/Children\'s Room/Nursery/Nursery_01_A.png';
import nurseryBoard2 from '../../assets/Children\'s Room/Nursery/Nursery_02_A.png';
import nurseryBoard3 from '../../assets/Children\'s Room/Nursery/Nursery_03_A.png';
import nurseryBoard4 from '../../assets/Children\'s Room/Nursery/Nursery_04_A.png';
import nurseryBoard5 from '../../assets/Children\'s Room/Nursery/Nursery_05_A.png';

import toysPanel1 from '../../assets/Children\'s Room/Toys/Toys_01_B.png';
import toysPanel2 from '../../assets/Children\'s Room/Toys/Toys_02_B.png';
import toysPanel3 from '../../assets/Children\'s Room/Toys/Toys_03_B.png';
import toysPanel4 from '../../assets/Children\'s Room/Toys/Toys_04_B.png';
import toysPanel5 from '../../assets/Children\'s Room/Toys/Toys_05_B.png';
import toysBoard1 from '../../assets/Children\'s Room/Toys/Toys_01_A.png';
import toysBoard2 from '../../assets/Children\'s Room/Toys/Toys_02_A.png';
import toysBoard3 from '../../assets/Children\'s Room/Toys/Toys_03_A.png';
import toysBoard4 from '../../assets/Children\'s Room/Toys/Toys_04_A.png';
import toysBoard5 from '../../assets/Children\'s Room/Toys/Toys_05_A.png';

import bathroomAccessory1Board from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_01_A.png';
import bathroomAccessory1Panel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_01_B.png';
import bathroomAccessory2Board from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_02_A.png';
import bathroomAccessory2Panel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_02_B.png';
import bathroomAccessory3Board from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_03_A.png';
import bathroomAccessory3Panel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_03_B.png';
import bathroomAccessory4Board from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_04_A.png';
import bathroomAccessory4Panel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_04_B.png';
import bathroomAccessory5Board from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_05_A.png';
import bathroomAccessory5Panel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-Accessories_05_B.png';
import toiletPaperBoard from '../../assets/Bathroom/Bathroom Accessories/Toilet-paper_01_A.png';
import toiletPaperPanel from '../../assets/Bathroom/Bathroom Accessories/Toilet-paper_01_B.png';

import showerBoard from '../../assets/Bathroom/Bathroom Accessories/Shower_01_A.png';
import showerPanel from '../../assets/Bathroom/Bathroom Accessories/Shower_01_B.png';
import shower2Board from '../../assets/Bathroom/Bathroom Accessories/Shower_02_A.png';
import shower2Panel from '../../assets/Bathroom/Bathroom Accessories/Shower_02_B.png';

import bathroomTowelRackBoard from '../../assets/Bathroom/Bathroom Accessories/Bathroom-towel-rack_01_A.png';
import bathroomTowelRackPanel from '../../assets/Bathroom/Bathroom Accessories/Bathroom-towel-rack_01_B.png';

import towelRackBoard from '../../assets/Bathroom/Bathroom Accessories/Towel-rack_01_A.png';
import towelRackPanel from '../../assets/Bathroom/Bathroom Accessories/Towel-rack_01_B.png';

import bathroomMirror1Panel from '../../assets/Bathroom/Mirrors/Mirrors_01_B.png';
import bathroomMirror1Board from '../../assets/Bathroom/Mirrors/Mirrors_01_A.png';

import bathroomMirror2Panel from '../../assets/Bathroom/Mirrors/Mirrors_02_B.png';
import bathroomMirror2Board from '../../assets/Bathroom/Mirrors/Mirrors_02_A.png';

import bathroomMirror3Panel from '../../assets/Bathroom/Mirrors/Mirrors_03_B.png';
import bathroomMirror3Board from '../../assets/Bathroom/Mirrors/Mirrors_03_A.png';

import bathroomMirror4Panel from '../../assets/Bathroom/Mirrors/Mirrors_04_B.png';
import bathroomMirror4Board from '../../assets/Bathroom/Mirrors/Mirrors_04_A.png';

import bathroomShowerDoor1Panel from '../../assets/Bathroom/Shower Doors/Shower-Doors_01_B.png';
import bathroomShowerDoor1Board from '../../assets/Bathroom/Shower Doors/Shower-Doors_01_A.png';
import bathroomShowerDoor2Panel from '../../assets/Bathroom/Shower Doors/Shower-Doors_02_B.png';
import bathroomShowerDoor2Board from '../../assets/Bathroom/Shower Doors/Shower-Doors_02_A.png';
import bathroomShowerDoor3Panel from '../../assets/Bathroom/Shower Doors/Shower-Doors_03_B.png';
import bathroomShowerDoor3Board from '../../assets/Bathroom/Shower Doors/Shower-Doors_03_A.png';
import bathroomShowerDoor4Panel from '../../assets/Bathroom/Shower Doors/Shower-Doors_04_B.png';
import bathroomShowerDoor4Board from '../../assets/Bathroom/Shower Doors/Shower-Doors_04_A.png';
import bathroomShowerDoor5Panel from '../../assets/Bathroom/Shower Doors/Shower-Doors_05_B.png';
import bathroomShowerDoor5Board from '../../assets/Bathroom/Shower Doors/Shower-Doors_05_A.png';

import bathroomToilet1Panel from '../../assets/Bathroom/Toilets/Toilets_01_B.png';
import bathroomToilet1Board from '../../assets/Bathroom/Toilets/Toilets_01_A.png';

import bathroomToilet2Panel from '../../assets/Bathroom/Toilets/Toilets_02_B.png';
import bathroomToilet2Board from '../../assets/Bathroom/Toilets/Toilets_02_A.png';

import bathroomToilet3Panel from '../../assets/Bathroom/Toilets/Toilets_03_B.png';
import bathroomToilet3Board from '../../assets/Bathroom/Toilets/Toilets_03_A.png';

import bathroomToilet4Panel from '../../assets/Bathroom/Toilets/Toilets_04_B.png';
import bathroomToilet4Board from '../../assets/Bathroom/Toilets/Toilets_04_A.png';



import bathroomTub1Panel from '../../assets/Bathroom/Tubs/Tubs_01_B.png';
import bathroomTub1Board from '../../assets/Bathroom/Tubs/Tubs_01_A.png';

import bathroomTub2Panel from '../../assets/Bathroom/Tubs/Tubs_02_B.png';
import bathroomTub2Board from '../../assets/Bathroom/Tubs/Tubs_02_A.png';

import bathroomTub3Panel from '../../assets/Bathroom/Tubs/Tubs_03_B.png';
import bathroomTub3Board from '../../assets/Bathroom/Tubs/Tubs_03_A.png';

import bathroomVanity1Panel from '../../assets/Bathroom/Vanities/Vanities_01_B.png';
import bathroomVanity1Board from '../../assets/Bathroom/Vanities/Vanities_01_A.png';

import bathroomVanity2Panel from '../../assets/Bathroom/Vanities/Vanities_02_B.png';
import bathroomVanity2Board from '../../assets/Bathroom/Vanities/Vanities_02_A.png';

import bathroomVanity3Panel from '../../assets/Bathroom/Vanities/Vanities_03_B.png';
import bathroomVanity3Board from '../../assets/Bathroom/Vanities/Vanities_03_A.png';

import bathroomVanity4Panel from '../../assets/Bathroom/Vanities/Vanities_04_B.png';
import bathroomVanity4Board from '../../assets/Bathroom/Vanities/Vanities_04_A.png';

import bathroomVanity5Panel from '../../assets/Bathroom/Vanities/Vanities_05_B.png';
import bathroomVanity5Board from '../../assets/Bathroom/Vanities/Vanities_05_A.png';

import bathroomVanity6Panel from '../../assets/Bathroom/Vanities/Vanities_06_B.png';
import bathroomVanity6Board from '../../assets/Bathroom/Vanities/Vanities_06_A.png';

import bathroomCabinet1Panel from '../../assets/Bathroom/Vanities/Bathroom-Cabinet_01_B.png';
import bathroomCabinet1Board from '../../assets/Bathroom/Vanities/Bathroom-Cabinet_01_A.png';

import bathroomCabinet2Panel from '../../assets/Bathroom/Vanities/Bathroom-Cabinet_02_B.png';
import bathroomCabinet2Board from '../../assets/Bathroom/Vanities/Bathroom-Cabinet_02_A.png';

import tissueBox1Panel from '../../assets/Tissue Box/Tissue-Box_01_A.png';
import tissueBox1Board from '../../assets/Tissue Box/Tissue-Box_01_B.png';

import paperTowelHolder1Panel from '../../assets/Bathroom/Bathroom Accessories/paper-towel-holder/paper-towel-holder_01_A.png';
import paperTowelHolder1Board from '../../assets/Bathroom/Bathroom Accessories/paper-towel-holder/paper-towel-holder_01_B.png';
import paperTowelHolder2Panel from '../../assets/Bathroom/Bathroom Accessories/paper-towel-holder/paper-towel-holder_02_A.png';
import paperTowelHolder2Board from '../../assets/Bathroom/Bathroom Accessories/paper-towel-holder/paper-towel-holder_02_B.png';

/**
 * Carpet Importation
 */

import carpet1Panel from '../../assets/Carpets/Carpet_001/Carpet_001_B.jpg';
import carpet1Board from '../../assets/Carpets/Carpet_001/Carpet_001_White.jpg';
import carpet2Panel from '../../assets/Carpets/Carpet_002/Carpet_002_B.jpg';
import carpet2Board from '../../assets/Carpets/Carpet_002/Carpet_002_White.jpg';
import carpet3Panel from '../../assets/Carpets/Carpet_003/Carpet_003_B.jpg';
import carpet3Board from '../../assets/Carpets/Carpet_003/Carpet_003_white.jpg';
import carpet4Panel from '../../assets/Carpets/Carpet_004/Carpet_004_B.jpg';
import carpet4Board from '../../assets/Carpets/Carpet_004/Carpet_004_white.jpg';
import carpet5Panel from '../../assets/Carpets/Carpet_005/Carpet_005_B.jpg';
import carpet5Board from '../../assets/Carpets/Carpet_005/Carpet_005_white.jpg';
import carpet6Panel from '../../assets/Carpets/Carpet_006/Carpet_006_white.jpg';
import carpet6Board from '../../assets/Carpets/Carpet_006/Carpet_006_white.jpg';
import carpet7Panel from '../../assets/Carpets/Carpet_007/Carpet_007_A.jpg';
import carpet7Board from '../../assets/Carpets/Carpet_007/Carpet_007_white.jpg';
import carpet8Panel from '../../assets/Carpets/Carpet_008/Carpet_008_B.jpg';
import carpet8Board from '../../assets/Carpets/Carpet_008/Carpet_008_Black.png';
import carpet9Panel from '../../assets/Carpets/Carpet_009/Carpet_009_B.jpg';
import carpet9Board from '../../assets/Carpets/Carpet_009/Carpet_009_black.jpg';
import carpet10Panel from '../../assets/Carpets/Carpet_010/Carpet_010_B.jpg';
import carpet10Board from '../../assets/Carpets/Carpet_010/Carpet_010_Black.png';
import carpet11Panel from '../../assets/Carpets/Carpet_011/Carpet_011_B.jpg';
import carpet11Board from '../../assets/Carpets/Carpet_011/Carpet_011_mix.jpg';
import carpet12Panel from '../../assets/Carpets/Carpet_012/Carpet_012_B.jpg';
import carpet12Board from '../../assets/Carpets/Carpet_012/Carpet_012_blue.png';

import carpet14Panel from '../../assets/Carpets/Carpet_014/Carpet_014_B.jpg';
import carpet14Board from '../../assets/Carpets/Carpet_014/Carpet_014_white.png';
import carpet15Panel from '../../assets/Carpets/Carpet_015/Carpet_015_B.jpg';
import carpet15Board from '../../assets/Carpets/Carpet_015/Carpet_015_green.jpg';
import carpet16Panel from '../../assets/Carpets/Carpet_016/Carpet_016_B.jpg';
import carpet16Board from '../../assets/Carpets/Carpet_016/Carpet_016_white.jpg';
import carpet17Panel from '../../assets/Carpets/Carpet_017/Carpet_017_B.jpg';
import carpet17Board from '../../assets/Carpets/Carpet_017/Carpet_017_white.jpg';
import carpet18Panel from '../../assets/Carpets/Carpet_018/Carpet_018_B.jpg';
import carpet18Board from '../../assets/Carpets/Carpet_018/Carpet_018_white.jpg';

const rotatable = [
    "officeAcc4",
    "white-goods1",
    "other-electronics6",
    "computer5",
    "showerDoor5",
]
export const rotateCache = new Set(rotatable);


export const ItemIcon = ({ src }) => <Image src={src} height='100%' />;

const parseDescription = (description) => {
    // Regular expression to match the numeric values
    const regex = /(\d+)'\s*(\d+)"?/g;

    // Match the regex against the description string
    const matches = description.match(regex);

    // Extract the height and width from the matches
    let height = 0;
    let width = 0;
    if (matches && matches.length >= 2) {
        height = parseInt(matches[0]) + parseInt(matches[1]) / 12; // Convert feet and inches to a single height value
        width = parseInt(matches[2]) + parseInt(matches[3]) / 12; // Convert feet and inches to a single width value
    }

    return { height, width };
};

export default [
    /*
    {
        "key": "roomtype",
        "title": "Room Type",
        "icon": <RoomTypeIcon />,
        "type": "menu",
        "children": [
            {
                key: "1",
                title: 'Living Room',
                value: 'Living Room',
                type: 'radio',
            },
            {
                key: "2",
                title: 'Bedroom',
                value: 'Bedroom',
                type: 'radio',
            },
            {
                key: "3",
                title: 'Kitchen',
                value: 'Kitchen',
                type: 'radio',
            },
            {
                key: "4",
                title: 'Bathroom',
                value: 'Bathroom',
                type: 'radio',
            },
            {
                key: "5",
                title: "Children's Room",
                value: "Children's Room",
                type: 'radio',
            },
            {
                key: "6",
                title: 'Dining Room',
                value: 'Dining Room',
                type: 'radio',
            },
            {
                key: "7",
                title: 'Office',
                value: 'Office',
                type: 'radio',
            },
            {
                key: "8",
                title: 'Meeting Room',
                value: 'Meeting Room',
                type: 'radio',
            }
        ]
    },
    */
    {
        "key": "furniture",
        "title": "Furniture",
        "icon": <FurnitureIcon />,
        "type": "menu",
        "children": [
            /**Accessories */
            {
                "key": "diningRoomAccessories",
                "title": "Accessories",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "tissueBox",
                        "title": "Tissue Box",
                        "type": "item",
                        "description": "0.22m x 0.14m x 0.17m (DWH)",
                        "icon": <ItemIcon src={tissueBox1Panel} />,
                        "panelIcon": tissueBox1Panel,
                        "boardIcon": tissueBox1Board,
                        "height": 5,
                        "width": 5,
                        "zIndex":5
                    },
                    {
                        "key": "bathroomMirror1",
                        "title": "Mirror 1",
                        "type": "item",
                        "description": "0.81m x 0.13m x 0.71m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror1Panel} />,
                        "panelIcon": bathroomMirror1Panel,
                        "boardIcon": bathroomMirror1Board,
                        "height": 7,
                        "width": 40,
                        "zIndex":5
                    },
                    {
                        "key": "bathroomMirror2",
                        "title": "Mirror 2",
                        "type": "item",
                        "description": "0.66m x 0.13m x 0.76m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror2Panel} />,
                        "panelIcon": bathroomMirror2Panel,
                        "boardIcon": bathroomMirror2Board,
                        "height": 8,
                        "width": 42,
                        "zIndex":5
                    },
                    {
                        "key": "bathroomMirror3",
                        "title": "Mirror 3",
                        "type": "item",
                        "description": "0.71m x 0.03m x 0.51m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror3Panel} />,
                        "panelIcon": bathroomMirror3Panel,
                        "boardIcon": bathroomMirror3Board,
                        "height": 2,
                        "width": 116,
                        "zIndex":5
                    },
                    {
                        "key": "bathroomMirror4",
                        "title": "Mirror 4",
                        "type": "item",
                        "description": "0.69m x 0.05m x 0.69m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror4Panel} />,
                        "panelIcon": bathroomMirror4Panel,
                        "boardIcon": bathroomMirror4Board,
                        "height": 3,
                        "width": 25,
                        "zIndex":5
                    },
                    {
                        "key": "diningMirror1",
                        "title": "Mirror 5",
                        "type": "item",
                        "description": "0.86m x 0.05m x 0.64m (DWH)",
                        "panelIcon": diningMirror1Panel,
                        "icon": <ItemIcon src={diningMirror1Panel} />,
                        "boardIcon": diningMirror1Board,
                        "height": 8,
                        "width": 74,
                        "zIndex":5
                    },
                    {
                        "key": "diningMirror3",
                        "title": "Mirror 6",
                        "type": "item",
                        "description": "0.89m x 0.05m x 0.56m (DWH)",
                        "panelIcon": diningMirror3Panel,
                        "icon": <ItemIcon src={diningMirror3Panel} />,
                        "boardIcon": diningMirror3Board,
                        "height": 9,
                        "width": 92,
                        "zIndex":5
                    },
                    {
                        "key": "diningMirror4",
                        "title": "Floor Mirror 1",
                        "type": "item",
                        "description": "0.66m x 0.41m x 1.68m (DWH)",
                        "panelIcon": diningMirror4Panel,
                        "icon": <ItemIcon src={diningMirror4Panel} />,
                        "boardIcon": diningMirror4Board,
                        "height": 18,
                        "width": 3,
                        "zIndex":5
                    },
                    {
                        "key": "diningMirror5",
                        "title": "Floor Mirror 2",
                        "type": "item",
                        "description": "0.61m x 0.30m x 1.73m (DWH)",
                        "panelIcon": diningMirror5Panel,
                        "icon": <ItemIcon src={diningMirror5Panel} />,
                        "boardIcon": diningMirror5Board,
                        "height": 14,
                        "width": 56,
                        "zIndex":5
                    },
                    {
                        "key": "toys1",
                        "title": "Toy 1",
                        "type": "item",
                        "description": "0.38m x 0.46m x 0.20m (DWH)",
                        "icon": <ItemIcon src={toysPanel1} />,
                        "panelIcon": toysPanel1,
                        "boardIcon": toysBoard1,
                        "height": 17,
                        "width": 14,
                        "zIndex":5
                    },
                    {
                        "key": "toys2",
                        "title": "Book Set 3",
                        "type": "item",
                        "description": "0.23m x 0.23m x 0.28m (DWH)",
                        "icon": <ItemIcon src={toysPanel2} />,
                        "panelIcon": toysPanel2,
                        "boardIcon": toysBoard2,
                        "height": 9,
                        "width": 9,
                        "zIndex":5
                    },
                    {
                        "key": "toys3",
                        "title": "Toy 2",
                        "type": "item",
                        "description": "0.97m x 0.43m x 0.51m (DWH)",
                        "icon": <ItemIcon src={toysPanel3} />,
                        "panelIcon": toysPanel3,
                        "boardIcon": toysBoard3,
                        "height": 20,
                        "width": 30,
                        "zIndex":5
                    },
                    {
                        "key": "toys4",
                        "title": "Toy Storage Box 2",
                        "type": "item",
                        "description": "0.58m x 0.36m x 0.66m (DWH)",
                        "icon": <ItemIcon src={toysPanel4} />,
                        "panelIcon": toysPanel4,
                        "boardIcon": toysBoard4,
                        "height": 15,
                        "width": 21,
                        "zIndex":5
                    },
                    {
                        "key": "toys5",
                        "title": "Toy 3",
                        "type": "item",
                        "description": "0.51m x 0.51m x 0.58m (DWH)",
                        "icon": <ItemIcon src={toysPanel5} />,
                        "panelIcon": toysPanel5,
                        "boardIcon": toysBoard5,
                        "height": 11,
                        "width": 11,
                        "zIndex":5
                    },
                    {
                        "key": "kidsFurniture6",
                        "title": `Toy Storage Box 1`,
                        "type": "item",
                        "description": "0.99m x 0.23m x 0.33m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel6} />,
                        "panelIcon": kidsFurniturePanel6,
                        "boardIcon": kidsFurnitureBoard6,
                        "height": 35,
                        "width": 129,
                        "zIndex":4
                    },
                    {
                        "key": "Art & Poster 1",
                        "title": "Painting 1",
                        "icon": <ItemIcon src={livingRoomArtPosterPanel1} />,
                        "boardIcon": livingRoomArtPosterBoard1,
                        "type": "item",
                        "description": "1.143m x 0.0254m x 0.7112m DWH",
                        "height": 2,
                        "width": 60,
                        "panelIcon": livingRoomArtPosterPanel1,
                        "zIndex":5
                    },
                    {
                        "key": "Art & Poster 2",
                        "title": "Painting 2",
                        "icon": <ItemIcon src={livingRoomArtPosterPanel2} />,
                        "boardIcon": livingRoomArtPosterBoard2,
                        "type": "item",
                        "description": "1.9304m x 0.0254m x 1.4488m DWH",
                        "height": 2,
                        "width": 60,
                        "panelIcon": livingRoomArtPosterPanel2,
                        "zIndex":5
                    },
                    {
                        "key": "Art & Poster 3",
                        "title": "Painting 3",
                        "icon": <ItemIcon src={livingRoomArtPosterPanel3} />,
                        "boardIcon": livingRoomArtPosterBoard3,
                        "type": "item",
                        "description": "1.9304m x 0.0254m x 1.4488m DWH",
                        "height": 2,
                        "width": 60,
                        "panelIcon": livingRoomArtPosterPanel3,
                        "zIndex":5
                    },
                    {
                        "key": "diningRoomAcc1",
                        "title": "Book Set 2",
                        "type": "item",
                        "description": "0.71m x 0.05m x 2.18m (DWH)",
                        "height": 15,
                        "width": 19,
                        "panelIcon": diningRoomAccessoriesPanel1,
                        "icon": <ItemIcon src={diningRoomAccessoriesPanel1} />,
                        "boardIcon": diningRoomAccessoriesBoard1,
                        "zIndex":5
                    },
                    {
                        "key": "diningRoomAcc2",
                        "title": "Wall Clock",
                        "type": "item",
                        "description": "0.30m x 0.05m x 0.30m (DWH)",
                        "height": 5,
                        "width": 12,
                        "panelIcon": diningRoomAccessoriesPanel2,
                        "icon": <ItemIcon src={diningRoomAccessoriesPanel2} />,
                        "boardIcon": diningRoomAccessoriesBoard2,
                        "zIndex":5
                    },
                    {
                        "key": "diningRoomAcc3",
                        "title": "Garbage Can",
                        "type": "item",
                        "description": "0.20m x 0.23m x 0.30m (DWH)",
                        "height": 12,
                        "width": 12,
                        "panelIcon": diningRoomAccessoriesPanel3,
                        "icon": <ItemIcon src={diningRoomAccessoriesPanel3} />,
                        "boardIcon": diningRoomAccessoriesBoard3,
                        "zIndex":5
                    },
                    {
                        "key": "officeAcc1",
                        "title": "Pen Holder & Notebook",
                        "type": "item",
                        "description": "0.36m x 0.33m x 0.18m (DWH)",
                        "height": 14,
                        "width": 13,
                        "panelIcon": officeAccessory1Panel,
                        "icon": <ItemIcon src={officeAccessory1Panel} />,
                        "boardIcon": officeAccessory1Board,
                        "zIndex":5
                    },
                    {
                        "key": "officeAcc2",
                        "title": "Picture 1",
                        "type": "item",
                        "description": "0.28m x 0.13m x 0.20m (DWH)",
                        "height": 11,
                        "width": 5,
                        "panelIcon": officeAccessory2Panel,
                        "icon": <ItemIcon src={officeAccessory2Panel} />,
                        "boardIcon": officeAccessory2Board,
                        "zIndex":5
                    },
                    {
                        "key": "officeAcc3",
                        "title": "Picture 2",
                        "type": "item",
                        "description": "0.28m x 0.51m x 0.30m (DWH)",
                        "height": 7,
                        "width": 11,
                        "panelIcon": officeAccessory3Panel,
                        "icon": <ItemIcon src={officeAccessory3Panel} />,
                        "boardIcon": officeAccessory3Board,
                        "zIndex":5
                    },
                    {
                        "key": "officeAcc4",
                        "title": "Trinket 1",
                        "type": "item",
                        "description": "0.30m x 0.25m x 0.23m (DWH)",
                        "height": 12,
                        "width": 16,
                        "rotation": 0,
                        "panelIcon": officeAccessory4Panel,
                        "icon": <ItemIcon src={officeAccessory4Panel} />,
                        "boardIcon": officeAccessory4Board,
                        "zIndex":5
                    },
                    /*
                    {
                        "key": "officeAccessory1",
                        "title": "Office Accessories 1",
                        "type": "item",
                        "description": "0.36m x 0.33m x 0.18m (DWH)",
                        "icon": <ItemIcon src={officeAccessoryPanel1} />,
                        "panelIcon": officeAccessoryPanel1,
                        "boardIcon": officeAccessoryBoard1,
                        "height": 15,
                        "width": 15
                    },
                    */
                   /*
                    {
                        "key": "officeAccessory2",
                        "title": "Office Accessories 2",
                        "type": "item",
                        "description": "0.30m x 0.25m x 0.23m (DWH)",
                        "icon": <ItemIcon src={officeAccessoryPanel2} />,
                        "panelIcon": officeAccessoryPanel2,
                        "boardIcon": officeAccessoryBoard2,
                        "height": 14,
                        "width": 14
                    },*/
                    {
                        "key": "bathroomAccessories5",
                        "title": "Make-up Set",
                        "type": "item",
                        "description": "0.33m x 0.20m x 0.20m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory5Panel} />,
                        "panelIcon": bathroomAccessory5Board,
                        "boardIcon": bathroomAccessory5Board,
                        "height": 8,
                        "width": 11,
                        "zIndex":5
                    },
                    {
                        "key": "bedroomAccessories1",
                        "title": "Carafe Set",
                        "type": "item",
                        "description": "0.18m x 0.18m x 0.25m(DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": bedroomAccessoriesPanel1,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel1} />,
                        "boardIcon": bedroomAccessoriesBoard1,
                        "zIndex":5
                    },
                    {
                        "key": "bedroomAccessories2",
                        "title": "Trinket 2",
                        "type": "item",
                        "description": "1.04m x 0.53m x 0.74m (DWH)",
                        "height": 15,
                        "width": 31,
                        "panelIcon": bedroomAccessoriesPanel2,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel2} />,
                        "boardIcon": bedroomAccessoriesBoard2,
                        "zIndex":5
                    },
                    {
                        "key": "bedroomAccessories3",
                        "title": "Picture 3",
                        "type": "item",
                        "description": "0.28m x 0.15m x 0.20m (DWH)",
                        "height": 18,
                        "width": 11,
                        "panelIcon": bedroomAccessoriesPanel3,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel3} />,
                        "boardIcon": bedroomAccessoriesBoard3,
                        "zIndex":5
                    },
                    {
                        "key": "bedroomAccessories4",
                        "title": "Book Set 1",
                        "type": "item",
                        "description": "0.23m x 0.18m x 0.20m (DWH)",
                        "height": 9,
                        "width": 11,
                        "panelIcon": bedroomAccessoriesPanel4,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel4} />,
                        "boardIcon": bedroomAccessoriesBoard4,
                        "zIndex":5
                    }

                ]
            },
            /**
             * Arts & Posters
             */
            /** 
            {
                "key": "livingRoomartsandposters",
                "title": "Arts & Posters",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                   

                ]
            },
            */
            /* Beds */
            {
                "key": "beds",
                "title": "Beds",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "nursery1",
                        "title": `Kids' Bed 3`,
                        "type": "item",
                        "description": "1.73m x 1.12m x 1.07m (DWH)",
                        "icon": <ItemIcon src={nurseryPanel1} />,
                        "panelIcon": nurseryPanel1,
                        "boardIcon": nurseryBoard1,
                        "height": 45,
                        "width": 70,
                        "zIndex":3
                    },
                    {
                        "key": "nursery4",
                        "title": `Kids' Bed 4`,
                        "type": "item",
                        "description": "1.45m x 0.81m x 1.12m (DWH)",
                        "icon": <ItemIcon src={nurseryPanel4} />,
                        "panelIcon": nurseryPanel4,
                        "boardIcon": nurseryBoard4,
                        "height": 54,
                        "width": 88,
                        "zIndex":3
                    },
                    {
                        "key": "nursery5",
                        "title":`Kids' Bed 5`,
                        "type": "item",
                        "description": "1.47m x 0.71m x 0.81m (DWH)",
                        "icon": <ItemIcon src={nurseryPanel5} />,
                        "panelIcon": nurseryPanel5,
                        "boardIcon": nurseryBoard5,
                        "height": 40,
                        "width": 80,
                        "zIndex":3
                    },
                    {
                        "key": "beds1",
                        "title": "Double Bed 1",
                        "type": "item",
                        "description": "2.84m x 2.44m x 1.68m (DWH)",
                        "height": 70,
                        "width": 80,
                        "panelIcon": bed1Panel,
                        "icon": <ItemIcon src={bed1Panel} />,
                        "boardIcon": bed1Board,
                        "zIndex":3
                    },
                    {
                        "key": "beds2",
                        "title": "Double Bed 2",
                        "type": "item",
                        "description": "2.11m x 2.26m x 0.97m (DWH)",
                        "height": 68,
                        "width": 64,
                        "panelIcon": bed2Panel,
                        "icon": <ItemIcon src={bed2Panel} />,
                        "boardIcon": bed2Board,
                        "zIndex":3
                    },
                    {
                        "key": "beds3",
                        "title": "Double Bed 3",
                        "type": "item",
                        "description": "2.31m x 2.18m x 1.45m (DWH)",
                        "height": 80,
                        "width": 85,
                        "panelIcon": bed3Panel,
                        "icon": <ItemIcon src={bed3Panel} />,
                        "boardIcon": bed3Board,
                        "zIndex":3
                    },
                    {
                        "key": "beds4",
                        "title": "Double Bed 4",
                        "type": "item",
                        "description": "1.96m x 2.26m x 1.47m (DWH)",
                        "height": 65,
                        "width": 71,
                        "panelIcon": bed4Panel,
                        "icon": <ItemIcon src={bed4Panel} />,
                        "boardIcon": bed4Board,
                        "zIndex":3
                    },
                    {
                        "key": "beds5",
                        "title": "Double Bed 5",
                        "type": "item",
                        "description": "3.18m x 2.24m x 2.08m (DWH)",
                        "height": 75,
                        "width": 100,
                        "panelIcon": bed5Panel,
                        "icon": <ItemIcon src={bed5Panel} />,
                        "boardIcon": bed5Board,
                        "zIndex":3
                    },
                    {
                        "key": "bunkbed1",
                        "title": "Bunk Bed 1",
                        "type": "item",
                        "description": "2.03m x 1.14m x 1.85m (DWH)",
                        "height": 45,
                        "width": 70,
                        "panelIcon": bunkBedPanel1,
                        "icon": <ItemIcon src={bunkBedPanel1} />,
                        "boardIcon": bunkBedBoard1,
                        "zIndex":3
                    },
                    {
                        "key": "bunkbed2",
                        "title": "Bunk Bed 2",
                        "type": "item",
                        "description": "2.06m x 1.52m x 1.83m (DWH)",
                        "height": 60,
                        "width": 85,
                        "panelIcon": bunkBedPanel2,
                        "icon": <ItemIcon src={bunkBedPanel2} />,
                        "boardIcon": bunkBedBoard2,
                        "zIndex":3
                    },
                    {
                        "key": "bunkbed3",
                        "title": "Bunk Bed 3",
                        "type": "item",
                        "description": "2.08m x 1.37m x 1.80m (DWH)",
                        "height": 54,
                        "width": 82,
                        "panelIcon": bunkBedPanel3,
                        "icon": <ItemIcon src={bunkBedPanel3} />,
                        "boardIcon": bunkBedBoard3,
                        "zIndex":3
                    },
                    {
                        "key": "bunkbed4",
                        "title": "Bunk Bed 4",
                        "type": "item",
                        "description": "2.03m x 1.30m x 1.83m (DWH)",
                        "height": 51,
                        "width": 72,
                        "panelIcon": bunkBedPanel4,
                        "icon": <ItemIcon src={bunkBedPanel4} />,
                        "boardIcon": bunkBedBoard4,
                        "zIndex":3
                    },
                    {
                        "key": "kidsBeds1",
                        "title": "Kids' Bed 1",
                        "type": "item",
                        "description": "1.27m x 0.69m x 0.81m (DWH)",
                        "height": 68,
                        "width": 40,
                        "panelIcon": kidsBed1Panel,
                        "icon": <ItemIcon src={kidsBed1Panel} />,
                        "boardIcon": kidsBed1Board,
                        "zIndex":3
                    },
                    {
                        "key": "kidsBeds2",
                        "title": "Kids' Bed 2",
                        "type": "item",
                        "description": "2.01m x 1.14m x 1.80m (DWH)",
                        "height": 45,
                        "width": 90,
                        "panelIcon": kidsBed2Panel,
                        "icon": <ItemIcon src={kidsBed2Panel} />,
                        "boardIcon": kidsBed2Board,
                        "zIndex":3
                    },
                    {
                        "key": "nursery2",
                        "title": "Bunk Bed 5",
                        "type": "item",
                        "description": "2.69m x 1.19m x 1.83m (DWH)",
                        "icon": <ItemIcon src={nurseryPanel2} />,
                        "panelIcon": nurseryPanel2,
                        "boardIcon": nurseryBoard2,
                        "height": 50,
                        "width": 105,
                        "zIndex":3
                    },
                    {
                        "key": "nursery3",
                        "title": "Double Bed 6",
                        "type": "item",
                        "description": "2.16m x 1.88m x 0.89m (DWH)",
                        "icon": <ItemIcon src={nurseryPanel3} />,
                        "panelIcon": nurseryPanel3,
                        "boardIcon": nurseryBoard3,
                        "height": 80,
                        "width": 60,
                        "zIndex":3
                    },
                    {
                        "key": "singleBed1",
                        "title": "Single Bed 1",
                        "type": "item",
                        "description": "1.80m x 2.46m x 1.45m (DWH)",
                        "height": 87,
                        "width": 65,
                        "panelIcon": singleBed1Panel,
                        "icon": <ItemIcon src={singleBed1Panel} />,
                        "boardIcon": singleBed1Board,
                        "zIndex":3
                    },
                    {
                        "key": "singleBed2",
                        "title": "Single Bed 2",
                        "type": "item",
                        "description": "1.80m x 2.46m x 1.45m (DWH)",
                        "height": 87,
                        "width": 65,
                        "panelIcon": singleBed2Panel,
                        "icon": <ItemIcon src={singleBed2Panel} />,
                        "boardIcon": singleBed2Board,
                        "zIndex":3
                    },
                    {
                        "key": "singleBed3",
                        "title": "Single Bed 3",
                        "type": "item",
                        "description": "1.80m x 2.46m x 1.45m (DWH)",
                        "height": 87,
                        "width": 65,
                        "panelIcon": singleBed3Panel,
                        "icon": <ItemIcon src={singleBed3Panel} />,
                        "boardIcon": singleBed3Board,
                        "zIndex":3
                    },
                    {
                        "key": "singleBed4",
                        "title": "Single Bed 4",
                        "type": "item",
                        "description": "1.80m x 2.46m x 1.45m (DWH)",
                        "height": 87,
                        "width": 65,
                        "panelIcon": singleBed4Panel,
                        "icon": <ItemIcon src={singleBed4Panel} />,
                        "boardIcon": singleBed4Board,
                        "zIndex":3
                    },
                    {
                        "key": "kidsBeds3",
                        "title": "Single Bed 5",
                        "type": "item",
                        "description": "1.80m x 2.46m x 1.45m (DWH)",
                        "height": 87,
                        "width": 65,
                        "panelIcon": kidsBed3Panel,
                        "icon": <ItemIcon src={kidsBed3Panel} />,
                        "boardIcon": kidsBed3Board,
                        "zIndex":3
                    },
                ]
            },
            /* Cabinets */
            /*
            {
                "key": "cabinets",
                "title": "Cabinets",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Kitchen 1",
                        "icon": <ItemIcon src={kitchen1Panel} />,
                        "panelIcon": kitchen1Panel,
                        "boardIcon": kitchen1Board,
                        "type": "item",
                        "description": "",
                        "height": 45,
                        "width": 45
                    },
                ]
            },
            */
            /* Carpets */
            {
                "key": "carpets",
                "title": "Carpets",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "carpet1",
                        "title": "Carpet 1",
                        "icon": <ItemIcon src={livingRoomCarpet1Panel} />,
                        "boardIcon": livingRoomCarpet1Board,
                        "type": "item",
                        "description": "2.1336m x 1.4488m x 0m DWH",
                        "height": 84,
                        "width": 49,
                        "panelIcon": livingRoomCarpet1Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet2",
                        "title": "Carpet 2",
                        "icon": <ItemIcon src={livingRoomCarpet2Panel} />,
                        "boardIcon": livingRoomCarpet2Board,
                        "type": "item",
                        "description": "2.41m x 3.00m x 0.03m (DWH)",
                        "height": 95,
                        "width": 143,
                        "panelIcon": livingRoomCarpet2Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet3",
                        "title": "Carpet 3",
                        "icon": <ItemIcon src={livingRoomCarpet3Panel} />,
                        "boardIcon": livingRoomCarpet3Board,
                        "type": "item",
                        "description": "1.68m x 1.40m x 0.03m (DWH)",
                        "height": 66,
                        "width": 55,
                        "panelIcon": livingRoomCarpet3Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet4",
                        "title": "Carpet 4",
                        "icon": <ItemIcon src={livingRoomCarpet4Panel} />,
                        "boardIcon": livingRoomCarpet4Board,
                        "type": "item",
                        "description": "1.63m x 1.63m x 0m (DWH)",
                        "height": 64,
                        "width": 64,
                        "panelIcon": livingRoomCarpet4Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet5",
                        "title": "Carpet 5",
                        "icon": <ItemIcon src={livingRoomCarpet5Panel} />,
                        "boardIcon": livingRoomCarpet5Board,
                        "type": "item",
                        "description": "2.08m x 1.40m x 0.03m (DWH)",
                        "height": 82,
                        "width": 55,
                        "panelIcon": livingRoomCarpet5Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet6",
                        "title": "Carpet 6",
                        "icon": <ItemIcon src={livingRoomCarpet6Panel} />,
                        "boardIcon": livingRoomCarpet6Board,
                        "type": "item",
                        "description": "1.93m x 1.30m x 0.03m (DWH)",
                        "height": 76,
                        "width": 51,
                        "panelIcon": livingRoomCarpet6Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet7",
                        "title": "Carpet 7",
                        "icon": <ItemIcon src={carpet1Panel} />,
                        "boardIcon": carpet1Board,
                        "type": "item",
                        "description": "2.9 m x 2.0 m x 0.01m (DWH)",
                        "height": 50,
                        "width": 85,
                        "panelIcon": carpet1Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet8",
                        "title": "Carpet 8",
                        "icon": <ItemIcon src={carpet2Panel} />,
                        "boardIcon": carpet2Board,
                        "type": "item",
                        "description": "3.17m x 2.38m x 0.02m (DWH)",
                        "height": 75,
                        "width": 110,
                        "panelIcon": carpet2Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet9",
                        "title": "Carpet 9",
                        "icon": <ItemIcon src={carpet3Panel} />,
                        "boardIcon": carpet3Board,
                        "type": "item",
                        "description": "3.17m x 2.38m x 0.02m (DWH)",
                        "height": 60,
                        "width": 100,
                        "panelIcon": carpet3Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet10",
                        "title": "Carpet 10",
                        "icon": <ItemIcon src={carpet4Panel} />,
                        "boardIcon": carpet4Board,
                        "type": "item",
                        "description": "4.45m x 2.78m x 0.01m (DWH)",
                        "height": 70,
                        "width": 120,
                        "panelIcon": carpet4Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet11",
                        "title": "Carpet 11",
                        "icon": <ItemIcon src={carpet5Panel} />,
                        "boardIcon": carpet5Board,
                        "type": "item",
                        "description": "2.9 m x 2.0 m x 0.011 m (DWH)",
                        "height": 72,
                        "width": 107,
                        "panelIcon": carpet5Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet12",
                        "title": "Carpet 12",
                        "icon": <ItemIcon src={carpet6Panel} />,
                        "boardIcon": carpet6Board,
                        "type": "item",
                        "description": "3.26m x 2.02m x 0.01m (DWH)",
                        "height": 75,
                        "width": 124,
                        "panelIcon": carpet6Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet13",
                        "title": "Carpet 13",
                        "icon": <ItemIcon src={carpet7Panel} />,
                        "boardIcon": carpet7Board,
                        "type": "item",
                        "description": "3.02m x 1.72m x 0.02m (DWH)",
                        "height": 76,
                        "width": 90,
                        "panelIcon": carpet7Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet14",
                        "title": "Carpet 14",
                        "icon": <ItemIcon src={carpet8Panel} />,
                        "boardIcon": carpet8Board,
                        "type": "item",
                        "description": "1.93m x 1.30m x 0.03m (DWH)",
                        "height": 65,
                        "width": 100,
                        "panelIcon": carpet8Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet15",
                        "title": "Carpet 15",
                        "icon": <ItemIcon src={carpet9Panel} />,
                        "boardIcon": carpet9Board,
                        "type": "item",
                        "description": "2.99m x 2.67m x 0.01m (DWH)",
                        "height": 75,
                        "width": 115,
                        "panelIcon": carpet9Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet16",
                        "title": "Carpet 16",
                        "icon": <ItemIcon src={carpet10Panel} />,
                        "boardIcon": carpet10Board,
                        "type": "item",
                        "description": "3.04m x 2.03m x 0.05m (DWH)",
                        "height": 75,
                        "width": 115,
                        "panelIcon": carpet10Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet17",
                        "title": "Carpet 17",
                        "icon": <ItemIcon src={carpet11Panel} />,
                        "boardIcon": carpet11Board,
                        "type": "item",
                        "description": "3.9m x 2.4m x 0.01m (DWH)",
                        "height": 75,
                        "width": 115,
                        "panelIcon": carpet11Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet18",
                        "title": "Carpet 18",
                        "icon": <ItemIcon src={carpet12Panel} />,
                        "boardIcon": carpet12Board,
                        "type": "item",
                        "description": "2.08m x 1.4m x 0.01m (DWH)",
                        "height": 75,
                        "width": 70,
                        "panelIcon": carpet12Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet20",
                        "title": "Carpet 20",
                        "icon": <ItemIcon src={carpet14Panel} />,
                        "boardIcon": carpet14Board,
                        "type": "item",
                        "description": "3.02m x 1.73m x 0.02m (DWH)",
                        "height": 65,
                        "width": 100,
                        "panelIcon": carpet14Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet21",
                        "title": "Carpet 21",
                        "icon": <ItemIcon src={carpet15Panel} />,
                        "boardIcon": carpet15Board,
                        "type": "item",
                        "description": "2.9m x 2m x 0.01m (DWH)",
                        "height": 71,
                        "width": 115,
                        "panelIcon": carpet15Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet22",
                        "title": "Carpet 22",
                        "icon": <ItemIcon src={carpet16Panel} />,
                        "boardIcon": carpet16Board,
                        "type": "item",
                        "description": "3.1m x 1.94m x 0.01m (DWH)",
                        "height": 104,
                        "width": 33,
                        "panelIcon": carpet16Panel,
                        "zIndex":1
                    },
                    {
                        "key": "carpet23",
                        "title": "Carpet 23",
                        "icon": <ItemIcon src={carpet17Panel} />,
                        "boardIcon": carpet17Board,
                        "type": "item",
                        "description": "0.7m x 2.1m x 0.03m (DWH)",
                        "height": 76,
                        "width": 51,
                        "panelIcon": carpet17Panel,
                        "zIndex":1
                    },
                    /*
                    {
                        "title": "Carpet 24",
                        "icon": <ItemIcon src={carpet18Panel} />,
                        "boardIcon": carpet18Board,
                        "type": "item",
                        "description": "1.78m x 2.5m x 0.02m (DWH)",
                        "height": 76,
                        "width": 51,
                        "panelIcon": carpet18Panel,
                        "zIndex":1
                    },
                    */
                    
                    {
                        "key": "carpet24",
                        "title": "Carpet 24",
                        "icon": <ItemIcon src={carpet18Panel} />,
                        "boardIcon": carpet18Board,
                        "type": "item",
                        "description": "2.05m x 1.39m x 0.01m (DWH)",
                        "height": 75,
                        "width": 105,
                        "panelIcon": carpet18Panel,
                        "zIndex":1
                    },
                    
                ]
            },
            /* Chairs */
            {
                "key": "chairs",
                "title": "Chairs",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "kidsFurniture11",
                        "title": `Kids' Chair`,
                        "type": "item",
                        "description": "0.46m x 0.48m x 0.79m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel11} />,
                        "panelIcon": kidsFurniturePanel11,
                        "boardIcon": kidsFurnitureBoard11,
                        "height": 40,
                        "width": 30,
                        "zIndex":2
                    },
                    {
                        "key": "kidsFurniture1",
                        "title": `Feeding Chair`,
                        "type": "item",
                        "description": "0.46m x 0.46m x 0.81m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel1} />,
                        "panelIcon": kidsFurniturePanel1,
                        "boardIcon": kidsFurnitureBoard1,
                        "height": 30,
                        "width": 30,
                        "zIndex":2
                    },
                    {
                        "key": "chair1",
                        "title": "Chair 1",
                        "type": "item",
                        "description": "0.46m x 0.53m x 0.81m (DWH)",
                        "height": 20,
                        "width": 18,
                        "panelIcon": diningChair1Panel,
                        "icon": <ItemIcon src={diningChair1Panel} />,
                        "boardIcon": diningChair1Board,
                        "zIndex":2
                    },
                    {
                        "key": "chair2",
                        "title": "Chair 2",
                        "type": "item",
                        "description": "0.43m x 0.51m x 0.86m (DWH)",
                        "height": 20,
                        "width": 17,
                        "panelIcon": diningChair2Panel,
                        "icon": <ItemIcon src={diningChair2Panel} />,
                        "boardIcon": diningChair2Board,
                        "zIndex":2
                    },
                    
                    {
                        "key": "officeChair4",
                        "title": "Chair 4",
                        "type": "item",
                        "description": "0.58m x 0.58m x 0.81m (DWH)",
                        "icon": <ItemIcon src={officeChair4Panel} />,
                        "boardIcon": officeChair4Board,
                        "height": 40,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "chair5",
                        "title": "Chair 3",
                        "type": "item",
                        "description": "0.43m x 0.48m x 0.79m (DWH)",
                        "height": 20,
                        "width": 17,
                        "panelIcon": diningChair5Panel,
                        "icon": <ItemIcon src={diningChair5Panel} />,
                        "boardIcon": diningChair5Board,
                        "zIndex":2
                    },
                    {
                        "key": "officeChair1",
                        "title": "Office Chair 1",
                        "type": "item",
                        "description": "0.71m x 0.71m x 1.07m (DWH)",
                        "icon": <ItemIcon src={officeChair1Panel} />,
                        "boardIcon": officeChair1Board,
                        "height": 40,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "officeChair2",
                        "title": "Office Chair 2",
                        "type": "item",
                        "description": "0.61m x 0.71m x 1.02m (DWH)",
                        "icon": <ItemIcon src={officeChair2Panel} />,
                        "boardIcon": officeChair2Board,
                        "height": 40,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "officeChair3",
                        "title": "Office Chair 3",
                        "type": "item",
                        "description": "0.71m x 0.71m x 1.24m (DWH)",
                        "icon": <ItemIcon src={officeChair3Panel} />,
                        "boardIcon": officeChair3Board,
                        "height": 40,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "officeChair5",
                        "title": "Office Chair 4",
                        "type": "item",
                        "description": "2.57m x 2.54m x 0.81m (DWH)",
                        "icon": <ItemIcon src={officeChair5Panel} />,
                        "boardIcon": officeChair5Board,
                        "height": 60,
                        "width": 60,
                        "zIndex":2
                    },
                    {
                        "key": "officeChair6",
                        "title": "Office Chair 5",
                        "type": "item",
                        "description": "0.74m x 0.74m x 0.79m (DWH)",
                        "icon": <ItemIcon src={officeChair6Panel} />,
                        "boardIcon": officeChair6Board,
                        "height": 40,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "bench1",
                        "title": "Stool",
                        "icon": <ItemIcon src={livingRoomOttomanBench2Panel} />,
                        "boardIcon": livingRoomOttomanBench2Board,
                        "type": "item",
                        "description": "0.43m x 0.43m x 0.53m (DWH)",
                        "height": 21,
                        "width": 21,
                        "panelIcon": livingRoomOttomanBench2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat1",
                        "title": "Bar Chair 1",
                        "type": "item",
                        "description": "0.56m x 0.56m x 1.07m (DWH)",
                        "height": 24,
                        "width": 24,
                        "panelIcon": barSeat1Panel,
                        "icon": <ItemIcon src={barSeat1Panel} />,
                        "boardIcon": barSeat1Board,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat2",
                        "title": "Bar Chair 2",
                        "type": "item",
                        "description": "0.53m x 0.48m x 0.97m (DWH)",
                        "height": 21,
                        "width": 19,
                        "panelIcon": barSeat2Panel,
                        "icon": <ItemIcon src={barSeat2Panel} />,
                        "boardIcon": barSeat2Board,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat5",
                        "title": "Bar Chair 3",
                        "type": "item",
                        "description": "0.43m x 0.48m x 0.89m (DWH)",
                        "height": 19,
                        "width": 21,
                        "panelIcon": barSeat5Panel,
                        "icon": <ItemIcon src={barSeat5Panel} />,
                        "boardIcon": barSeat5Board,
                        "zIndex":2
                    },
                ]
            },
            /* Counter Tops*/
            /*
            {
                "key": "counterTops",
                "title": "Counter Tops",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Countertops 1",
                        "icon": <ItemIcon src={kitchenCountertops1Panel} />,
                        "panelIcon": kitchenCountertops1Panel,
                        "boardIcon": kitchenCountertops1Board,
                        "type": "item",
                        "description": "0.61m x 0.66m x 0.06m (DWH)",
                        "height": 24,
                        "width": 26
                    },
                    {
                        "title": "Countertops Stainless Steel",
                        "icon": <ItemIcon src={kitchenCountertops2Panel} />,
                        "panelIcon": kitchenCountertops2Panel,
                        "boardIcon": kitchenCountertops2Board,
                        "type": "item",
                        "description": "0.61m x 0.66m x 0.06m (DWH)",
                        "height": 24,
                        "width": 26
                    }
                ]
            },
            */
            /* Curtains */
            {
                "key": "diningRoomCurtains",
                "title": "Curtains",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "diningRoomCurtain1",
                        "title": "Curtain 1",
                        "type": "item",
                        "description": "1.12m x 0.18m x 2.84m (DWH)",
                        "panelIcon": diningCurtain1Panel,
                        "icon": <ItemIcon src={diningCurtain1Panel} />,
                        "boardIcon": diningCurtain1Board,
                        "height": 8,
                        "width": 125,
                        "zIndex":4
                    },
                    {
                        "key": "diningRoomCurtain2",
                        "title": "Curtain 2",
                        "type": "item",
                        "description": "0.56m x 0.10m x 1.85m (DWH)",
                        "panelIcon": diningCurtain2Panel,
                        "icon": <ItemIcon src={diningCurtain2Panel} />,
                        "boardIcon": diningCurtain2Board,
                        "height": 5,
                        "width": 40,
                        "zIndex":4
                    },
                    {
                        "key": "diningRoomCurtain3",
                        "title": "CurtainThree",
                        "type": "item",
                        "description": "1.52m x 0.08m x 2.01m (DWH)",
                        "panelIcon": diningCurtain3Panel,
                        "icon": <ItemIcon src={diningCurtain3Panel} />,
                        "boardIcon": diningCurtain3Board,
                        "height": 20,
                        "width": 25,
                        "zIndex":4
                    },
                    {
                        "key": "diningRoomCurtain4",
                        "title": "CurtainFour",
                        "type": "item",
                        "description": "0.94m x 0.05m x 0.69m (DWH)",
                        "panelIcon": diningCurtain4Panel,
                        "icon": <ItemIcon src={diningCurtain4Panel} />,
                        "boardIcon": diningCurtain4Board,
                        "height": 4,
                        "width": 67,
                        "zIndex":4
                    },
                    {
                        "key": "diningRoomCurtain5",
                        "title": "CurtainFive",
                        "type": "item",
                        "description": "0.94m x 0.05m x 0.69m (DWH)",
                        "panelIcon": diningCurtain5Panel,
                        "icon": <ItemIcon src={diningCurtain5Panel} />,
                        "boardIcon": diningCurtain5Board,
                        "height": 6,
                        "width": 60,
                        "zIndex":4
                    }

                ]
            },
            /* Desks */
            /*
            {
                "key": "officeDesks",
                "title": "Desks",
                "category": "Office",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Dressers */
            /*
            {
                "key": "dressers",
                "title": "Dressers",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Fireplaces */
            {
                "key": "fireplaces",
                "title": "Fireplaces",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Fireplace 1",
                        "icon": <ItemIcon src={livingRoomFireplace1Panel} />,
                        "boardIcon": livingRoomFireplace1Board,
                        "type": "item",
                        "description": "1.50m x 0.53m x 1.24m (DWH)",
                        "height": 21,
                        "width": 70,
                        "panelIcon": livingRoomFireplace1Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Fireplace 2",
                        "icon": <ItemIcon src={livingRoomFireplace2Panel} />,
                        "boardIcon": livingRoomFireplace2Board,
                        "type": "item",
                        "description": "1.45m x 0.53m x 1.14m (DWH)",
                        "height": 21,
                        "width": 80,
                        "panelIcon": livingRoomFireplace2Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Fireplace 3",
                        "icon": <ItemIcon src={livingRoomFireplace3Panel} />,
                        "boardIcon": livingRoomFireplace3Board,
                        "type": "item",
                        "description": "4.17m x 1.45m x 2.95m (DWH)",
                        "height": 28,
                        "width": 100,
                        "panelIcon": livingRoomFireplace3Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Fireplace 4",
                        "icon": <ItemIcon src={livingRoomFireplace4Panel} />,
                        "boardIcon": livingRoomFireplace4Board,
                        "type": "item",
                        "description": "1.80m x 0.97m x 2.46m (DWH)",
                        "height": 30,
                        "width": 120,
                        "panelIcon": livingRoomFireplace4Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Fireplace 5",
                        "icon": <ItemIcon src={livingRoomFireplace5Panel} />,
                        "boardIcon": livingRoomFireplace5Board,
                        "type": "item",
                        "description": "Unknown dimensions",
                        "height": 20,
                        "width": 70,
                        "panelIcon": livingRoomFireplace5Panel,
                        "zIndex":2
                    }
                ]
            },
            /* Food & Utensils */
            /*
            {
                "key": "foodUtensils",
                "title": "Food & Utensils",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                ]
            },
            */
            /* Hutches & Buffets */
            /*
            {
                "key": "hutchesandbuffets",
                "title": "Hutches & Buffets",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Kids Furniture */
            /*
            {
                "key": "kidsFurniture",
                "title": `Kids' Furniture`,
                "category": "Children's Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
 
                ]
            },
            */
            /* Kitchen Sinks */
            /*
            {
                "key": "kitchenSink",
                "title": "Sinks",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                    
                ]
            },
            */
            /* Media Units */
            /*
            {
                "key": "mediaunits",
                "title": "Media Units",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Mirrors */
            /*
            {
                "key": "diningRoomMirrors",
                "title": "Mirrors",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Nursery */
            /*
            {
                "key": "nursery",
                "title": "Nursery",
                "category": "Children's Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    

                ]
            },
            */
            /* Ottomans & Benches */
            /*
            {
                "key": "ottomansbenches",
                "title": "Ottomans",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                    
                    
                ]
            },
            */
            /* Range & Cooktops */
            /*
            {
                "key": "rangesCookTops",
                "title": "Ranges & Cooktops",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    

                ]
            },
            */
            /* Screen & Dividers */
            {
                "key": "screensandDividers",
                "title": "Folding Screens",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "diningRoomAcc4",
                        "title": "Folding Screen 6",
                        "type": "item",
                        "description": "0.71m x 0.05m x 2.18m (DWH)",
                        "height": 2,
                        "width": 43,
                        "panelIcon": diningRoomAccessoriesPanel4,
                        "icon": <ItemIcon src={diningRoomAccessoriesPanel4} />,
                        "boardIcon": diningRoomAccessoriesBoard4,
                        "zIndex":3
                    },
                    {
                        "title": "Folding Screen 1",
                        "icon": <ItemIcon src={livingRoomScreenDivider1Panel} />,
                        "boardIcon": livingRoomScreenDivider1Board,
                        "type": "item",
                        "description": "1.91m x 0.41m x 2.24m (DWH)",
                        "height": 16,
                        "width": 70,
                        "panelIcon": livingRoomScreenDivider1Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Folding Screen 2",
                        "icon": <ItemIcon src={livingRoomScreenDivider2Panel} />,
                        "boardIcon": livingRoomScreenDivider2Board,
                        "type": "item",
                        "description": "1.93m x 0.41m x 1.85m (DWH)",
                        "height": 16,
                        "width": 66,
                        "panelIcon": livingRoomScreenDivider2Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Folding Screen 3",
                        "icon": <ItemIcon src={livingRoomScreenDivider3Panel} />,
                        "boardIcon": livingRoomScreenDivider3Board,
                        "type": "item",
                        "description": "2.67m x 0.41m x 1.91m (DWH)",
                        "height": 16,
                        "width": 89,
                        "panelIcon": livingRoomScreenDivider3Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Folding Screen 4",
                        "icon": <ItemIcon src={livingRoomScreenDivider4Panel} />,
                        "boardIcon": livingRoomScreenDivider4Board,
                        "type": "item",
                        "description": "2.49m x 0.81m x 2.92m (DWH)",
                        "height": 32,
                        "width": 109,
                        "panelIcon": livingRoomScreenDivider4Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Folding Screen 5",
                        "icon": <ItemIcon src={livingRoomScreenDivider5Panel} />,
                        "boardIcon": livingRoomScreenDivider5Board,
                        "type": "item",
                        "description": "5.11m x 0.86m x 2.92m (DWH)",
                        "height": 33,
                        "width": 171,
                        "panelIcon": livingRoomScreenDivider5Panel,
                        "zIndex":3
                    }

                ]
            },
            /* Shower Doors */
            /*
            {
                "key": "showerDoors",
                "title": "Shower Doors",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Sofas */
            {
                "key": "sofas",
                "title": "Sofas",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "Ottoman2",
                        "title": "Daybed",
                        "icon": <ItemIcon src={livingRoomOttomanBench3Panel} />,
                        "boardIcon": livingRoomOttomanBench3Board,
                        "type": "item",
                        "description": "1.75m x 0.76m x 0.56m (DWH)",
                        "height": 29,
                        "width": 70,
                        "panelIcon": livingRoomOttomanBench3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Ottoman1",
                        "title": "Pouf 1",
                        "icon": <ItemIcon src={livingRoomOttomanBench1Panel} />,
                        "boardIcon": livingRoomOttomanBench1Board,
                        "type": "item",
                        "description": "0.76m x 0.97m x 0.30m (DWH)",
                        "height": 39,
                        "width": 39,
                        "panelIcon": livingRoomOttomanBench1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Ottoman3",
                        "title": "Pouf 2",
                        "icon": <ItemIcon src={livingRoomOttomanBench4Panel} />,
                        "boardIcon": livingRoomOttomanBench4Board,
                        "type": "item",
                        "description": "0.76m x 0.74m x 0.38m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomOttomanBench4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Ottoman4",
                        "title": "Pouf 3",
                        "icon": <ItemIcon src={livingRoomOttomanBench5Panel} />,
                        "boardIcon": livingRoomOttomanBench5Board,
                        "type": "item",
                        "description": "0.76m x 0.74m x 0.38m (DWH)",
                        "height": 30,
                        "width": 50,
                        "panelIcon": livingRoomOttomanBench5Panel,
                        "zIndex":2
                    },
                    {
                        "key": "kidsFurniture5",
                        "title": `Kids' Sofa`,
                        "type": "item",
                        "description": "0.51m x 0.46m x 0.41m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel5} />,
                        "panelIcon": kidsFurniturePanel5,
                        "boardIcon": kidsFurnitureBoard5,
                        "height": 40,
                        "width": 50,
                        "zIndex":2
                    },
                    {
                        "key": "Corner Set",
                        "title": "Corner Set 1",
                        "icon": <ItemIcon src={livingRoomSofa1Panel} />,
                        "boardIcon": livingRoomSofa1Board,
                        "type": "item",
                        "description": "3.61m x 3.43m x 0.81m (DWH)",
                        "height": 100,
                        "width": 100,
                        "panelIcon": livingRoomSofa1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Triple Sofa 1",
                        "title": "Triple Sofa 1",
                        "icon": <ItemIcon src={livingRoomSofa2Panel} />,
                        "boardIcon": livingRoomSofa2Board,
                        "type": "item",
                        "description": "3.30m x 1.17m x 0.94m (DWH)",
                        "height": 49,
                        "width": 129,
                        "panelIcon": livingRoomSofa2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Triple Sofa 2",
                        "title": "Triple Sofa 2",
                        "icon": <ItemIcon src={livingRoomSofa3Panel} />,
                        "boardIcon": livingRoomSofa3Board,
                        "type": "item",
                        "description": "3.30m x 0.94m x 0.74m (DWH)",
                        "height": 41,
                        "width": 130,
                        "panelIcon": livingRoomSofa3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Double Sofa 5",
                        "title": "Double Sofa 5",
                        "icon": <ItemIcon src={livingRoomSofa4Panel} />,
                        "boardIcon": livingRoomSofa4Board,
                        "type": "item",
                        "description": "2.24m x 1.02m x 0.76m (DWH)",
                        "height": 120,
                        "width": 120,
                        "panelIcon": livingRoomSofa4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Double Sofa 6",
                        "title": "Double Sofa 6",
                        "icon": <ItemIcon src={livingRoomSofa5Panel} />,
                        "boardIcon": livingRoomSofa5Board,
                        "type": "item",
                        "description": "2.79m x 1.04m x 0.91m (DWH)",
                        "height": 45,
                        "width": 92,
                        "panelIcon": livingRoomSofa5Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Leather Sofa",
                        "icon": <ItemIcon src={livingRoomLeatherSofa1Panel} />,
                        "boardIcon": livingRoomLeatherSofa1Board,
                        "type": "item",
                        "description": "2.21m x 0.91m x 0.89m (DWH)",
                        "height": 50,
                        "width": 110,
                        "panelIcon": livingRoomLeatherSofa1Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Clover Sofa",
                        "icon": <ItemIcon src={livingRoomCloverSofa1Panel} />,
                        "boardIcon": livingRoomCloverSofa1Board,
                        "type": "item",
                        "description": "2.18m x 1.07m x 0.71m (DWH)",
                        "height": 45,
                        "width": 100,
                        "panelIcon": livingRoomCloverSofa1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Sofa Set 1",
                        "title": "Sofa Set 1",
                        "icon": <ItemIcon src={livingRoomSofaSet1Panel} />,
                        "boardIcon": livingRoomSofaSet1Board,
                        "panelIcon": livingRoomSofaSet1Panel,
                        "type": "item",
                        "description": "",
                        "height": 150,
                        "width": 230,
                        "zIndex":2
                    },
                    {
                        "key": "Sofa Set 2",
                        "title": "Sofa Set 2",
                        "icon": <ItemIcon src={livingRoomSofaSet2Panel} />,
                        "boardIcon": livingRoomSofaSet2Board,
                        "panelIcon": livingRoomSofaSet2Panel,
                        "type": "item",
                        "description": "",
                        "height": 137,
                        "width": 204,
                        "zIndex":2
                    },
                    {
                        "key": "Sofa Set 3",
                        "title": "Sofa Set 3",
                        "icon": <ItemIcon src={livingRoomSofaSet3Panel} />,
                        "boardIcon": livingRoomSofaSet3Board,
                        "panelIcon": livingRoomSofaSet3Panel,
                        "type": "item",
                        "description": "",
                        "height": 136,
                        "width": 179,
                        "zIndex":2
                    },
                    {
                        "key": "Sofa Set 4",
                        "title": "Sofa Set 4",
                        "icon": <ItemIcon src={livingRoomSofaSet4Panel} />,
                        "boardIcon": livingRoomSofaSet4Board,
                        "panelIcon": livingRoomSofaSet4Panel,
                        "type": "item",
                        "description": "",
                        "height": 200,
                        "width": 194,
                        "zIndex":2
                    },
                    {
                        "key": "singleSofa1",
                        "title": "Single Sofa 1",
                        "icon": <ItemIcon src={livingRoomSingleSofa1Panel} />,
                        "boardIcon": livingRoomSingleSofa1Board,
                        "type": "item",
                        "description": "0.81m x 0.71m x 1.07m (DWH)",
                        "height": 44,
                        "width": 60,
                        "panelIcon": livingRoomSingleSofa1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "singleSofa2",
                        "title": "Single Sofa 2",
                        "icon": <ItemIcon src={livingRoomSingleSofa2Panel} />,
                        "boardIcon": livingRoomSingleSofa2Board,
                        "type": "item",
                        "description": "0.74m x 0.81m x 0.84m (DWH)",
                        "height": 35,
                        "width": 35,
                        "panelIcon": livingRoomSingleSofa2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "singleSofa3",
                        "title": "Single Sofa 3",
                        "icon": <ItemIcon src={livingRoomSingleSofa3Panel} />,
                        "boardIcon": livingRoomSingleSofa3Board,
                        "type": "item",
                        "description": "0.74m x 0.84m x 0.99m (DWH)",
                        "height": 33,
                        "width": 32,
                        "panelIcon": livingRoomSingleSofa3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "singleSofa4",
                        "title": "Single Sofa 4",
                        "icon": <ItemIcon src={livingRoomSingleSofa4Panel} />,
                        "boardIcon": livingRoomSingleSofa4Board,
                        "type": "item",
                        "description": "1.35m x 0.89m x 0.97m (DWH)",
                        "height": 41,
                        "width": 53,
                        "panelIcon": livingRoomSingleSofa4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "singleSofa5",
                        "title": "Single Sofa 5",
                        "icon": <ItemIcon src={livingRoomSingleSofa5Panel} />,
                        "boardIcon": livingRoomSingleSofa5Board,
                        "type": "item",
                        "description": "0.97m x 0.79m x 0.89m (DWH)",
                        "height": 35,
                        "width": 36,
                        "panelIcon": livingRoomSingleSofa5Panel,
                        "zIndex":2
                    },
                    {
                        "key": "sofa6",
                        "title": "Triple Sofa 3",
                        "icon": <ItemIcon src={sofa6Panel} />,
                        "boardIcon": sofa6Board,
                        "type": "item",
                        "description": "1.81m x 0.7m x 0.88m (DWH)",
                        "height": 36,
                        "width": 66,
                        "panelIcon": sofa6Panel,
                        "zIndex":2
                    },
                    {
                        "key": "sofa7",
                        "title": "Double Sofa 7",
                        "icon": <ItemIcon src={sofa7Panel} />,
                        "boardIcon": sofa7Board,
                        "type": "item",
                        "description": "1.67m x 0.82m x 0.75m (DWH)",
                        "height": 34,
                        "width": 69,
                        "panelIcon": sofa7Panel,
                        "zIndex":2
                    },
                    {
                        "key": "sofa8",
                        "title": "Corner Set 2",
                        "icon": <ItemIcon src={sofa8Panel} />,
                        "boardIcon": sofa8Board,
                        "type": "item",
                        "description": "3.06m x 1.6m x 0.73m (DWH)",
                        "height": 40,
                        "width": 100,
                        "panelIcon": sofa8Panel,
                        "zIndex":2
                    },
                    {
                        "key": "sofa9",
                        "title": "Corner Set 3",
                        "icon": <ItemIcon src={sofa9Panel} />,
                        "boardIcon": sofa9Board,
                        "type": "item",
                        "description": "3.02m x 2.25m x 0.88m (DWH)",
                        "height": 70,
                        "width": 70,
                        "panelIcon": sofa9Panel,
                        "zIndex":2
                    },
                    {
                        "key": "sofa10",
                        "title": "Corner Set 4",
                        "icon": <ItemIcon src={sofa10Panel} />,
                        "boardIcon": sofa10Board,
                        "type": "item",
                        "description": "3.67m x 3.17 x 0.93m (DWH)",
                        "height": 88,
                        "width": 88,
                        "panelIcon": sofa10Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Chaise Longue 1",
                        "icon": <ItemIcon src={livingRoomChaisesLongues1Panel} />,
                        "boardIcon": livingRoomChaisesLongues1Board,
                        "type": "item",
                        "description": "2.29m x 1.09m x 1.02m (DWH)",
                        "height": 45,
                        "width": 90,
                        "panelIcon": livingRoomChaisesLongues1Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Chaise Longue 2",
                        "icon": <ItemIcon src={livingRoomChaisesLongues2Panel} />,
                        "boardIcon": livingRoomChaisesLongues2Board,
                        "type": "item",
                        "description": "1.75m x 1.04m x 0.86m (DWH)",
                        "height": 40,
                        "width": 69,
                        "panelIcon": livingRoomChaisesLongues2Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Chaise Longue 3",
                        "icon": <ItemIcon src={livingRoomChaisesLongues3Panel} />,
                        "boardIcon": livingRoomChaisesLongues3Board,
                        "type": "item",
                        "description": "1.65m x 0.74m x 0.64m (DWH)",
                        "height": 37,
                        "width": 65,
                        "panelIcon": livingRoomChaisesLongues3Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Chaise Longue 4",
                        "icon": <ItemIcon src={livingRoomChaisesLongues4Panel} />,
                        "boardIcon": livingRoomChaisesLongues4Board,
                        "type": "item",
                        "description": "1.75m x 0.86m x 0.79m (DWH)",
                        "height": 37,
                        "width": 76,
                        "panelIcon": livingRoomChaisesLongues4Panel,
                        "zIndex":2
                    },
                    {
                        "title": "Chaise Longue 5",
                        "icon": <ItemIcon src={livingRoomChaisesLongues5Panel} />,
                        "boardIcon": livingRoomChaisesLongues5Board,
                        "type": "item",
                        "description": "2.18m x 1.09m x 0.89m (DWH)",
                        "height": 43,
                        "width": 86,
                        "panelIcon": livingRoomChaisesLongues5Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Lounge Chair 2",
                        "title": "Lounge Chair 1",
                        "icon": <ItemIcon src={livingRoomLoungeChair2Panel} />,
                        "boardIcon": livingRoomLoungeChair2Board,
                        "type": "item",
                        "description": "0.89m x 0.81m x 0.79m (DWH)",
                        "height": 30,
                        "width": 27,
                        "panelIcon": livingRoomLoungeChair2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Lounge Chair 3",
                        "title": "Lounge Chair 2",
                        "icon": <ItemIcon src={livingRoomLoungeChair3Panel} />,
                        "boardIcon": livingRoomLoungeChair3Board,
                        "type": "item",
                        "description": "0.69m x 0.81m x 0.91m (DWH)",
                        "height": 30,
                        "width": 31,
                        "panelIcon": livingRoomLoungeChair3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "chair4",
                        "title": "Lounge Chair 3",
                        "type": "item",
                        "description": "0.71m x 0.76m x 0.76m (DWH)",
                        "height": 30,
                        "width": 28,
                        "panelIcon": diningChair4Panel,
                        "icon": <ItemIcon src={diningChair4Panel} />,
                        "boardIcon": diningChair4Board,
                        "zIndex":2
                    },
                    {
                        "key": "chair3",
                        "title": "Lounge Chair 4",
                        "type": "item",
                        "description": "0.56m x 0.61m x 0.86m (DWH)",
                        "height": 24,
                        "width": 22,
                        "panelIcon": diningChair3Panel,
                        "icon": <ItemIcon src={diningChair3Panel} />,
                        "boardIcon": diningChair3Board,
                        "zIndex":2
                    },
                    {
                        "key": "Loveseat 1",
                        "title": "Double Sofa 1",
                        "icon": <ItemIcon src={livingRoomLoveseat1Panel} />,
                        "boardIcon": livingRoomLoveseat1Board,
                        "type": "item",
                        "description": "2.03m x 0.91m x 0.97m (DWH)",
                        "height": 30,
                        "width": 60,
                        "panelIcon": livingRoomLoveseat1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Loveseat 2",
                        "title": "Double Sofa 2",
                        "icon": <ItemIcon src={livingRoomLoveseat2Panel} />,
                        "boardIcon": livingRoomLoveseat2Board,
                        "type": "item",
                        "description": "1.55m x 0.81m x 0.81m (DWH)",
                        "height": 30,
                        "width": 50,
                        "panelIcon": livingRoomLoveseat2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Loveseat 3",
                        "title": "Double Sofa 3",
                        "icon": <ItemIcon src={livingRoomLoveseat3Panel} />,
                        "boardIcon": livingRoomLoveseat3Board,
                        "type": "item",
                        "description": "2.06m x 0.89m x 0.79m (DWH)",
                        "height": 30,
                        "width": 60,
                        "panelIcon": livingRoomLoveseat3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Loveseat 4",
                        "title": "Double Sofa 4",
                        "icon": <ItemIcon src={livingRoomLoveseat4Panel} />,
                        "boardIcon": livingRoomLoveseat4Board,
                        "type": "item",
                        "description": "2.03m x 0.84m x 0.71m (DWH)",
                        "height": 30,
                        "width": 70,
                        "panelIcon": livingRoomLoveseat4Panel,
                        "zIndex":2
                    }
                ]
            },
            /* Storages */
            {
                "key": "Storage",
                "title": "Cabinets",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Media Unit 7",
                        "icon": <ItemIcon src={livingRoomMediaUnit1Panel} />,
                        "boardIcon": livingRoomMediaUnit1Board,
                        "type": "item",
                        "description": "1.80m x 0.43m x 1.55m (DWH)",
                        "height": 28,
                        "width": 88,
                        "panelIcon": livingRoomMediaUnit1Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Media Unit 1",
                        "icon": <ItemIcon src={livingRoomMediaUnit2Panel} />,
                        "boardIcon": livingRoomMediaUnit2Board,
                        "type": "item",
                        "description": "1.80m x 0.41m x 0.43m (DWH)",
                        "height": 21,
                        "width": 82,
                        "panelIcon": livingRoomMediaUnit2Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Media Unit 2",
                        "icon": <ItemIcon src={livingRoomMediaUnit3Panel} />,
                        "boardIcon": livingRoomMediaUnit3Board,
                        "type": "item",
                        "description": "1.80m x 0.36m x 0.46m (DWH)",
                        "height": 21,
                        "width": 90,
                        "panelIcon": livingRoomMediaUnit3Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Media Unit 3",
                        "icon": <ItemIcon src={livingRoomMediaUnit4Panel} />,
                        "boardIcon": livingRoomMediaUnit4Board,
                        "type": "item",
                        "description": "1.60m x 0.36m x 0.43m (DWH)",
                        "height": 18,
                        "width": 86,
                        "panelIcon": livingRoomMediaUnit4Panel,
                        "zIndex":3
                    },
                    {
                        "title": "Media Unit 4",
                        "icon": <ItemIcon src={livingRoomMediaUnit5Panel} />,
                        "boardIcon": livingRoomMediaUnit5Board,
                        "type": "item",
                        "description": "1.80m x 0.41m x 0.46m (DWH)",
                        "height": 22,
                        "width": 94,
                        "panelIcon": livingRoomMediaUnit5Panel,
                        "zIndex":3
                    },
                    {
                        "key": "kidsFurniture3",
                        "title": `Decorative Shelf 2`,
                        "type": "item",
                        "description": "0.91m x 0.30m x 1.63m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel3} />,
                        "panelIcon": kidsFurniturePanel3,
                        "boardIcon": kidsFurnitureBoard3,
                        "height": 19,
                        "width": 72,
                        "zIndex":3
                    },
                    {
                        "key": "storage1",
                        "title": "Dresser 6",
                        "icon": <ItemIcon src={livingRoomStorage1Panel} />,
                        "boardIcon": livingRoomStorage1Board,
                        "type": "item",
                        "description": "1.32m x 0.53m x 1.55m (DWH)",
                        "height": 21,
                        "width": 51,
                        "panelIcon": livingRoomStorage1Panel,
                        "zIndex":3
                    },
                    /*
                    {
                        "title": "Storage 2",
                        "icon": <ItemIcon src={livingRoomStorage2Panel} />,
                        "boardIcon": livingRoomStorage2Board,
                        "type": "item",
                        "description": "2' 11\" x 0' 10\" x 1' 11\" DWH",
                        "height": 11,
                        "width": 31,
                        "panelIcon": livingRoomStorage2Panel
                    }*/,
                    {
                        "key":"cabinetUnit1",
                        "title": "Dresser 5",
                        "icon": <ItemIcon src={kitchenCabinetUnit1Panel} />,
                        "panelIcon": kitchenCabinetUnit1Panel,
                        "boardIcon": kitchenCabinetUnit1Board,
                        "type": "item",
                        "description": "1.50m x 0.56m x 0.46m (DWH)",
                        "height": 30,
                        "width": 56,
                        "zIndex":3
                    },
                    /*
                    {
                        "key":"cabinetUnit2",
                        "title": "Cabinet Unit 2",
                        "icon": <ItemIcon src={kitchenCabinetUnit2Panel} />,
                        "panelIcon": kitchenCabinetUnit2Panel,
                        "boardIcon": kitchenCabinetUnit2Board,
                        "type": "item",
                        "description": "3.45m x 1.70m x 0.89m (DWH)",
                        "height": 67,
                        "width": 135
                    },
                    */
                    {
                        "key": "storage3",
                        "title": "Console 4",
                        "icon": <ItemIcon src={livingRoomStorage3Panel} />,
                        "boardIcon": livingRoomStorage3Board,
                        "type": "item",
                        "description": "1.40m x 0.38m x 0.86m (DWH)",
                        "height": 15,
                        "width": 45,
                        "panelIcon": livingRoomStorage3Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Decorative Shelf 1",
                        "title": "Decorative Shelf 1",
                        "icon": <ItemIcon src={livingRoomDecorativeShelf1Panel} />,
                        "boardIcon": livingRoomDecorativeShelf1Board,
                        "type": "item",
                        "description": "1.65m x 0.30m x 2.11m (DWH)",
                        "height": 21,
                        "width": 77,
                        "panelIcon": livingRoomDecorativeShelf1Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Bookshelf 2",
                        "title": "Bookshelf 2",
                        "icon": <ItemIcon src={livingRoomBookshelf2Panel} />,
                        "boardIcon": livingRoomBookshelf2Board,
                        "type": "item",
                        "description": "0.84m x 0.41m x 1.96m (DWH)",
                        "height": 11,
                        "width": 55,
                        "panelIcon": livingRoomBookshelf2Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Bookshelf 1",
                        "title": "Bookshelf 1",
                        "icon": <ItemIcon src={livingRoomBookshelf1Panel} />,
                        "boardIcon": livingRoomBookshelf1Board,
                        "type": "item",
                        "description": "3.30m x 0.38m x 1.85m (DWH)",
                        "height": 15,
                        "width": 95,
                        "panelIcon": livingRoomBookshelf1Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Bookshelf 3",
                        "title": "Decorative Shelf 3",
                        "icon": <ItemIcon src={livingRoomBookshelf3Panel} />,
                        "boardIcon": livingRoomBookshelf3Board,
                        "type": "item",
                        "description": "0.36m x 0.33m x 2.01m (DWH)",
                        "height": 15,
                        "width": 15,
                        "panelIcon": livingRoomBookshelf3Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Bookshelf 4",
                        "title": "Decorative Shelf 4",
                        "icon": <ItemIcon src={livingRoomBookshelf4Panel} />,
                        "boardIcon": livingRoomBookshelf4Board,
                        "type": "item",
                        "description": "0.79m x 0.30m x 0.84m (DWH)",
                        "height": 42,
                        "width": 151,
                        "panelIcon": livingRoomBookshelf4Panel,
                        "zIndex":3
                    },
                    {
                        "key": "Bookshelf 5",
                        "title": "Bookshelf 3",
                        "icon": <ItemIcon src={livingRoomBookshelf5Panel} />,
                        "boardIcon": livingRoomBookshelf5Board,
                        "type": "item",
                        "description": "2.69m x 0.43m x 2.79m (DWH)",
                        "height": 12,
                        "width": 70,
                        "panelIcon": livingRoomBookshelf5Panel,
                        "zIndex":3
                    },
                    {
                        "key": "officeFileCS1",
                        "title": "Bookshelf 4",
                        "type": "item",
                        "description": "1.65m x 0.58m x 2.16m (DWH)",
                        "height": 40,
                        "width": 90,
                        "panelIcon": officeFileCabinet1Panel,
                        "icon": <ItemIcon src={officeFileCabinet1Panel} />,
                        "boardIcon": officeFileCabinet1Board,
                        "zIndex":3
                    },
                    {
                        "key": "officeFileCS2",
                        "title": "Bookshelf 5",
                        "type": "item",
                        "description": "0.79m x 0.38m x 0.76m (DWH)",
                        "height": 25,
                        "width": 60,
                        "panelIcon": officeFileCabinet2Panel,
                        "icon": <ItemIcon src={officeFileCabinet2Panel} />,
                        "boardIcon": officeFileCabinet2Board,
                        "zIndex":3
                    },
                    {
                        "key": "officeFileCS3",
                        "title": "Decorative Shelf 7",
                        "type": "item",
                        "description": "2.01m x 0.41m x 1.30m (DWH)",
                        "height": 29,
                        "width": 89,
                        "panelIcon": officeFileCabinet3Panel,
                        "icon": <ItemIcon src={officeFileCabinet3Panel} />,
                        "boardIcon": officeFileCabinet3Board,
                        "zIndex":3
                    },
                    {
                        "key": "officeFileCS4",
                        "title": "Decorative Shelf 6",
                        "type": "item",
                        "description": "0.79m x 0.43m x 1.19m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": officeFileCabinet4Panel,
                        "icon": <ItemIcon src={officeFileCabinet4Panel} />,
                        "boardIcon": officeFileCabinet4Board,
                        "zIndex":3
                    },
                    {
                        "key": "dresser1",
                        "title": "Dresser 1",
                        "type": "item",
                        "description": "0.81m x 0.43m x 1.09m (DWH)",
                        "height": 20,
                        "width": 35,
                        "panelIcon": bedroomDresser1Panel,
                        "icon": <ItemIcon src={bedroomDresser1Panel} />,
                        "boardIcon": bedroomDresser1Board,
                        "zIndex":3
                    },
                    {
                        "key": "dresser2",
                        "title": "Dresser 2",
                        "type": "item",
                        "description": "4'11\" x 1'3\" x 2'9\" DWH",
                        "height": 20,
                        "width": 60,
                        "panelIcon": bedroomDresser2Panel,
                        "icon": <ItemIcon src={bedroomDresser2Panel} />,
                        "boardIcon": bedroomDresser2Board,
                        "zIndex":3
                    },
                    {
                        "key": "dresser3",
                        "title": "Dresser 3",
                        "type": "item",
                        "description": "3'3\" x 1'9\" x 2'11\" DWH",
                        "height": 21,
                        "width": 55,
                        "panelIcon": bedroomDresser3Panel,
                        "icon": <ItemIcon src={bedroomDresser3Panel} />,
                        "boardIcon": bedroomDresser3Board,
                        "zIndex":3
                    },
                    {
                        "key": "dresser4",
                        "title": "Dresser 4",
                        "type": "item",
                        "description": "1'9\" x 0'9\" x 3'5\" DWH",
                        "height": 17,
                        "width": 45,
                        "panelIcon": bedroomDresser4Panel,
                        "icon": <ItemIcon src={bedroomDresser4Panel} />,
                        "boardIcon": bedroomDresser4Board,
                        "zIndex":3
                    },
                    {
                        "key": "Console Table 1",
                        "title": "Media Unit 5",
                        "icon": <ItemIcon src={livingRoomConsoleTable1Panel} />,
                        "boardIcon": livingRoomConsoleTable1Board,
                        "type": "item",
                        "description": "2.87m x 0.69m x 0.86m (DWH)",
                        "height": 25,
                        "width": 103,
                        "panelIcon": livingRoomConsoleTable1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Console Table 2",
                        "title": "Dresser 7",
                        "icon": <ItemIcon src={livingRoomConsoleTable2Panel} />,
                        "boardIcon": livingRoomConsoleTable2Board,
                        "type": "item",
                        "description": "1.37m x 0.51m x 0.84m (DWH)",
                        "height": 25,
                        "width": 64,
                        "panelIcon": livingRoomConsoleTable2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Console Table 3",
                        "title": "Media Unit 6",
                        "icon": <ItemIcon src={livingRoomConsoleTable3Panel} />,
                        "boardIcon": livingRoomConsoleTable3Board,
                        "type": "item",
                        "description": "1.52m x 0.46m x 0.81m (DWH)",
                        "height": 23,
                        "width": 95,
                        "panelIcon": livingRoomConsoleTable3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Console Table 4",
                        "title": "Console 1",
                        "icon": <ItemIcon src={livingRoomConsoleTable4Panel} />,
                        "boardIcon": livingRoomConsoleTable4Board,
                        "type": "item",
                        "description": "1.80m x 0.46m x 0.91m (DWH)",
                        "height": 25,
                        "width": 101,
                        "panelIcon": livingRoomConsoleTable4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "console5",
                        "title": "Console 2",
                        "icon": <ItemIcon src={livingRoomHutchBuffet3Panel} />,
                        "boardIcon": livingRoomHutchBuffet3Board,
                        "type": "item",
                        "description": "1.24m x 0.43m x 0.81m (DWH)",
                        "height": 17,
                        "width": 45,
                        "panelIcon": livingRoomHutchBuffet3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Buffet1",
                        "title": "Console 3",
                        "icon": <ItemIcon src={livingRoomHutchBuffet1Panel} />,
                        "boardIcon": livingRoomHutchBuffet1Board,
                        "type": "item",
                        "description": "1.07m x 0.46m x 1.83m (DWH)",
                        "height": 18,
                        "width": 32,
                        "panelIcon": livingRoomHutchBuffet1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Buffet2",
                        "title": "Decorative Shelf 5",
                        "icon": <ItemIcon src={livingRoomHutchBuffet2Panel} />,
                        "boardIcon": livingRoomHutchBuffet2Board,
                        "type": "item",
                        "description": "2.13m x 0.41m x 2.49m (DWH)",
                        "height": 26,
                        "width": 98,
                        "panelIcon": livingRoomHutchBuffet2Panel,
                        "zIndex":2
                    },
                ]
            },
            /* Tables */
            {
                "key": "tables",
                "title": "Tables",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "kidsFurniture7",
                        "title": `Kids' Table 2`,
                        "type": "item",
                        "description": "0.76m x 0.43m x 0.76m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel7} />,
                        "panelIcon": kidsFurniturePanel7,
                        "boardIcon": kidsFurnitureBoard7,
                        "height": 62,
                        "width": 110,
                        "zIndex":2
                    },
                    
                    {
                        "key": "kidsFurniture9",
                        "title": `Kids' Table 3`,
                        "type": "item",
                        "description": "0.94m x 0.71m x 1.02m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel9} />,
                        "panelIcon": kidsFurniturePanel9,
                        "boardIcon": kidsFurnitureBoard9,
                        "height": 29,
                        "width": 35,
                        "zIndex":2
                    },
                    {
                        "key": "kidsFurniture10",
                        "title": `Kids' Table 4`,
                        "type": "item",
                        "description": "0.94m x 0.71m x 1.02m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel10} />,
                        "panelIcon": kidsFurniturePanel10,
                        "boardIcon": kidsFurnitureBoard10,
                        "height": 29,
                        "width": 35,
                        "zIndex":2
                    },
                    {
                        "key": "kidsFurniture4",
                        "title": `Kids' Table 1`,
                        "type": "item",
                        "description": "1.14m x 0.64m x 1.27m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel4} />,
                        "panelIcon": kidsFurniturePanel4,
                        "boardIcon": kidsFurnitureBoard4,
                        "height": 50,
                        "width": 80,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk1",
                        "title": "Desk 1",
                        "type": "item",
                        "description": "1.52m x 1.47m x 1.07m (DWH)",
                        "panelIcon": officeDesk1Panel,
                        "icon": <ItemIcon src={officeDesk1Panel} />,
                        "boardIcon": officeDesk1Board,
                        "height": 90,
                        "width": 100,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk2",
                        "title": "Desk 2",
                        "type": "item",
                        "description": "2.01m x 0.89m x 1.27m (DWH)",
                        "panelIcon": officeDesk2Panel,
                        "icon": <ItemIcon src={officeDesk2Panel} />,
                        "boardIcon": officeDesk2Board,
                        "height": 100,
                        "width": 55,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk3",
                        "title": "Desk 3",
                        "type": "item",
                        "description": "1.80m x 0.91m x 0.71m (DWH)",
                        "panelIcon": officeDesk3Panel,
                        "icon": <ItemIcon src={officeDesk3Panel} />,
                        "boardIcon": officeDesk3Board,
                        "height": 80,
                        "width": 40,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk4",
                        "title": "Desk 4",
                        "type": "item",
                        "description": "1.60m x 1.09m x 0.89m (DWH)",
                        "panelIcon": officeDesk4Panel,
                        "icon": <ItemIcon src={officeDesk4Panel} />,
                        "boardIcon": officeDesk4Board,
                        "height": 75,
                        "width": 50,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk5",
                        "title": "Desk 5",
                        "type": "item",
                        "description": "1.17m x 0.61m x 0.79m (DWH)",
                        "panelIcon": officeDesk5Panel,
                        "icon": <ItemIcon src={officeDesk5Panel} />,
                        "boardIcon": officeDesk5Board,
                        "height": 60,
                        "width": 30,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk6",
                        "title": "Desk 6",
                        "type": "item",
                        "description": "2.74m x 0.94m x 1.57m (DWH)",
                        "panelIcon": officeDesk6Panel,
                        "icon": <ItemIcon src={officeDesk6Panel} />,
                        "boardIcon": officeDesk6Board,
                        "height": 59,
                        "width": 169,
                        "zIndex":2
                    },
                    {
                        "key": "officeDesk7",
                        "title": "Desk 7",
                        "type": "item",
                        "description": "1.19m x 1.40m x 0.76m (DWH)",
                        "panelIcon": officeDesk7Panel,
                        "icon": <ItemIcon src={officeDesk7Panel} />,
                        "boardIcon": officeDesk7Board,
                        "height": 55,
                        "width": 65,
                        "zIndex":2
                    },
                    {
                        "key": "Coffee Table 1",
                        "title": "Coffee Table 1",
                        "icon": <ItemIcon src={livingRoomCoffeeTable1Panel} />,
                        "boardIcon": livingRoomCoffeeTable1Board,
                        "type": "item",
                        "description": "0.76m x 0.76m x 0.51m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomCoffeeTable1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Coffee Table 2",
                        "title": "Coffee Table 2",
                        "icon": <ItemIcon src={livingRoomCoffeeTable2Panel} />,
                        "boardIcon": livingRoomCoffeeTable2Board,
                        "type": "item",
                        "description": "1.14m x 0.76m x 0.71m (DWH)",
                        "height": 23,
                        "width": 34,
                        "panelIcon": livingRoomCoffeeTable2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Coffee Table 3",
                        "title": "Coffee Table 3",
                        "icon": <ItemIcon src={livingRoomCoffeeTable3Panel} />,
                        "boardIcon": livingRoomCoffeeTable3Board,
                        "type": "item",
                        "description": "0.41m x 0.41m x 0.48m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomCoffeeTable3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Coffee Table 4",
                        "title": "Coffee Table 4",
                        "icon": <ItemIcon src={livingRoomCoffeeTable4Panel} />,
                        "boardIcon": livingRoomCoffeeTable4Board,
                        "type": "item",
                        "description": "1.22m x 0.89m x 0.97m (DWH)",
                        "height": 23,
                        "width": 34,
                        "panelIcon": livingRoomCoffeeTable4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Coffee Table 5",
                        "title": "Coffee Table 5",
                        "icon": <ItemIcon src={livingRoomCoffeeTable5Panel} />,
                        "boardIcon": livingRoomCoffeeTable5Board,
                        "type": "item",
                        "description": "1.30m x 0.81m x 0.41m (DWH)",
                        "height": 30,
                        "width": 46,
                        "panelIcon": livingRoomCoffeeTable5Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Side Table 1",
                        "title": "Side Table 1",
                        "icon": <ItemIcon src={livingRoomSideTable1Panel} />,
                        "boardIcon": livingRoomSideTable1Board,
                        "type": "item",
                        "description": "0.48m x 0.48m x 0.51m (DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable1Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Side Table 2",
                        "title": "Side Table 2",
                        "icon": <ItemIcon src={livingRoomSideTable2Panel} />,
                        "boardIcon": livingRoomSideTable2Board,
                        "type": "item",
                        "description": "0.41m x 0.41m x 0.64m (DWH)",
                        "height": 17,
                        "width": 17,
                        "panelIcon": livingRoomSideTable2Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Side Table 3",
                        "title": "Side Table 3",
                        "icon": <ItemIcon src={livingRoomSideTable3Panel} />,
                        "boardIcon": livingRoomSideTable3Board,
                        "type": "item",
                        "description": "0.51m x 0.51m x 0.64m (DWH",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable3Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Side Table 4",
                        "title": "Side Table 4",
                        "icon": <ItemIcon src={livingRoomSideTable4Panel} />,
                        "boardIcon": livingRoomSideTable4Board,
                        "type": "item",
                        "description": "0.46m x 0.46m x 0.51m (DWH)",
                        "height": 18,
                        "width": 18,
                        "panelIcon": livingRoomSideTable4Panel,
                        "zIndex":2
                    },
                    {
                        "key": "Side Table 5",
                        "title": "Side Table 5",
                        "icon": <ItemIcon src={livingRoomSideTable5Panel} />,
                        "boardIcon": livingRoomSideTable5Board,
                        "type": "item",
                        "description": "0.51m x 0.51m x 0.58m (DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable5Panel,
                        "zIndex":3
                    },
                    {
                        "key": "nighTable1",
                        "title": "Night Table 1",
                        "type": "item",
                        "description": "0.56m x 0.43m x 0.97m (DWH)",
                        "height": 20,
                        "width": 31,
                        "panelIcon": nightTablePanel1,
                        "icon": <ItemIcon src={nightTablePanel1} />,
                        "boardIcon": nightTableBoard1,
                        "zIndex":3
                    },
                    {
                        "key": "nighTable2",
                        "title": "Night Table 2",
                        "type": "item",
                        "description": "0.46m x 0.41m x 0.46m (DWH)",
                        "height": 20,
                        "width": 18,
                        "panelIcon": nightTablePanel2,
                        "icon": <ItemIcon src={nightTablePanel2} />,
                        "boardIcon": nightTableBoard2,
                        "zIndex":3
                    },
                    {
                        "key": "nighTable3",
                        "title": "Night Table 3",
                        "type": "item",
                        "description": "0.56m x 0.43m x 0.81m (DWH)",
                        "height": 25,
                        "width": 32,
                        "panelIcon": nightTablePanel3,
                        "icon": <ItemIcon src={nightTablePanel3} />,
                        "boardIcon": nightTableBoard3,
                        "zIndex":3
                    },
                    {
                        "key": "nighTable4",
                        "title": "Night Table 4",
                        "type": "item",
                        "description": "0.41m x 0.43m x 0.46m (DWH)",
                        "height": 18,
                        "width": 30,
                        "panelIcon": nightTablePanel4,
                        "icon": <ItemIcon src={nightTablePanel4} />,
                        "boardIcon": nightTableBoard4,
                        "zIndex":3
                    },
                    {
                        "key": "dressingTable1",
                        "title": "Dressing Table 1",
                        "type": "item",
                        "description": "0.89m x 0.48m x 1.55m (DWH)",
                        "height": 30,
                        "width": 45,
                        "panelIcon": dressingTable1Panel,
                        "icon": <ItemIcon src={dressingTable1Panel} />,
                        "boardIcon": dressingTable1Board,
                        "zIndex":3
                    },
                    {
                        "key": "dressingTable2",
                        "title": "Dressing Table 2",
                        "type": "item",
                        "description": "0.89m x 0.38m x 1.35m (DWH)",
                        "height": 25,
                        "width": 40,
                        "panelIcon": dressingTable2Panel,
                        "icon": <ItemIcon src={dressingTable2Panel} />,
                        "boardIcon": dressingTable2Board,
                        "zIndex":3
                    },
                    {
                        "key": "dressingTable3",
                        "title": "Dressing Table 3",
                        "type": "item",
                        "description": "1.09m x 0.53m x 1.40m (DWH)",
                        "height": 22,
                        "width": 45,
                        "panelIcon": dressingTable3Panel,
                        "icon": <ItemIcon src={dressingTable3Panel} />,
                        "boardIcon": dressingTable3Board,
                        "zIndex":3
                    },
                    {
                        "key": "dressingTable4",
                        "title": "Dressing Table 4",
                        "type": "item",
                        "description": "1.57m x 0.46m x 1.24m (DWH)",
                        "height": 54,
                        "width": 63,
                        "panelIcon": dressingTable4Panel,
                        "icon": <ItemIcon src={dressingTable4Panel} />,
                        "boardIcon": dressingTable4Board,
                        "zIndex":3
                    },
                    {
                        "key": "diningTable1",
                        "title": "Dining Table Set 4",
                        "type": "item",
                        "description": "2.16m x 2.08m x 1.57m (DWH)",
                        "panelIcon": diningTable1Panel,
                        "icon": <ItemIcon src={diningTable1Panel} />,
                        "boardIcon": diningTable1Board,
                        "height": 93,
                        "width": 99,
                        "zIndex":3
                    },
                    {
                        "key": "diningTable2",
                        "title": "Table 1",
                        "type": "item",
                        "description": "1.60m x 0.79m x 0.76m (DWH)",
                        "panelIcon": diningTable2Panel,
                        "icon": <ItemIcon src={diningTable2Panel} />,
                        "boardIcon": diningTable2Board,
                        "height": 53,
                        "width": 27,
                        "zIndex":3
                    },
                    {
                        "key": "diningTable3",
                        "title": "Table 2",
                        "type": "item",
                        "description": "1.40m x 1.40m x 0.74m (DWH)",
                        "panelIcon": diningTable3Panel,
                        "icon": <ItemIcon src={diningTable3Panel} />,
                        "boardIcon": diningTable3Board,
                        "height": 47,
                        "width": 47,
                        "zIndex":3
                    },
                    {
                        "key": "diningTable4",
                        "title": "Table 3",
                        "type": "item",
                        "description": "1.80m x 0.84m x 0.76m (DWH)",
                        "panelIcon": diningTable4Panel,
                        "icon": <ItemIcon src={diningTable4Panel} />,
                        "boardIcon": diningTable4Board,
                        "height": 62,
                        "width": 37,
                        "zIndex":3
                    },
                    {
                        "key": "diningTable5",
                        "title": "Dining Table Set 5",
                        "type": "item",
                        "description": "1.40m x 1.50m x 0.97m (DWH)",
                        "panelIcon": diningTable5Panel,
                        "icon": <ItemIcon src={diningTable5Panel} />,
                        "boardIcon": diningTable5Board,
                        "height": 65,
                        "width": 80,
                        "zIndex":3
                    },
                    {
                        "key": "meetingRoomtable1",
                        "title": "Meeting Room Table 1",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel1} />,
                        "panelIcon": tablePanel1,
                        "boardIcon": tableBoard1,
                        "height": 125,
                        "width": 90,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable2",
                        "title": "Table 4",
                        "type": "item",
                        "description": "Description ",
                        "icon": <ItemIcon src={tablePanel2} />,
                        "panelIcon": tablePanel2,
                        "boardIcon": tableBoard2,
                        "height": 55,
                        "width": 35,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable3",
                        "title": "Meeting Room Table 2",
                        "type": "item",
                        "description": "Description ",
                        "icon": <ItemIcon src={tablePanel3} />,
                        "panelIcon": tablePanel3,
                        "boardIcon": tableBoard3,
                        "height": 74,
                        "width": 140,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable4",
                        "title": "Meeting Room Table 3",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel4} />,
                        "panelIcon": tablePanel4,
                        "boardIcon": tableBoard4,
                        "height": 43,
                        "width": 104,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable5",
                        "title": "Meeting Room Table 4",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel5} />,
                        "panelIcon": tablePanel5,
                        "boardIcon": tableBoard5,
                        "height": 100,
                        "width": 215,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable6",
                        "title": "Meeting Room Table 5",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel6} />,
                        "panelIcon": tablePanel6,
                        "boardIcon": tableBoard6,
                        "height": 117,
                        "width": 185,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable7",
                        "title": "Meeting Room Table 6",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel7} />,
                        "panelIcon": tablePanel7,
                        "boardIcon": tableBoard7,
                        "height": 41,
                        "width": 85,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable8",
                        "title": "Meeting Room Table 7",
                        "type": "item",
                        "description": "Description",
                        "icon": <ItemIcon src={tablePanel8} />,
                        "panelIcon": tablePanel8,
                        "boardIcon": tableBoard8,
                        "height": 70,
                        "width": 70,
                        "zIndex":2
                    },
                    {
                        "key": "meetingRoomtable9",
                        "title": "Reception Desk",
                        "type": "item",
                        "description": "Description of Table 9",
                        "icon": <ItemIcon src={tablePanel9} />,
                        "panelIcon": tablePanel9,
                        "boardIcon": tableBoard9,
                        "height": 94,
                        "width": 110,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat3",
                        "title": "Bar & Counter-Seats 1",
                        "type": "item",
                        "description": "0.79m x 1.63m x 0.84m (DWH)",
                        "height": 33,
                        "width": 64,
                        "panelIcon": barSeat3Panel,
                        "icon": <ItemIcon src={barSeat3Panel} />,
                        "boardIcon": barSeat3Board,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat4",
                        "title": "Bar & Counter-Seats 2",
                        "type": "item",
                        "description": "1.88m x 1.68m x 2.13m (DWH)",
                        "height": 64,
                        "width": 76,
                        "panelIcon": barSeat4Panel,
                        "icon": <ItemIcon src={barSeat4Panel} />,
                        "boardIcon": barSeat4Board,
                        "zIndex":2
                    },
                    {
                        "key": "barSeat6",
                        "title": "Bar & Counter-Seats 3",
                        "type": "item",
                        "description": "1.96m x 1.07m x 1.09m (DWH)",
                        "height": 44,
                        "width": 90,
                        "panelIcon": barSeat6Panel,
                        "icon": <ItemIcon src={barSeat6Panel} />,
                        "boardIcon": barSeat6Board,
                        "zIndex":2
                    },
                    {
                        "key": "diningSeat1",
                        "title": "Dining Table Set 1",
                        "type": "item",
                        "description": "1.65m x 2.03m x 1.07m (DWH)",
                        "panelIcon": diningSeat1Panel,
                        "icon": <ItemIcon src={diningSeat1Panel} />,
                        "boardIcon": diningSeat1Board,
                        "height": 100,
                        "width": 85,
                        "zIndex":2
                    },
                    {
                        "key": "diningSeat2",
                        "title": "Dining Table Set 2",
                        "type": "item",
                        "description": "1.88m x 0.79m x 0.66m (DWH)",
                        "panelIcon": diningSeat2Panel,
                        "icon": <ItemIcon src={diningSeat2Panel} />,
                        "boardIcon": diningSeat2Board,
                        "height": 42,
                        "width": 90,
                        "zIndex":2
                    },
                    {
                        "key": "diningSeat3",
                        "title": "Dining Table Set 3",
                        "type": "item",
                        "description": "1.96m x 1.93m x 0.94m (DWH)",
                        "panelIcon": diningSeat3Panel,
                        "icon": <ItemIcon src={diningSeat3Panel} />,
                        "boardIcon": diningSeat3Board,
                        "height": 95,
                        "width": 89,
                        "zIndex":2
                    }
                ]

            },
            /* Toilets */
            /*
            {
                "key": "toilets",
                "title": "Toilets",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Toys */
            /*
            {
                "key": "toys",
                "title": "Toys",
                "category": "Children's Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Tubs */
            /*
            {
                "key": "tubs",
                "title": "Tubs",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Utensils */
            /*
            {
                "key": "kitchenUtensils",
                "title": "Utensils",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                   

                ]
            },
            */
            /* Vanities */
            /*
            {
                "key": "vanities",
                "title": "Vanities",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    
                ]
            },
            */
            /* Wardrobes */
            {
                "key": "wardrobes",
                "title": "Wardrobes",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "kidsFurniture8",
                        "title": `Kids' Wardrobe 1`,
                        "type": "item",
                        "description": "1.68m x 0.64m x 2.36m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel8} />,
                        "panelIcon": kidsFurniturePanel8,
                        "boardIcon": kidsFurnitureBoard8,
                        "height": 28,
                        "width": 98,
                        "zIndex":2
                    },
                    {
                        "key": "kidsFurniture2",
                        "title": `Kids' Wardrobe 2`,
                        "type": "item",
                        "description": "1.75m x 0.64m x 2.49m (DWH)",
                        "icon": <ItemIcon src={kidsFurniturePanel2} />,
                        "panelIcon": kidsFurniturePanel2,
                        "boardIcon": kidsFurnitureBoard2,
                        "height": 35,
                        "width": 105,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe1",
                        "title": "Wardrobe 1",
                        "type": "item",
                        "description": "2.41m x 1.27m x 2.49m (DWH)",
                        "height": 54,
                        "width": 132,
                        "panelIcon": wardrobePanel1,
                        "icon": <ItemIcon src={wardrobePanel1} />,
                        "boardIcon": wardrobeBoard1,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe2",
                        "title": "Wardrobe 2",
                        "type": "item",
                        "description": "2.03m x 0.69m x 2.36m (DWH)",
                        "height": 29,
                        "width": 91,
                        "panelIcon": wardrobePanel2,
                        "icon": <ItemIcon src={wardrobePanel2} />,
                        "boardIcon": wardrobeBoard2,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe3",
                        "title": "Wardrobe 3",
                        "type": "item",
                        "description": "5.16m x 0.69m x 2.92m (DWH)",
                        "height": 23,
                        "width": 111,
                        "panelIcon": wardrobePanel3,
                        "icon": <ItemIcon src={wardrobePanel3} />,
                        "boardIcon": wardrobeBoard3,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe4",
                        "title": "Wardrobe 4",
                        "type": "item",
                        "description": "2.21m x 0.61m x 2.24m (DWH)",
                        "height": 24,
                        "width": 88,
                        "panelIcon": wardrobePanel4,
                        "icon": <ItemIcon src={wardrobePanel4} />,
                        "boardIcon": wardrobeBoard4,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe5",
                        "title": "Wardrobe 5",
                        "type": "item",
                        "description": "2.21m x 0.61m x 2.24m (DWH)",
                        "height": 25,
                        "width": 70,
                        "panelIcon": wardrobePanel5,
                        "icon": <ItemIcon src={wardrobePanel5} />,
                        "boardIcon": wardrobeBoard5,
                        "zIndex":2
                    },
                    {
                        "key": "wardrobe6",
                        "title": "Wardrobe 6",
                        "type": "item",
                        "description": "2.44m x 0.53m x 2.26m (DWH)",
                        "height": 55,
                        "width": 80,
                        "panelIcon": wardrobePanel6,
                        "icon": <ItemIcon src={wardrobePanel6} />,
                        "boardIcon": wardrobeBoard6,
                        "zIndex":2
                    }

                ]
            },
            /*
            {
                "key": "coffeetables",
                "title": "Coffee Tables",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Coffee Table 1",
                        "icon": <ItemIcon src={livingRoomCoffeeTable1Panel} />,
                        "boardIcon": livingRoomCoffeeTable1Board,
                        "type": "item",
                        "description": "0.76m x 0.76m x 0.51m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomCoffeeTable1Panel
                    },
                    {
                        "title": "Coffee Table 2",
                        "icon": <ItemIcon src={livingRoomCoffeeTable2Panel} />,
                        "boardIcon": livingRoomCoffeeTable2Board,
                        "type": "item",
                        "description": "1.14m x 0.76m x 0.71m (DWH)",
                        "height": 23,
                        "width": 34,
                        "panelIcon": livingRoomCoffeeTable2Panel
                    },
                    {
                        "title": "Coffee Table 3",
                        "icon": <ItemIcon src={livingRoomCoffeeTable3Panel} />,
                        "boardIcon": livingRoomCoffeeTable3Board,
                        "type": "item",
                        "description": "0.41m x 0.41m x 0.48m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomCoffeeTable3Panel
                    },
                    {
                        "title": "Coffee Table 4",
                        "icon": <ItemIcon src={livingRoomCoffeeTable4Panel} />,
                        "boardIcon": livingRoomCoffeeTable4Board,
                        "type": "item",
                        "description": "1.22m x 0.89m x 0.97m (DWH)",
                        "height": 23,
                        "width": 34,
                        "panelIcon": livingRoomCoffeeTable4Panel
                    },
                    {
                        "title": "Coffee Table 5",
                        "icon": <ItemIcon src={livingRoomCoffeeTable5Panel} />,
                        "boardIcon": livingRoomCoffeeTable5Board,
                        "type": "item",
                        "description": "1.30m x 0.81m x 0.41m (DWH)",
                        "height": 30,
                        "width": 46,
                        "panelIcon": livingRoomCoffeeTable5Panel
                    }


                ]
            },
            {
                "key": "consoletables",
                "title": "Console Tables",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Console Table 1",
                        "icon": <ItemIcon src={livingRoomConsoleTable1Panel} />,
                        "boardIcon": livingRoomConsoleTable1Board,
                        "type": "item",
                        "description": "2.87m x 0.69m x 0.86m (DWH)",
                        "height": 25,
                        "width": 103,
                        "panelIcon": livingRoomConsoleTable1Panel
                    },
                    {
                        "title": "Console Table 2",
                        "icon": <ItemIcon src={livingRoomConsoleTable2Panel} />,
                        "boardIcon": livingRoomConsoleTable2Board,
                        "type": "item",
                        "description": "1.37m x 0.51m x 0.84m (DWH)",
                        "height": 25,
                        "width": 64,
                        "panelIcon": livingRoomConsoleTable2Panel
                    },
                    {
                        "title": "Console Table 3",
                        "icon": <ItemIcon src={livingRoomConsoleTable3Panel} />,
                        "boardIcon": livingRoomConsoleTable3Board,
                        "type": "item",
                        "description": "1.52m x 0.46m x 0.81m (DWH)",
                        "height": 23,
                        "width": 95,
                        "panelIcon": livingRoomConsoleTable3Panel
                    },
                    {
                        "title": "Console Table 4",
                        "icon": <ItemIcon src={livingRoomConsoleTable4Panel} />,
                        "boardIcon": livingRoomConsoleTable4Board,
                        "type": "item",
                        "description": "1.80m x 0.46m x 0.91m (DWH)",
                        "height": 25,
                        "width": 101,
                        "panelIcon": livingRoomConsoleTable4Panel
                    }

                ]
            },
            */

            /*
            {
                "key": "loungechairs",
                "title": "Lounge Chairs",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Lounge Chair 1",
                        "icon": <ItemIcon src={livingRoomLoungeChair1Panel} />,
                        "boardIcon": livingRoomLoungeChair1Board,
                        "type": "item",
                        "description": "0.71m x 0.76m x 0.76m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": livingRoomLoungeChair1Panel
                    },
                    {
                        "title": "Lounge Chair 2",
                        "icon": <ItemIcon src={livingRoomLoungeChair2Panel} />,
                        "boardIcon": livingRoomLoungeChair2Board,
                        "type": "item",
                        "description": "0.89m x 0.81m x 0.79m (DWH)",
                        "height": 30,
                        "width": 27,
                        "panelIcon": livingRoomLoungeChair2Panel
                    },
                    {
                        "title": "Lounge Chair 3",
                        "icon": <ItemIcon src={livingRoomLoungeChair3Panel} />,
                        "boardIcon": livingRoomLoungeChair3Board,
                        "type": "item",
                        "description": "0.69m x 0.81m x 0.91m (DWH)",
                        "height": 30,
                        "width": 31,
                        "panelIcon": livingRoomLoungeChair3Panel
                    }


                ]
            },
            */


            /*
            {
                "key": "sidetables",
                "title": "Side Tables",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Side Table 1",
                        "icon": <ItemIcon src={livingRoomSideTable1Panel} />,
                        "boardIcon": livingRoomSideTable1Board,
                        "type": "item",
                        "description": "0.48m x 0.48m x 0.51m (DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable1Panel
                    },
                    {
                        "title": "Side Table 2",
                        "icon": <ItemIcon src={livingRoomSideTable2Panel} />,
                        "boardIcon": livingRoomSideTable2Board,
                        "type": "item",
                        "description": "0.41m x 0.41m x 0.64m (DWH)",
                        "height": 17,
                        "width": 17,
                        "panelIcon": livingRoomSideTable2Panel
                    },
                    {
                        "title": "Side Table 3",
                        "icon": <ItemIcon src={livingRoomSideTable3Panel} />,
                        "boardIcon": livingRoomSideTable3Board,
                        "type": "item",
                        "description": "0.51m x 0.51m x 0.64m (DWH",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable3Panel
                    },
                    {
                        "title": "Side Table 4",
                        "icon": <ItemIcon src={livingRoomSideTable4Panel} />,
                        "boardIcon": livingRoomSideTable4Board,
                        "type": "item",
                        "description": "0.46m x 0.46m x 0.51m (DWH)",
                        "height": 18,
                        "width": 18,
                        "panelIcon": livingRoomSideTable4Panel
                    },
                    {
                        "title": "Side Table 5",
                        "icon": <ItemIcon src={livingRoomSideTable5Panel} />,
                        "boardIcon": livingRoomSideTable5Board,
                        "type": "item",
                        "description": "0.51m x 0.51m x 0.58m (DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": livingRoomSideTable5Panel
                    }
                ]
            },
            */
            /*
             {
                 "key": "singlesofa",
                 "title": "Single Sofa",
                 "category": "Living Room",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key":"singleSofa1",
                         "title": "Single Sofa 1",
                         "icon": <ItemIcon src={livingRoomSingleSofa1Panel} />,
                         "boardIcon": livingRoomSingleSofa1Board,
                         "type": "item",
                         "description": "0.81m x 0.71m x 1.07m (DWH)",
                         "height": 44,
                         "width": 60,
                         "panelIcon": livingRoomSingleSofa1Panel
                     },
                     {
                         "key":"singleSofa2",
                         "title": "Single Sofa 2",
                         "icon": <ItemIcon src={livingRoomSingleSofa2Panel} />,
                         "boardIcon": livingRoomSingleSofa2Board,
                         "type": "item",
                         "description": "0.74m x 0.81m x 0.84m (DWH)",
                         "height": 35,
                         "width": 35,
                         "panelIcon": livingRoomSingleSofa2Panel
                     },
                     {
                         "key":"singleSofa3",
                         "title": "Single Sofa 3",
                         "icon": <ItemIcon src={livingRoomSingleSofa3Panel} />,
                         "boardIcon": livingRoomSingleSofa3Board,
                         "type": "item",
                         "description": "0.74m x 0.84m x 0.99m (DWH)",
                         "height": 33,
                         "width": 32,
                         "panelIcon": livingRoomSingleSofa3Panel
                     },
                     {
                         "key":"singleSofa4",
                         "title": "Single Sofa 4",
                         "icon": <ItemIcon src={livingRoomSingleSofa4Panel} />,
                         "boardIcon": livingRoomSingleSofa4Board,
                         "type": "item",
                         "description": "1.35m x 0.89m x 0.97m (DWH)",
                         "height": 41,
                         "width": 53,
                         "panelIcon": livingRoomSingleSofa4Panel
                     },
                     {
                         "key":"singleSofa5",
                         "title": "Single Sofa 5",
                         "icon": <ItemIcon src={livingRoomSingleSofa5Panel} />,
                         "boardIcon": livingRoomSingleSofa5Board,
                         "type": "item",
                         "description": "0.97m x 0.79m x 0.89m (DWH)",
                         "height": 35,
                         "width": 36,
                         "panelIcon": livingRoomSingleSofa5Panel
                     }
 
                 ]
             },
             */
            /*
            {
                "key": "sofasets",
                "title": "Sofa Sets",
                "category": "Living Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key":"Sofa Set 1",
                        "title": "Sofa Set 1",
                        "icon": <ItemIcon src={livingRoomSofaSet1Panel} />,
                        "boardIcon": livingRoomSofaSet1Board,
                        "panelIcon":livingRoomSofaSet1Panel,
                        "type": "item",
                        "description": "",
                        "height": 150,
                        "width": 230
                    },
                    {
                        "key":"Sofa Set 2",
                        "title": "Sofa Set 2",
                        "icon": <ItemIcon src={livingRoomSofaSet2Panel} />,
                        "boardIcon": livingRoomSofaSet2Board,
                        "panelIcon":livingRoomSofaSet2Panel,
                        "type": "item",
                        "description": "",
                        "height": 137,
                        "width": 204
                    },
                    {
                        "key":"Sofa Set 3",
                        "title": "Sofa Set 3",
                        "icon": <ItemIcon src={livingRoomSofaSet3Panel} />,
                        "boardIcon": livingRoomSofaSet3Board,
                        "panelIcon":livingRoomSofaSet3Panel,
                        "type": "item",
                        "description": "",
                        "height": 136,
                        "width": 179
                    },
                    {
                        "key":"Sofa Set 4",
                        "title": "Sofa Set 4",
                        "icon": <ItemIcon src={livingRoomSofaSet4Panel} />,
                        "boardIcon": livingRoomSofaSet4Board,
                        "panelIcon":livingRoomSofaSet4Panel,
                        "type": "item",
                        "description": "",
                        "height": 200,
                        "width": 194
                    },
                ]
            },
            */

            /**
             * Bedroom
             */
            /*
            {
                "key": "bedroomAccessories",
                "title": "Bedroom Accessories",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "bedroomAccessories1",
                        "title": "Bedroom Accessory 1",
                        "type": "item",
                        "description": "0.18m x 0.18m x 0.25m(DWH)",
                        "height": 20,
                        "width": 20,
                        "panelIcon": bedroomAccessoriesPanel1,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel1} />,
                        "boardIcon": bedroomAccessoriesBoard1
                    },
                    {
                        "key": "bedroomAccessories2",
                        "title": "Bedroom Accessory 2",
                        "type": "item",
                        "description": "1.04m x 0.53m x 0.74m (DWH)",
                        "height": 15,
                        "width": 31,
                        "panelIcon": bedroomAccessoriesPanel2,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel2} />,
                        "boardIcon": bedroomAccessoriesBoard2
                    },
                    {
                        "key": "bedroomAccessories3",
                        "title": "Bedroom Accessory 3",
                        "type": "item",
                        "description": "0.28m x 0.15m x 0.20m (DWH)",
                        "height": 18,
                        "width": 11,
                        "panelIcon": bedroomAccessoriesPanel3,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel3} />,
                        "boardIcon": bedroomAccessoriesBoard3
                    },
                    {
                        "key": "bedroomAccessories4",
                        "title": "Bedroom Accessory 4",
                        "type": "item",
                        "description": "0.23m x 0.18m x 0.20m (DWH)",
                        "height": 9,
                        "width": 11,
                        "panelIcon": bedroomAccessoriesPanel4,
                        "icon": <ItemIcon src={bedroomAccessoriesPanel4} />,
                        "boardIcon": bedroomAccessoriesBoard4
                    }

                ]
            },
            */

            /*
            {
                "key": "nightTable",
                "title": "Night Tables",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "nighTable1",
                        "title": "Night Table 1",
                        "type": "item",
                        "description": "0.56m x 0.43m x 0.97m (DWH)",
                        "height": 20,
                        "width": 31,
                        "panelIcon": nightTablePanel1,
                        "icon": <ItemIcon src={nightTablePanel1} />,
                        "boardIcon": nightTableBoard1
                    },
                    {
                        "key": "nighTable2",
                        "title": "Night Table 2",
                        "type": "item",
                        "description": "0.46m x 0.41m x 0.46m (DWH)",
                        "height": 20,
                        "width": 18,
                        "panelIcon": nightTablePanel2,
                        "icon": <ItemIcon src={nightTablePanel2} />,
                        "boardIcon": nightTableBoard2
                    },
                    {
                        "key": "nighTable3",
                        "title": "Night Table 3",
                        "type": "item",
                        "description": "0.56m x 0.43m x 0.81m (DWH)",
                        "height": 25,
                        "width": 32,
                        "panelIcon": nightTablePanel3,
                        "icon": <ItemIcon src={nightTablePanel3} />,
                        "boardIcon": nightTableBoard3
                    },
                    {
                        "key": "nighTable4",
                        "title": "Night Table 4",
                        "type": "item",
                        "description": "0.41m x 0.43m x 0.46m (DWH)",
                        "height": 18,
                        "width": 30,
                        "panelIcon": nightTablePanel4,
                        "icon": <ItemIcon src={nightTablePanel4} />,
                        "boardIcon": nightTableBoard4
                    }

                ]
            },
            */
            /*
             {
                 "key": "bunkBeds",
                 "title": "Bunk Beds",
                 "category": "Bedroom",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key": "bunkbed1",
                         "title": "Bunk Bed 1",
                         "type": "item",
                         "description": "2.03m x 1.14m x 1.85m (DWH)",
                         "height": 45,
                         "width": 70,
                         "panelIcon": bunkBedPanel1,
                         "icon": <ItemIcon src={bunkBedPanel1} />,
                         "boardIcon": bunkBedBoard1
                     },
                     {
                         "key": "bunkbed2",
                         "title": "Bunk Bed 2",
                         "type": "item",
                         "description": "2.06m x 1.52m x 1.83m (DWH)",
                         "height": 60,
                         "width": 85,
                         "panelIcon": bunkBedPanel2,
                         "icon": <ItemIcon src={bunkBedPanel2} />,
                         "boardIcon": bunkBedBoard2
                     },
                     {
                         "key": "bunkbed3",
                         "title": "Bunk Bed 3",
                         "type": "item",
                         "description": "2.08m x 1.37m x 1.80m (DWH)",
                         "height": 54,
                         "width": 82,
                         "panelIcon": bunkBedPanel3,
                         "icon": <ItemIcon src={bunkBedPanel3} />,
                         "boardIcon": bunkBedBoard3
                     },
                     {
                         "key": "bunkbed4",
                         "title": "Bunk Bed 4",
                         "type": "item",
                         "description": "2.03m x 1.30m x 1.83m (DWH)",
                         "height": 51,
                         "width": 72,
                         "panelIcon": bunkBedPanel4,
                         "icon": <ItemIcon src={bunkBedPanel4} />,
                         "boardIcon": bunkBedBoard4
                     }
 
                 ]
             },
             */

            /*
            {
                "key": "dressingTables",
                "title": "Dressing Tables",
                "category": "Bedroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "dressingTable1",
                        "title": "Dressing Table 1",
                        "type": "item",
                        "description": "0.89m x 0.48m x 1.55m (DWH)",
                        "height": 30,
                        "width": 45,
                        "panelIcon": dressingTable1Panel,
                        "icon": <ItemIcon src={dressingTable1Panel} />,
                        "boardIcon": dressingTable1Board
                    },
                    {
                        "key": "dressingTable2",
                        "title": "Dressing Table 2",
                        "type": "item",
                        "description": "0.89m x 0.38m x 1.35m (DWH)",
                        "height": 25,
                        "width": 40,
                        "panelIcon": dressingTable2Panel,
                        "icon": <ItemIcon src={dressingTable2Panel} />,
                        "boardIcon": dressingTable2Board
                    },
                    {
                        "key": "dressingTable3",
                        "title": "Dressing Table 3",
                        "type": "item",
                        "description": "1.09m x 0.53m x 1.40m (DWH)",
                        "height": 22,
                        "width": 45,
                        "panelIcon": dressingTable3Panel,
                        "icon": <ItemIcon src={dressingTable3Panel} />,
                        "boardIcon": dressingTable3Board
                    },
                    {
                        "key": "dressingTable4",
                        "title": "Dressing Table 4",
                        "type": "item",
                        "description": "1.57m x 0.46m x 1.24m (DWH)",
                        "height": 54,
                        "width": 63,
                        "panelIcon": dressingTable4Panel,
                        "icon": <ItemIcon src={dressingTable4Panel} />,
                        "boardIcon": dressingTable4Board
                    }

                ]
            },
            */
            /*
             {
                 "key": "kidsBeds",
                 "title": `Kids' Beds`,
                 "category": "Bedroom",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key": "kidsBeds1",
                         "title": "Kids' Beds 1",
                         "type": "item",
                         "description": "1.27m x 0.69m x 0.81m (DWH)",
                         "height": 68,
                         "width": 40,
                         "panelIcon": kidsBed1Panel,
                         "icon": <ItemIcon src={kidsBed1Panel} />,
                         "boardIcon": kidsBed1Board
                     },
                     {
                         "key": "kidsBeds2",
                         "title": "Kids' Beds 2",
                         "type": "item",
                         "description": "2.01m x 1.14m x 1.80m (DWH)",
                         "height": 45,
                         "width": 90,
                         "panelIcon": kidsBed2Panel,
                         "icon": <ItemIcon src={kidsBed2Panel} />,
                         "boardIcon": kidsBed2Board
                     },
                     {
                         "key": "kidsBeds3",
                         "title": "Kids' Beds 3",
                         "type": "item",
                         "description": "1.80m x 2.46m x 1.45m (DWH)",
                         "height": 87,
                         "width": 65,
                         "panelIcon": kidsBed3Panel,
                         "icon": <ItemIcon src={kidsBed3Panel} />,
                         "boardIcon": kidsBed3Board
                     }
                 ]
             },
             */
            /*
             {
                 "key": "singleBeds",
                 "title": "Single Beds",
                 "category": "Bedroom",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key": "singleBeds1",
                         "title": "Single Bed 1",
                         "type": "item",
                         "description": "1.24m x 2.18m x 0.94m (DWH)",
                         "height": 86,
                         "width": 49,
                         "panelIcon": singleBedPanel1,
                         "icon": <ItemIcon src={singleBedPanel1} />,
                         "boardIcon": singleBedBoard1
                     },
                     {
                         "key": "singleBeds2",
                         "title": "Single Bed 2",
                         "type": "item",
                         "description": "1.35m x 2.01m x 0.94m (DWH)",
                         "height": 79,
                         "width": 54,
                         "panelIcon": singleBedPanel2,
                         "icon": <ItemIcon src={singleBedPanel2} />,
                         "boardIcon": singleBedBoard2
                     },
                     {
                         "key": "singleBeds3",
                         "title": "Single Bed 3",
                         "type": "item",
                         "description": "1.47m x 2.18m x 0.81m (DWH)",
                         "height": 86,
                         "width": 58,
                         "panelIcon": singleBedPanel3,
                         "icon": <ItemIcon src={singleBedPanel3} />,
                         "boardIcon": singleBedBoard3
                     },
                     {
                         "key": "singleBeds4",
                         "title": "Single Bed 4",
                         "type": "item",
                         "description": "1.57m x 2.21m x 0.99m (DWH)",
                         "height": 97,
                         "width": 62,
                         "panelIcon": singleBedPanel4,
                         "icon": <ItemIcon src={singleBedPanel4} />,
                         "boardIcon": singleBedBoard4
                     }
 
                 ]
             },
             */

            /*
            {
                "key": "bar-counter-seats",
                "title": "Bar & Counter Seats",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "barSeat1",
                        "title": "Bar & Counter-Seats 1",
                        "type": "item",
                        "description": "0.56m x 0.56m x 1.07m (DWH)",
                        "height": 24,
                        "width": 24,
                        "panelIcon": barSeat1Panel,
                        "icon": <ItemIcon src={barSeat1Panel} />,
                        "boardIcon": barSeat1Board
                    },
                    {
                        "key": "barSeat2",
                        "title": "Bar & Counter-Seats 1",
                        "type": "item",
                        "description": "0.53m x 0.48m x 0.97m (DWH)",
                        "height": 21,
                        "width": 19,
                        "panelIcon": barSeat2Panel,
                        "icon": <ItemIcon src={barSeat2Panel} />,
                        "boardIcon": barSeat2Board
                    },
                    {
                        "key": "barSeat3",
                        "title": "Bar & Counter-Seats 3",
                        "type": "item",
                        "description": "0.79m x 1.63m x 0.84m (DWH)",
                        "height": 33,
                        "width": 64,
                        "panelIcon": barSeat3Panel,
                        "icon": <ItemIcon src={barSeat3Panel} />,
                        "boardIcon": barSeat3Board
                    },
                    {
                        "key": "barSeat4",
                        "title": "Bar & Counter-Seats 4",
                        "type": "item",
                        "description": "1.88m x 1.68m x 2.13m (DWH)",
                        "height": 64,
                        "width": 76,
                        "panelIcon": barSeat4Panel,
                        "icon": <ItemIcon src={barSeat4Panel} />,
                        "boardIcon": barSeat4Board
                    },
                    {
                        "key": "barSeat5",
                        "title": "Bar & Counter-Seats 5",
                        "type": "item",
                        "description": "0.43m x 0.48m x 0.89m (DWH)",
                        "height": 19,
                        "width": 21,
                        "panelIcon": barSeat5Panel,
                        "icon": <ItemIcon src={barSeat5Panel} />,
                        "boardIcon": barSeat5Board
                    },
                    {
                        "key": "barSeat6",
                        "title": "Bar & Counter-Seats 6",
                        "type": "item",
                        "description": "1.96m x 1.07m x 1.09m (DWH)",
                        "height": 44,
                        "width": 90,
                        "panelIcon": barSeat6Panel,
                        "icon": <ItemIcon src={barSeat6Panel} />,
                        "boardIcon": barSeat6Board
                    }
                ]
            },
            */
            
            /*
            {
                "key": "diningSeats",
                "title": "Dining Seats",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "diningSeat1",
                        "title": "Dining Seat 1",
                        "type": "item",
                        "description": "1.65m x 2.03m x 1.07m (DWH)",
                        "panelIcon": diningSeat1Panel,
                        "icon": <ItemIcon src={diningSeat1Panel} />,
                        "boardIcon": diningSeat1Board,
                        "height": 100,
                        "width": 85
                    },
                    {
                        "key": "diningSeat2",
                        "title": "Dining Seat 2",
                        "type": "item",
                        "description": "1.88m x 0.79m x 0.66m (DWH)",
                        "panelIcon": diningSeat2Panel,
                        "icon": <ItemIcon src={diningSeat2Panel} />,
                        "boardIcon": diningSeat2Board,
                        "height": 42,
                        "width": 90
                    },
                    {
                        "key": "diningSeat3",
                        "title": "Dining Seat 3",
                        "type": "item",
                        "description": "1.96m x 1.93m x 0.94m (DWH)",
                        "panelIcon": diningSeat3Panel,
                        "icon": <ItemIcon src={diningSeat3Panel} />,
                        "boardIcon": diningSeat3Board,
                        "height": 95,
                        "width": 89
                    }
                ]
            },
            */
            

            /*
            {
                "key": "diningRoomTables",
                "title": "Tables",
                "category": "Dining Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "diningTable1",
                        "title": "Table 1",
                        "type": "item",
                        "description": "2.16m x 2.08m x 1.57m (DWH)",
                        "panelIcon": diningTable1Panel,
                        "icon": <ItemIcon src={diningTable1Panel} />,
                        "boardIcon": diningTable1Board,
                        "height": 93,
                        "width": 99
                    },
                    {
                        "key": "diningTable2",
                        "title": "Table 2",
                        "type": "item",
                        "description": "1.60m x 0.79m x 0.76m (DWH)",
                        "panelIcon": diningTable2Panel,
                        "icon": <ItemIcon src={diningTable2Panel} />,
                        "boardIcon": diningTable2Board,
                        "height": 53,
                        "width": 27
                    },
                    {
                        "key": "diningTable3",
                        "title": "Table 3",
                        "type": "item",
                        "description": "1.40m x 1.40m x 0.74m (DWH)",
                        "panelIcon": diningTable3Panel,
                        "icon": <ItemIcon src={diningTable3Panel} />,
                        "boardIcon": diningTable3Board,
                        "height": 47,
                        "width": 47
                    },
                    {
                        "key": "diningTable4",
                        "title": "Table 4",
                        "type": "item",
                        "description": "1.80m x 0.84m x 0.76m (DWH)",
                        "panelIcon": diningTable4Panel,
                        "icon": <ItemIcon src={diningTable4Panel} />,
                        "boardIcon": diningTable4Board,
                        "height": 62,
                        "width": 37
                    },
                    {
                        "key": "diningTable5",
                        "title": "Table 5",
                        "type": "item",
                        "description": "1.40m x 1.50m x 0.97m (DWH)",
                        "panelIcon": diningTable5Panel,
                        "icon": <ItemIcon src={diningTable5Panel} />,
                        "boardIcon": diningTable5Board,
                        "height": 65,
                        "width": 80
                    }

                ]
            },
            */
            /*
             {
                 "key": "officeChairs",
                 "title": "Chairs",
                 "category": "Office",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key": "officeChair1",
                         "title": "Chair 1",
                         "type": "item",
                         "description": "0.71m x 0.71m x 1.07m (DWH)",
                         "icon": <ItemIcon src={officeChair1Panel} />,
                         "boardIcon": officeChair1Board,
                         "height":40,
                         "width":40
                     },
                     {
                         "key": "officeChair2",
                         "title": "Chair 2",
                         "type": "item",
                         "description": "0.61m x 0.71m x 1.02m (DWH)",
                         "icon": <ItemIcon src={officeChair2Panel} />,
                         "boardIcon": officeChair2Board,
                         "height":40,
                         "width":40
                     },
                     {
                         "key": "officeChair3",
                         "title": "Chair 3",
                         "type": "item",
                         "description": "0.71m x 0.71m x 1.24m (DWH)",
                         "icon": <ItemIcon src={officeChair3Panel} />,
                         "boardIcon": officeChair3Board,
                         "height":40,
                         "width":40
                     },
                     {
                         "key": "officeChair4",
                         "title": "Chair 4",
                         "type": "item",
                         "description": "0.58m x 0.58m x 0.81m (DWH)",
                         "icon": <ItemIcon src={officeChair4Panel} />,
                         "boardIcon": officeChair4Board,
                         "height":40,
                         "width":40
                     },
                     {
                         "key": "officeChair5",
                         "title": "Chair 5",
                         "type": "item",
                         "description": "2.57m x 2.54m x 0.81m (DWH)",
                         "icon": <ItemIcon src={officeChair5Panel} />,
                         "boardIcon": officeChair5Board,
                         "height":60,
                         "width":60
                     },
                     {
                         "key": "officeChair6",
                         "title": "Chair 6",
                         "type": "item",
                         "description": "0.74m x 0.74m x 0.79m (DWH)",
                         "icon": <ItemIcon src={officeChair6Panel} />,
                         "boardIcon": officeChair6Board,
                         "height":40,
                         "width":40
                     },
                 ]
             },
             */

            /*
            {
                "key": "officeFileCabinetsStorage",
                "title": "File Cabinets & Storage",
                "category": "Office",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "officeFileCS1",
                        "title": "File Cabinets & Storage 1",
                        "type": "item",
                        "description": "1.65m x 0.58m x 2.16m (DWH)",
                        "height": 40,
                        "width": 90,
                        "panelIcon": officeFileCabinet1Panel,
                        "icon": <ItemIcon src={officeFileCabinet1Panel} />,
                        "boardIcon": officeFileCabinet1Board
                    },
                    {
                        "key": "officeFileCS2",
                        "title": "File Cabinets & Storage 2",
                        "type": "item",
                        "description": "0.79m x 0.38m x 0.76m (DWH)",
                        "height": 25,
                        "width": 60,
                        "panelIcon": officeFileCabinet2Panel,
                        "icon": <ItemIcon src={officeFileCabinet2Panel} />,
                        "boardIcon": officeFileCabinet2Board
                    },
                    {
                        "key": "officeFileCS3",
                        "title": "File Cabinets & Storage 3",
                        "type": "item",
                        "description": "2.01m x 0.41m x 1.30m (DWH)",
                        "height": 29,
                        "width": 89,
                        "panelIcon": officeFileCabinet3Panel,
                        "icon": <ItemIcon src={officeFileCabinet3Panel} />,
                        "boardIcon": officeFileCabinet3Board
                    },
                    {
                        "key": "officeFileCS4",
                        "title": "File Cabinets & Storage 4",
                        "type": "item",
                        "description": "0.79m x 0.43m x 1.19m (DWH)",
                        "height": 30,
                        "width": 30,
                        "panelIcon": officeFileCabinet4Panel,
                        "icon": <ItemIcon src={officeFileCabinet4Panel} />,
                        "boardIcon": officeFileCabinet4Board
                    },
                    {
                        "key": "meetingRoomStorage1",
                        "title": "Meeting Room Storage 1",
                        "type": "item",
                        "description": "1.65m x 0.58m x 2.16m (DWH)",
                        "icon": <ItemIcon src={storagePanel1} />,
                        "panelIcon": storagePanel1,
                        "boardIcon": storageBoard1,
                        "height": 30,
                        "width": 45
                    },
                    {
                        "key": "meetingRoomStorage2",
                        "title": "Meeting Room Storage 2",
                        "type": "item",
                        "description": "0.79m x 0.38m x 0.76m (DWH)",
                        "icon": <ItemIcon src={storagePanel2} />,
                        "panelIcon": storagePanel2,
                        "boardIcon": storageBoard2,
                        "height": 25,
                        "width": 25
                    },
                    {
                        "key": "meetingRoomStorage3",
                        "title": "Meeting Room Storage 3",
                        "type": "item",
                        "description": "2.01m x 0.41m x 1.30m (DWH)",
                        "icon": <ItemIcon src={storagePanel3} />,
                        "panelIcon": storagePanel3,
                        "boardIcon": storageBoard3,
                        "height": 35,
                        "width": 65
                    },
                    {
                        "key": "meetingRoomStorage4",
                        "title": "Meeting Room Storage 4",
                        "type": "item",
                        "description": "0.79m x 0.43m x 1.19m (DWH)",
                        "icon": <ItemIcon src={storagePanel4} />,
                        "panelIcon": storagePanel4,
                        "boardIcon": storageBoard4,
                        "height": 25,
                        "width": 30
                    }
                ]
            },
            */
            /*
            {
                "key": "officeAccessories",
                "title": "Accessories",
                "category": "Office",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "officeAcc1",
                        "title": "Accessory 1",
                        "type": "item",
                        "description": "0.36m x 0.33m x 0.18m (DWH)",
                        "height": 14,
                        "width": 13,
                        "panelIcon": officeAccessory1Panel,
                        "icon": <ItemIcon src={officeAccessory1Panel} />,
                        "boardIcon": officeAccessory1Board
                    },
                    {
                        "key": "officeAcc2",
                        "title": "Accessory 2",
                        "type": "item",
                        "description": "0.28m x 0.13m x 0.20m (DWH)",
                        "height": 11,
                        "width": 5,
                        "panelIcon": officeAccessory2Panel,
                        "icon": <ItemIcon src={officeAccessory2Panel} />,
                        "boardIcon": officeAccessory2Board
                    },
                    {
                        "key": "officeAcc3",
                        "title": "Accessory 3",
                        "type": "item",
                        "description": "0.28m x 0.51m x 0.30m (DWH)",
                        "height": 7,
                        "width": 11,
                        "panelIcon": officeAccessory3Panel,
                        "icon": <ItemIcon src={officeAccessory3Panel} />,
                        "boardIcon": officeAccessory3Board
                    },
                    {
                        "key": "officeAcc4",
                        "title": "Accessory 4",
                        "type": "item",
                        "description": "0.30m x 0.25m x 0.23m (DWH)",
                        "height": 12,
                        "width": 16,
                        "rotation":0,
                        "panelIcon": officeAccessory4Panel,
                        "icon": <ItemIcon src={officeAccessory4Panel} />,
                        "boardIcon": officeAccessory4Board
                    }

                ]
            },
            */
            /*
            {
                "key": "officeShelvesBookCases",
                "title": "Shelves & Bookcases",
                "category": "Office",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "officeshelf1",
                        "title": "Shelves & Bookcases 1",
                        "type": "item",
                        "description": "5'11\" x 1'3\" x 1'3\"",
                        "height": 20,
                        "width": 71,
                        "panelIcon": officeBookcase1Panel,
                        "icon": <ItemIcon src={officeBookcase1Panel} />,
                        "boardIcon": officeBookcase1Board
                    },
                    {
                        "key": "officeshelf2",
                        "title": "Shelves & Bookcases 2",
                        "type": "item",
                        "description": "2'2\" x 0'8\" x 7'11\"",
                        "height": 15,
                        "width": 32,
                        "panelIcon": officeBookcase2Panel,
                        "icon": <ItemIcon src={officeBookcase2Panel} />,
                        "boardIcon": officeBookcase2Board
                    },
                    {
                        "key": "officeshelf3",
                        "title": "Shelves & Bookcases 3",
                        "type": "item",
                        "description": "6'0\" x 2'2\" x 6'4\"",
                        "height": 30,
                        "width": 72,
                        "panelIcon": officeBookcase3Panel,
                        "icon": <ItemIcon src={officeBookcase3Panel} />,
                        "boardIcon": officeBookcase3Board
                    },
                    {
                        "key": "officeshelf4",
                        "title": "Shelves & Bookcases 4",
                        "type": "item",
                        "description": "4'0\" x 0'10\" x 6'0\"",
                        "height": 15,
                        "width": 35,
                        "panelIcon": officeBookcase4Panel,
                        "icon": <ItemIcon src={officeBookcase4Panel} />,
                        "boardIcon": officeBookcase4Board
                    },
                    {
                        "key": "officeshelf5",
                        "title": "Shelves & Bookcases 5",
                        "type": "item",
                        "description": "1'3\" x 1'3\" x 3'3\"",
                        "height": 20,
                        "width": 20,
                        "panelIcon": officeBookcase5Panel,
                        "icon": <ItemIcon src={officeBookcase5Panel} />,
                        "boardIcon": officeBookcase5Board
                    },
                    {
                        "key": "officeshelf6",
                        "title": "Shelves & Bookcases 6",
                        "type": "item",
                        "description": "0'11\" x 0'3\" x 0'10\"",
                        "height": 11,
                        "width": 10,
                        "panelIcon": officeBookcase6Panel,
                        "icon": <ItemIcon src={officeBookcase6Panel} />,
                        "boardIcon": officeBookcase6Board
                    }
                ]
            }, 
            /*
            {
                "key": "cabinetUnit",
                "title": "Cabinet Units",
                "category": "Kitchen",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Cabinet Unit 1",
                        "icon": <ItemIcon src={kitchenCabinetUnit1Panel} />,
                        "panelIcon": kitchenCabinetUnit1Panel,
                        "boardIcon": kitchenCabinetUnit1Board,
                        "type": "item",
                        "description": "1.50m x 0.56m x 0.46m (DWH)",
                        "height": 30,
                        "width": 56
                    },
                    {
                        "title": "Cabinet Unit 2",
                        "icon": <ItemIcon src={kitchenCabinetUnit2Panel} />,
                        "panelIcon": kitchenCabinetUnit2Panel,
                        "boardIcon": kitchenCabinetUnit2Board,
                        "type": "item",
                        "description": "3.45m x 1.70m x 0.89m (DWH)",
                        "height": 67,
                        "width": 135
                    }

                ]
            },
            */

            /**
             * Meeting Room
             */
            /*
            {
                "key": "officeAccessory",
                "title": "Accessories",
                "category": "Meeting Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "officeAccessory1",
                        "title": "Office Accessories 1",
                        "type": "item",
                        "description": "0.36m x 0.33m x 0.18m (DWH)",
                        "icon": <ItemIcon src={officeAccessoryPanel1} />,
                        "panelIcon": officeAccessoryPanel1,
                        "boardIcon": officeAccessoryBoard1,
                        "height": 15,
                        "width": 15
                    },
                    {
                        "key": "officeAccessory2",
                        "title": "Office Accessories 2",
                        "type": "item",
                        "description": "0.30m x 0.25m x 0.23m (DWH)",
                        "icon": <ItemIcon src={officeAccessoryPanel2} />,
                        "panelIcon": officeAccessoryPanel2,
                        "boardIcon": officeAccessoryBoard2,
                        "height": 14,
                        "width": 14
                    },
                    /*
                    {
                        "key": "officeAccessory3",
                        "title": "Office Accessories 3",
                        "type": "item",
                        "description": "3654 x 110 x 2141 mm",
                        "icon": <ItemIcon src={officeAccessoryPanel3} />,
                        "panelIcon": officeAccessoryPanel3,
                        "boardIcon": officeAccessoryBoard3,
                        "height": 14,
                        "width": 36
                    }
                    
                ]
            },*/
            /*
            {
                "key": "chair",
                "title": "Chairs",
                "category": "Meeting Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "chair1",
                        "title": "Chair 1",
                        "type": "item",
                        "description": "0.71m x 0.71m x 1.07m (DWH)",
                        "icon": <ItemIcon src={chairPanel1} />,
                        "panelIcon": chairPanel1,
                        "boardIcon": chairBoard1,
                        "height": 20,
                        "width": 20
                    },
                    {
                        "key": "chair2",
                        "title": "Chair 2",
                        "type": "item",
                        "description": "0.61m x 0.71m x 1.02m (DWH)",
                        "icon": <ItemIcon src={chairPanel2} />,
                        "panelIcon": chairPanel2,
                        "boardIcon": chairBoard2,
                        "height": 20,
                        "width": 20
                    },
                    {
                        "key": "chair3",
                        "title": "Chair 3",
                        "type": "item",
                        "description": "0.71m x 0.71m x 1.24m (DWH)",
                        "icon": <ItemIcon src={chairPanel3} />,
                        "panelIcon": chairPanel3,
                        "boardIcon": chairBoard3,
                        "height": 25,
                        "width": 25
                    },
                    {
                        "key": "chair4",
                        "title": "Chair 4",
                        "type": "item",
                        "description": "0.58m x 0.58m x 0.81m (DWH)",
                        "icon": <ItemIcon src={chairPanel4} />,
                        "panelIcon": chairPanel4,
                        "boardIcon": chairBoard4,
                        "height": 15,
                        "width": 15
                    },
                    {
                        "key": "chair5",
                        "title": "Chair 5",
                        "type": "item",
                        "description": "2.57m x 2.54m x 0.81m (DWH)",
                        "icon": <ItemIcon src={chairPanel5} />,
                        "panelIcon": chairPanel5,
                        "boardIcon": chairBoard5,
                        "height": 50,
                        "width": 50
                    },
                    {
                        "key": "chair6",
                        "title": "Chair 6",
                        "type": "item",
                        "description": "0.74m x 0.74m x 0.79m (DWH)",
                        "icon": <ItemIcon src={chairPanel6} />,
                        "panelIcon": chairPanel6,
                        "boardIcon": chairBoard6,
                        "height": 20,
                        "width": 20
                    }

                ]
            },
            */
            /*
             {
                 "key": "storage",
                 "title": "File Cabinets & Storage",
                 "category": "Meeting Room",
                 "icon": <FurnitureIcon />,
                 "type": "menu",
                 "children": [
                     {
                         "key": "storage1",
                         "title": "Storage 1",
                         "type": "item",
                         "description": "1.65m x 0.58m x 2.16m (DWH)",
                         "icon": <ItemIcon src={storagePanel1} />,
                         "panelIcon": storagePanel1,
                         "boardIcon": storageBoard1,
                         "height": 30,
                         "width": 45
                     },
                     {
                         "key": "storage2",
                         "title": "Storage 2",
                         "type": "item",
                         "description": "0.79m x 0.38m x 0.76m (DWH)",
                         "icon": <ItemIcon src={storagePanel2} />,
                         "panelIcon": storagePanel2,
                         "boardIcon": storageBoard2,
                         "height": 25,
                         "width": 25
                     },
                     {
                         "key": "storage3",
                         "title": "Storage 3",
                         "type": "item",
                         "description": "2.01m x 0.41m x 1.30m (DWH)",
                         "icon": <ItemIcon src={storagePanel3} />,
                         "panelIcon": storagePanel3,
                         "boardIcon": storageBoard3,
                         "height": 35,
                         "width": 65
                     },
                     {
                         "key": "storage4",
                         "title": "Storage 4",
                         "type": "item",
                         "description": "0.79m x 0.43m x 1.19m (DWH)",
                         "icon": <ItemIcon src={storagePanel4} />,
                         "panelIcon": storagePanel4,
                         "boardIcon": storageBoard4,
                         "height": 25,
                         "width": 30
                     }
                 ]
             },
             */
            /*
            {
                "key": "table",
                "title": "Tables",
                "category": "Meeting Room",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "meetingRoomtable1",
                        "title": "Table 1",
                        "type": "item",
                        "description": "Description of Table 1",
                        "icon": <ItemIcon src={tablePanel1} />,
                        "panelIcon": tablePanel1,
                        "boardIcon": tableBoard1,
                        "height": 125,
                        "width": 90
                    },
                    {
                        "key": "meetingRoomtable2",
                        "title": "Table 2",
                        "type": "item",
                        "description": "Description of Table 2",
                        "icon": <ItemIcon src={tablePanel2} />,
                        "panelIcon": tablePanel2,
                        "boardIcon": tableBoard2,
                        "height": 55,
                        "width": 35
                    },
                    {
                        "key": "meetingRoomtable3",
                        "title": "Table 3",
                        "type": "item",
                        "description": "Description of Table 3",
                        "icon": <ItemIcon src={tablePanel3} />,
                        "panelIcon": tablePanel3,
                        "boardIcon": tableBoard3,
                        "height": 74,
                        "width": 140
                    },
                    {
                        "key": "meetingRoomtable4",
                        "title": "Table 4",
                        "type": "item",
                        "description": "Description of Table 4",
                        "icon": <ItemIcon src={tablePanel4} />,
                        "panelIcon": tablePanel4,
                        "boardIcon": tableBoard4,
                        "height": 43,
                        "width": 104
                    },
                    {
                        "key": "meetingRoomtable5",
                        "title": "Table 5",
                        "type": "item",
                        "description": "Description of Table 5",
                        "icon": <ItemIcon src={tablePanel5} />,
                        "panelIcon": tablePanel5,
                        "boardIcon": tableBoard5,
                        "height": 100,
                        "width": 215
                    },
                    {
                        "key": "meetingRoomtable6",
                        "title": "Table 6",
                        "type": "item",
                        "description": "Description of Table 6",
                        "icon": <ItemIcon src={tablePanel6} />,
                        "panelIcon": tablePanel6,
                        "boardIcon": tableBoard6,
                        "height": 117,
                        "width": 185
                    },
                    {
                        "key": "meetingRoomtable7",
                        "title": "Table 7",
                        "type": "item",
                        "description": "Description of Table 7",
                        "icon": <ItemIcon src={tablePanel7} />,
                        "panelIcon": tablePanel7,
                        "boardIcon": tableBoard7,
                        "height": 41,
                        "width": 85
                    },
                    {
                        "key": "meetingRoomtable8",
                        "title": "Table 8",
                        "type": "item",
                        "description": "Description of Table 8",
                        "icon": <ItemIcon src={tablePanel8} />,
                        "panelIcon": tablePanel8,
                        "boardIcon": tableBoard8,
                        "height": 70,
                        "width": 70
                    },
                    {
                        "key": "meetingRoomtable9",
                        "title": "Table 9",
                        "type": "item",
                        "description": "Description of Table 9",
                        "icon": <ItemIcon src={tablePanel9} />,
                        "panelIcon": tablePanel9,
                        "boardIcon": tableBoard9,
                        "height": 94,
                        "width": 110
                    }

                ]
            },
            */

            /**
             * Bathroom
             */
            /*
            {
                "key": "bathroomAccessories",
                "title": "Bathroom Accessories",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "towelRack1",
                        "title": "Towel Rack 1",
                        "type": "item",
                        "description": "0.48m x 0.08m x 0.41m (DWH)",
                        "icon": <ItemIcon src={towelRackPanel} />,
                        "panelIcon": towelRackPanel,
                        "boardIcon": towelRackBoard,
                        "height": 3,
                        "width": 20
                    },
                    {
                        "key": "bathroomTowelRack1",
                        "title": "Bathroom Towel Rack 1",
                        "type": "item",
                        "description": "0.10m x 0.13m x 0.33m (DWH)",
                        "icon": <ItemIcon src={bathroomTowelRackPanel} />,
                        "panelIcon": bathroomTowelRackPanel,
                        "boardIcon": bathroomTowelRackBoard,
                        "height": 13,
                        "width": 14
                    },

                    {
                        "key": "toiletPaper1",
                        "title": "Toilet Paper 1",
                        "type": "item",
                        "description": "0.13m x 0.10m x 0.20m (DWH)",
                        "icon": <ItemIcon src={toiletPaperPanel} />,
                        "panelIcon": toiletPaperPanel,
                        "boardIcon": toiletPaperBoard,
                        "height": 5,
                        "width": 10
                    },
                    {
                        "key": "shower1",
                        "title": "Shower 1",
                        "type": "item",
                        "description": "0.33m x 0.41m x 1.27m (DWH)",
                        "icon": <ItemIcon src={showerPanel} />,
                        "panelIcon": showerPanel,
                        "boardIcon": showerBoard,
                        "height": 17,
                        "width": 15
                    },
                    {
                        "key": "shower2",
                        "title": "Shower 2",
                        "type": "item",
                        "description": "0.41m x 0.56m x 1.57m (DWH)",
                        "icon": <ItemIcon src={shower2Panel} />,
                        "panelIcon": shower2Panel,
                        "boardIcon": shower2Board,
                        "height": 22,
                        "width": 15
                    },
                    {
                        "key": "bathroomAccessories1",
                        "title": "Bathroom Accessories 1",
                        "type": "item",
                        "description": "0.10m x 0.10m x 0.00m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory1Panel} />,
                        "panelIcon": bathroomAccessory1Panel,
                        "boardIcon": bathroomAccessory1Board,
                        "height": 8,
                        "width": 8
                    },
                    {
                        "key": "bathroomAccessories2",
                        "title": "Bathroom Accessories 2",
                        "type": "item",
                        "description": "0.15m x 0.08m x 0.15m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory2Panel} />,
                        "panelIcon": bathroomAccessory2Board,
                        "boardIcon": bathroomAccessory2Board,
                        "height": 3,
                        "width": 5
                    },
                    {
                        "key": "bathroomAccessories3",
                        "title": "Bathroom Accessory 3",
                        "type": "item",
                        "description": "0.20m x 0.20m x 0.18m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory3Panel} />,
                        "panelIcon": bathroomAccessory3Panel,
                        "boardIcon": bathroomAccessory3Board,
                        "height": 13,
                        "width": 15
                    },
                    {
                        "key": "bathroomAccessories4",
                        "title": "Bathroom Accessory 4",
                        "type": "item",
                        "description": "0.28m x 0.38m x 0.20m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory4Panel} />,
                        "panelIcon": bathroomAccessory4Panel,
                        "boardIcon": bathroomAccessory4Board,
                        "height": 8,
                        "width": 5
                    },

                    {
                        "key": "bathroomAccessories5",
                        "title": "Bathroom Accessories 5",
                        "type": "item",
                        "description": "0.33m x 0.20m x 0.20m (DWH)",
                        "icon": <ItemIcon src={bathroomAccessory5Panel} />,
                        "panelIcon": bathroomAccessory5Board,
                        "boardIcon": bathroomAccessory5Board,
                        "height": 8,
                        "width": 11
                    },
                ]
            },
            */
            /*
            {
                "key": "bathroomMirrors",
                "title": "Mirrors",
                "category": "Bathroom",
                "icon": <FurnitureIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "mirror1",
                        "title": "Mirror 1",
                        "type": "item",
                        "description": "0.81m x 0.13m x 0.71m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror1Panel} />,
                        "panelIcon": bathroomMirror1Panel,
                        "boardIcon": bathroomMirror1Board,
                        "height": 7,
                        "width": 40
                    },
                    {
                        "key": "mirror2",
                        "title": "Mirror 2",
                        "type": "item",
                        "description": "0.66m x 0.13m x 0.76m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror2Panel} />,
                        "panelIcon": bathroomMirror2Panel,
                        "boardIcon": bathroomMirror2Board,
                        "height": 8,
                        "width": 42
                    },
                    {
                        "key": "mirror3",
                        "title": "Mirror 3",
                        "type": "item",
                        "description": "0.71m x 0.03m x 0.51m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror3Panel} />,
                        "panelIcon": bathroomMirror3Panel,
                        "boardIcon": bathroomMirror3Board,
                        "height": 2,
                        "width": 116
                    },
                    {
                        "key": "mirror4",
                        "title": "Mirror 4",
                        "type": "item",
                        "description": "0.69m x 0.05m x 0.69m (DWH)",
                        "icon": <ItemIcon src={bathroomMirror4Panel} />,
                        "panelIcon": bathroomMirror4Panel,
                        "boardIcon": bathroomMirror4Board,
                        "height": 3,
                        "width": 25
                    }
                ]
            },
            */


        ]
    },
    {
        "key": "doors",
        "title": "Doors",
        "icon": <ConstructionIcon />,
        "type": "menu",
        "children": [
            {
                "key": "door1",
                "title": "Door 1",
                "type": "item",
                "description": "0.74m x 0.20m x 2.03m (DWH)",
                "icon": <ItemIcon src={door1Panel} />,
                "panelIcon": door1Panel,
                "boardIcon": singleDoor1Board,
                "height": 60,
                "width": 60,
                "zIndex":1
            },
            {
                "key": "door2",
                "title": "Door 2",
                "type": "item",
                "description": "0.74m x 0.20m x 2.03m (DWH)",
                "icon": <ItemIcon src={door2Panel} />,
                "panelIcon": door2Panel,
                "boardIcon": singleDoor2Board,
                "height": 60,
                "width": 60,
                "zIndex":1
            },
            {
                "key": "door3",
                "title": "Door 3",
                "type": "item",
                "description": "0.74m x 0.20m x 2.03m (DWH)",
                "icon": <ItemIcon src={door3Panel} />,
                "panelIcon": door3Panel,
                "boardIcon": doubleDoorBoard,
                "height": 55,
                "width": 100,
                "zIndex":1
            },
            {
                "key": "door4",
                "title": "Dutch Door",
                "type": "item",
                "description": "0.74m x 0.20m x 2.03m (DWH)",
                "icon": <ItemIcon src={door4Panel} />,
                "panelIcon": door4Panel,
                "boardIcon": dutchDoorBoard,
                "height": 60,
                "width": 60,
                "zIndex":1
            }

        ]
    },
    {
        "key": "windows",
        "title": "Windows",
        "icon": <WindowIcon />,
        "type": "menu",
        "children": [
            {
                "key": "window1",
                "title": "Window 1",
                "type": "item",
                "description": "1.50m x 0.15m x 2.21m (DWH)",
                "icon": <ItemIcon src={window1Panel} />,
                "panelIcon": window1Panel,
                "boardIcon": window1Board,
                "height": 20,
                "width": 40,
                "zIndex":1
            },
            {
                "key": "window2",
                "title": "Window 2",
                "type": "item",
                "description": "1.83m x 0.15m x 1.42m (DWH)",
                "icon": <ItemIcon src={window2Panel} />,
                "panelIcon": window2Panel,
                "boardIcon": window2Board,
                "height": 20,
                "width": 60,
                "zIndex":1
            },
            {
                "key": "window3",
                "title": "Window 3",
                "type": "item",
                "description": "2.03m x 0.15m x 1.42m (DWH)",
                "icon": <ItemIcon src={window3Panel} />,
                "panelIcon": window3Panel,
                "boardIcon": window3Board,
                "height": 20,
                "width": 68,
                "zIndex":1
            },
            {
                "key": "window4",
                "title": "Window 4",
                "type": "item",
                "description": "2.03m x 0.13m x 1.65m (DWH)",
                "icon": <ItemIcon src={window4Panel} />,
                "panelIcon": window4Panel,
                "boardIcon": window4Board,
                "height": 20,
                "width": 68,
                "zIndex":1
            }

        ]
    },
    /* Kitchen */
    {
        "key": "kitchen",
        "title": "Kitchen",
        "icon": <KitchenIconItemMenu/>,
        "type": "menu",
        "children": [
            {
                "key":"cabinet1",
                "title": "Cabinet 1",
                "icon": <ItemIcon src={kitchenAvenuePiece2Panel} />,
                "panelIcon": kitchenAvenuePiece2Panel,
                "boardIcon": kitchenAvenuePiece2Board,
                "type": "item",
                "description": "1.07m x 0.66m x 0.91m (DWH)",
                "height": 42,
                "width": 60,
                "zIndex":2
            },
            {
                "key":"cabinetSet1",
                "title": "Kitchen Set 4",
                "icon": <ItemIcon src={kitchencabinetSet1Panel} />,
                "panelIcon": kitchencabinetSet1Panel,
                "boardIcon": kitchencabinetSet1Board,
                "type": "item",
                "description": "",
                "height": 100,
                "width": 135,
                "zIndex":2
            },
            {
                "key":"kitchenIsland1",
                "title": "Kitchen Island",
                "icon": <ItemIcon src={kitchenIsland1Panel} />,
                "panelIcon": kitchenIsland1Panel,
                "boardIcon": kitchenIsland1Board,
                "type": "item",
                "description": "3.05m x 0.94m x 0.89m (DWH)",
                "height": 40,
                "width": 129,
                "zIndex":2
            },
            {
                "key":"kitchenSet1",
                "title": "Cabinet 2",
                "icon": <ItemIcon src={kitchenSet1Panel} />,
                "panelIcon": kitchenSet1Panel,
                "boardIcon": kitchenSet1Board,
                "type": "item",
                "description": "1.07m x 0.66m x 0.91m (DWH)",
                "height": 42,
                "width": 65,
                "zIndex":2
            },
            {
                "key":"kitchenSet2",
                "title": "Kitchen Set 1",
                "icon": <ItemIcon src={kitchenSet2Panel} />,
                "panelIcon": kitchenSet2Panel,
                "boardIcon": kitchenSet2Board,
                "type": "item",
                "description": "3.96m x 0.56m x 2.79m (DWH)",
                "height": 40,
                "width": 250,
                "zIndex":2
            },
            {
                "key":"kitchenSet3",
                "title": "Kitchen Set 2",
                "icon": <ItemIcon src={kitchenSet3Panel} />,
                "panelIcon": kitchenSet3Panel,
                "boardIcon": kitchenSet3Board,
                "type": "item",
                "description": "3.68m x 2.16m x 2.49m (DWH)",
                "height": 110,
                "width": 215,
                "zIndex":2
            },
            {
                "key":"kitchenSet4",
                "title": "Kitchen Set 3",
                "icon": <ItemIcon src={kitchenSet4Panel} />,
                "panelIcon": kitchenSet4Panel,
                "boardIcon": kitchenSet4Board,
                "type": "item",
                "description": "3.68m x 2.16m x 2.49m (DWH)",
                "height": 110,
                "width": 215,
                "zIndex":2
            },
            {
                "key": "foodUtensil1",
                "title": "Meal 1",
                "type": "item",
                "description": "0.25m x 0.38m x 0.08m (DWH)",
                "panelIcon": foodUtensil1Panel,
                "icon": <ItemIcon src={foodUtensil1Panel} />,
                "boardIcon": foodUtensil1Board,
                "height": 15,
                "width": 10,
                "zIndex":5
            },
            {
                "key": "foodUtensil2",
                "title": "Meal 2",
                "type": "item",
                "description": "0.30m x 0.28m x 0.13m (DWH)",
                "panelIcon": foodUtensil2Panel,
                "icon": <ItemIcon src={foodUtensil2Panel} />,
                "boardIcon": foodUtensil2Board,
                "height": 11,
                "width": 12,
                "zIndex":5
            },
            {
                "key": "foodUtensil3",
                "title": "Plates",
                "type": "item",
                "description": "0.43m x 0.38m x 0.13m (DWH)",
                "panelIcon": foodUtensil3Panel,
                "icon": <ItemIcon src={foodUtensil3Panel} />,
                "boardIcon": foodUtensil3Board,
                "height": 9,
                "width": 9,
                "zIndex":5
            },
            {
                "title": "Cooktop 1",
                "icon": <ItemIcon src={kitchenCooktops1Panel} />,
                "panelIcon": kitchenCooktops1Panel,
                "boardIcon": kitchenCooktops1Board,
                "type": "item",
                "description": "0.76m x 0.46m x 0.13m (DWH)",
                "height": 25,
                "width": 50,
                "zIndex":3
            },
            {
                "title": "Cooktop 2",
                "icon": <ItemIcon src={kitchenCooktops2Panel} />,
                "panelIcon": kitchenCooktops2Panel,
                "boardIcon": kitchenCooktops2Board,
                "type": "item",
                "description": "0.86m x 0.51m x 0.10m (DWH)",
                "height": 25,
                "width": 40,
                "zIndex":3
            },
            {
                "title": "Wall Hood 1",
                "icon": <ItemIcon src={kitchenWallHood1Panel} />,
                "panelIcon": kitchenWallHood1Panel,
                "boardIcon": kitchenWallHood1Board,
                "type": "item",
                "description": "0.91m x 0.43m x 0.33m (DWH)",
                "height": 17,
                "width": 36,
                "zIndex":4
            },
            {
                "title": "Wall Hood 2",
                "icon": <ItemIcon src={kitchenWallHood2Panel} />,
                "panelIcon": kitchenWallHood2Panel,
                "boardIcon": kitchenWallHood2Board,
                "type": "item",
                "description": "0.89m x 0.56m x 0.89m (DWH)",
                "height": 22,
                "width": 36,
                "zIndex":4
            },
            {
                "key":"kitchenSink1",
                "title": "Sink 1",
                "icon": <ItemIcon src={kitchenSink1Panel} />,
                "panelIcon": kitchenSink1Panel,
                "boardIcon": kitchenSink1Board,
                "type": "item",
                "description": "0.79m x 0.43m x 0.46m (DWH)",
                "height": 20,
                "width": 40,
                "zIndex":2
            },
            /*
            {
                "title": "Kitchen Sink 2",
                "icon": <ItemIcon src={kitchenSink2Panel} />,
                "panelIcon": kitchenSink2Panel,
                "boardIcon": kitchenSink2Board,
                "type": "item",
                "description": "0.61m x 0.48m x 1.45m (DWH)",
                "height": 20,
                "width": 25
            },
            */
            {
                "key":"kitchenSink2",
                "title": "Sink 2",
                "icon": <ItemIcon src={kitchenSink3Panel} />,
                "panelIcon": kitchenSink3Panel,
                "boardIcon": kitchenSink3Board,
                "type": "item",
                "description": "0.53m x 0.51m x 0.46m (DWH)",
                "height": 20,
                "width": 20,
                "zIndex":2
            },
            {
                "key":"dishDrainer",
                "title": "Dish Drainer",
                "icon": <ItemIcon src={kitchenUtensils1Panel} />,
                "panelIcon": kitchenUtensils1Panel,
                "boardIcon": kitchenUtensils1Board,
                "type": "item",
                "description": "0.58m x 0.28m x 0.43m (DWH)",
                "height": 11,
                "width": 23,
                "zIndex":2
            },
            {
                "key":"cupset",
                "title": "Cup Set",
                "icon": <ItemIcon src={kitchenUtensils2Panel} />,
                "panelIcon": kitchenUtensils2Panel,
                "boardIcon": kitchenUtensils2Board,
                "type": "item",
                "description": "0.43m x 0.33m x 0.10m (DWH)",
                "height": 13,
                "width": 14,
                "zIndex":5
            },
            {
                "key":"bin",
                "title": "Garbage Can",
                "icon": <ItemIcon src={kitchenUtensils3Panel} />,
                "panelIcon": kitchenUtensils3Panel,
                "boardIcon": kitchenUtensils3Board,
                "type": "item",
                "description": "0.15m x 0.25m x 0.33m (DWH)",
                "height": 15,
                "width": 10,
                "zIndex":4
            }
        ]
    },
    /* Bathroom */
    {
        "key": "bathroom",
        "title": "Bathroom",
        "icon": <BathroomIcon />,
        "type": "menu",
        "children": [
            {
                "key": "paperTowel1",
                "title": "Paper Towel Holder 1",
                "type": "item",
                "description": "0.48m x 0.08m x 0.41m (DWH)",
                "icon": <ItemIcon src={paperTowelHolder1Panel} />,
                "panelIcon": paperTowelHolder1Panel,
                "boardIcon": paperTowelHolder1Board,
                "height": 5,
                "width": 5,
                "zIndex":5
            },
            {
                "key": "paperTowel2",
                "title": "Paper Towel Holder 2",
                "type": "item",
                "description": "0.255m x 0.132m x 0.193m (DWH)",
                "icon": <ItemIcon src={paperTowelHolder2Panel} />,
                "panelIcon": paperTowelHolder2Panel,
                "boardIcon": paperTowelHolder2Board,
                "height": 5,
                "width": 5,
                "zIndex":5
            },
            {
                "key": "towelRack1",
                "title": "Towel Rack",
                "type": "item",
                "description": "0.160m x 0.159m x 0.286m (DWH)",
                "icon": <ItemIcon src={towelRackPanel} />,
                "panelIcon": towelRackPanel,
                "boardIcon": towelRackBoard,
                "height": 3,
                "width": 20,
                "zIndex":5
            },
            {
                "key": "bathroomTowelRack1",
                "title": "Toilet Brush",
                "type": "item",
                "description": "0.10m x 0.13m x 0.33m (DWH)",
                "icon": <ItemIcon src={bathroomTowelRackPanel} />,
                "panelIcon": bathroomTowelRackPanel,
                "boardIcon": bathroomTowelRackBoard,
                "height": 13,
                "width": 14,
                "zIndex":5
            },

            {
                "key": "toiletPaper1",
                "title": "Toilet Paper",
                "type": "item",
                "description": "0.13m x 0.10m x 0.20m (DWH)",
                "icon": <ItemIcon src={toiletPaperPanel} />,
                "panelIcon": toiletPaperPanel,
                "boardIcon": toiletPaperBoard,
                "height": 5,
                "width": 10,
                "zIndex":5
            },
            {
                "key": "shower1",
                "title": "Shower 1",
                "type": "item",
                "description": "0.33m x 0.41m x 1.27m (DWH)",
                "icon": <ItemIcon src={showerPanel} />,
                "panelIcon": showerPanel,
                "boardIcon": showerBoard,
                "height": 17,
                "width": 15,
                "zIndex":2
            },
            {
                "key": "shower2",
                "title": "Shower 2",
                "type": "item",
                "description": "0.41m x 0.56m x 1.57m (DWH)",
                "icon": <ItemIcon src={shower2Panel} />,
                "panelIcon": shower2Panel,
                "boardIcon": shower2Board,
                "height": 22,
                "width": 15,
                "zIndex":2
            },
            {
                "key": "bathroomAccessories1",
                "title": "Drain Plug",
                "type": "item",
                "description": "0.10m x 0.10m x 0.00m (DWH)",
                "icon": <ItemIcon src={bathroomAccessory1Panel} />,
                "panelIcon": bathroomAccessory1Panel,
                "boardIcon": bathroomAccessory1Board,
                "height": 8,
                "width": 8,
                "zIndex":5
            },
            {
                "key": "bathroomAccessories2",
                "title": "Soap Dish",
                "type": "item",
                "description": "0.15m x 0.08m x 0.15m (DWH)",
                "icon": <ItemIcon src={bathroomAccessory2Panel} />,
                "panelIcon": bathroomAccessory2Board,
                "boardIcon": bathroomAccessory2Board,
                "height": 3,
                "width": 5,
                "zIndex":5
            },
            {
                "key": "bathroomAccessories3",
                "title": "Tooth Paste Set",
                "type": "item",
                "description": "0.20m x 0.20m x 0.18m (DWH)",
                "icon": <ItemIcon src={bathroomAccessory3Panel} />,
                "panelIcon": bathroomAccessory3Panel,
                "boardIcon": bathroomAccessory3Board,
                "height": 13,
                "width": 15,
                "zIndex":5
            },
            {
                "key": "bathroomAccessories4",
                "title": "Towels",
                "type": "item",
                "description": "0.28m x 0.38m x 0.20m (DWH)",
                "icon": <ItemIcon src={bathroomAccessory4Panel} />,
                "panelIcon": bathroomAccessory4Panel,
                "boardIcon": bathroomAccessory4Board,
                "height": 8,
                "width": 5,
                "zIndex":5
            },
            {
                "key": "tub2",
                "title": "Sink 1",
                "type": "item",
                "description": "1.42m x 0.61m x 1.91m (DWH)",
                "icon": <ItemIcon src={bathroomTub2Panel} />,
                "panelIcon": bathroomTub2Panel,
                "boardIcon": bathroomTub2Board,
                "height": 35,
                "width": 85,
                "zIndex":5
            },
            {
                "key":"bathroom sink2",
                "title": "Sink 2",
                "icon": <ItemIcon src={kitchenAvenuePiece1Panel} />,
                "panelIcon": kitchenAvenuePiece1Panel,
                "boardIcon": kitchenAvenuePiece1Board,
                "type": "item",
                "description": "1.42m x 0.61m x 1.91m (DWH)",
                "height": 42,
                "width": 60,
                "zIndex":5
            },
           
            {
                "key": "showerDoor1",
                "title": "Shower Door 1",
                "type": "item",
                "description": "1.19m x 0.91m x 2.01m (DWH)",
                "icon": <ItemIcon src={bathroomShowerDoor1Panel} />,
                "panelIcon": bathroomShowerDoor1Panel,
                "boardIcon": bathroomShowerDoor1Board,
                "height": 60,
                "width": 80,
                "zIndex":2
            },
            /*
            {
                "key": "showerDoor2",
                "title": "Shower Door 2",
                "type": "item",
                "description": "1.60m x 0.10m x 2.01m (DWH)",
                "icon": <ItemIcon src={bathroomShowerDoor2Panel} />,
                "panelIcon": bathroomShowerDoor2Panel,
                "boardIcon": bathroomShowerDoor2Board,
                "height": 6,
                "width": 88
            },
            */
            {
                "key": "showerDoor3",
                "title": "Shower Door 2",
                "type": "item",
                "description": "0.99m x 0.99m x 1.91m (DWH)",
                "icon": <ItemIcon src={bathroomShowerDoor3Panel} />,
                "panelIcon": bathroomShowerDoor3Panel,
                "boardIcon": bathroomShowerDoor3Board,
                "height": 60,
                "width": 60,
                "zIndex":2
            },
            {
                "key": "showerDoor4",
                "title": "Shower Door 3",
                "type": "item",
                "description": "1.75m x 1.12m x 2.54m (DWH)",
                "icon": <ItemIcon src={bathroomShowerDoor4Panel} />,
                "panelIcon": bathroomShowerDoor4Panel,
                "boardIcon": bathroomShowerDoor4Board,
                "height": 65,
                "width": 120,
                "zIndex":2
            },
            /*
            {
                "key": "showerDoor5",
                "title": "Shower Door 5",
                "type": "item",
                "description": "1.75m x 1.12m x 2.54m (DWH)",
                "icon": <ItemIcon src={bathroomShowerDoor5Panel} />,
                "panelIcon": bathroomShowerDoor5Panel,
                "boardIcon": bathroomShowerDoor5Board,
                "height": 14,
                "width": 217
            },
            */
            {
                "key": "toilet1",
                "title": "Toilet 1",
                "type": "item",
                "description": "0.38m x 0.71m x 0.71m (DWH)",
                "icon": <ItemIcon src={bathroomToilet1Panel} />,
                "panelIcon": bathroomToilet1Panel,
                "boardIcon": bathroomToilet1Board,
                "height": 40,
                "width": 25,
                "zIndex":3
            },
            {
                "key": "toilet2",
                "title": "Pissour",
                "type": "item",
                "description": "0.36m x 0.38m x 1.09m (DWH)",
                "icon": <ItemIcon src={bathroomToilet2Panel} />,
                "panelIcon": bathroomToilet2Panel,
                "boardIcon": bathroomToilet2Board,
                "height": 30,
                "width": 30,
                "zIndex":3
            },
            {
                "key": "toilet3",
                "title": "Toilet 2",
                "type": "item",
                "description": "0.43m x 0.58m x 0.25m (DWH)",
                "icon": <ItemIcon src={bathroomToilet3Panel} />,
                "panelIcon": bathroomToilet3Panel,
                "boardIcon": bathroomToilet3Board,
                "height": 30,
                "width": 25,
                "zIndex":2
            },
            {
                "key": "toilet4",
                "title": "Toilet 3",
                "type": "item",
                "description": "0.41m x 0.69m x 0.53m (DWH)",
                "icon": <ItemIcon src={bathroomToilet4Panel} />,
                "panelIcon": bathroomToilet4Panel,
                "boardIcon": bathroomToilet4Board,
                "height": 40,
                "width": 25,
                "zIndex":2
            },
            {
                "key": "tub1",
                "title": "Tub 1",
                "type": "item",
                "description": "1.50m x 0.71m x 0.84m (DWH)",
                "icon": <ItemIcon src={bathroomTub1Panel} />,
                "panelIcon": bathroomTub1Panel,
                "boardIcon": bathroomTub1Board,
                "height": 40,
                "width": 90,
                "zIndex":2
            },
            {
                "key": "tub3",
                "title": "Tub 2",
                "type": "item",
                "description": "1.83m x 0.97m x 0.91m (DWH)",
                "icon": <ItemIcon src={bathroomTub3Panel} />,
                "panelIcon": bathroomTub3Panel,
                "boardIcon": bathroomTub3Board,
                "height": 50,
                "width": 85,
                "zIndex":2
            },
            {
                "key": "vanity1",
                "title": "Sink 3",
                "type": "item",
                "description": "0.51m x 0.84m x 1.75m (DWH)",
                "icon": <ItemIcon src={bathroomVanity1Panel} />,
                "panelIcon": bathroomVanity1Panel,
                "boardIcon": bathroomVanity1Board,
                "height": 30,
                "width": 70,
                "zIndex":2
            },
            {
                "key": "vanity2",
                "title": "Bathroom Cabinet Set",
                "type": "item",
                "description": "1.60m x 0.64m x 2.59m (DWH)",
                "icon": <ItemIcon src={bathroomVanity2Panel} />,
                "panelIcon": bathroomVanity2Panel,
                "boardIcon": bathroomVanity2Board,
                "height": 51,
                "width": 116,
                "zIndex":2
            },
            {
                "key": "vanity3",
                "title": "Bathroom Shelf",
                "type": "item",
                "description": "0.56m x 0.30m x 1.35m (DWH)",
                "icon": <ItemIcon src={bathroomVanity3Panel} />,
                "panelIcon": bathroomVanity3Panel,
                "boardIcon": bathroomVanity3Board,
                "height": 29,
                "width": 88,
                "zIndex":3
            },
            {
                "key": "vanity4",
                "title": "Sink 4",
                "type": "item",
                "description": "1.57m x 0.64m x 0.86m (DWH)",
                "icon": <ItemIcon src={bathroomVanity4Panel} />,
                "panelIcon": bathroomVanity4Panel,
                "boardIcon": bathroomVanity4Board,
                "height": 16,
                "width": 36,
                "zIndex":3
            },
            {
                "key": "vanity5",
                "title": "Sink 5",
                "type": "item",
                "description": "1.02m x 0.48m x 0.64m (DWH)",
                "icon": <ItemIcon src={bathroomVanity5Panel} />,
                "panelIcon": bathroomVanity5Panel,
                "boardIcon": bathroomVanity5Board,
                "height": 27,
                "width": 60,
                "zIndex":3
            },
            {
                "key": "vanity6",
                "title": "Sink 6",
                "type": "item",
                "description": "0.66m x 0.48m x 0.30m (DWH)",
                "icon": <ItemIcon src={bathroomVanity6Panel} />,
                "panelIcon": bathroomVanity6Panel,
                "boardIcon": bathroomVanity6Board,
                "height": 22,
                "width": 32,
                "zIndex":3
            },
            {
                "key": "bathroomCabinet1",
                "title": "Cabinet 1",
                "type": "item",
                "description": "1.47m x 0.38m x 0.51m (DWH)",
                "icon": <ItemIcon src={bathroomCabinet1Panel} />,
                "panelIcon": bathroomCabinet1Panel,
                "boardIcon": bathroomCabinet1Board,
                "height": 27,
                "width": 100,
                "zIndex":3
            },
            {
                "key": "bathroomCabinet2",
                "title": "Cabinet 2",
                "type": "item",
                "description": "1.45m x 0.38m x 0.51m (DWH)",
                "icon": <ItemIcon src={bathroomCabinet2Panel} />,
                "panelIcon": bathroomCabinet2Panel,
                "boardIcon": bathroomCabinet2Board,
                "height": 29,
                "width": 100,
                "zIndex":3
            }
        ]
    },
    {
        "key": "electronics",
        "title": "Electronics",
        "icon": <ElectronicsIcon />,
        "type": "menu",
        "children": [
            {
                "key": "air-conditioners",
                "title": "Air Conditioners",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "air-conditioner-1",
                        "title": "Air Conditioner 1",
                        "type": "item",
                        "description": "0.91m x 0.20m x 0.28m (DWH)",
                        "icon": <ItemIcon src={airconditioner1Panel} />,
                        "panelIcon": airconditioner1Panel,
                        "boardIcon": airconditioner1Board,
                        "height": 10,
                        "width": 40,
                        "zIndex":4
                    },
                    {
                        "key": "air-conditioner-2",
                        "title": "Air Conditioner 2",
                        "type": "item",
                        "description": "0.53m x 0.30m x 1.83m (DWH)",
                        "icon": <ItemIcon src={airconditioner2Panel} />,
                        "panelIcon": airconditioner2Panel,
                        "boardIcon": airconditioner2Board,
                        "height": 23,
                        "width": 39,
                        "zIndex":4
                    },
                    {
                        "key": "air-conditioner-3",
                        "title": "Fan",
                        "type": "item",
                        "description": "0.97m x 1.47m x 1.88m (DWH)",
                        "icon": <ItemIcon src={airconditioner3Panel} />,
                        "panelIcon": airconditioner3Panel,
                        "boardIcon": airconditioner3Board,
                        "height": 12,
                        "width": 16,
                        "zIndex":4
                    },
                    {
                        "key": "air-conditioner-4",
                        "title": "Air Conditioner 3",
                        "type": "item",
                        "description": "0.86m x 0.23m x 0.28m (DWH)",
                        "icon": <ItemIcon src={airconditioner4Panel} />,
                        "panelIcon": airconditioner4Panel,
                        "boardIcon": airconditioner4Board,
                        "height": 10,
                        "width": 40,
                        "zIndex":4
                    }

                ]
            },
            {
                "key": "computers",
                "title": "Computers",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "computer1",
                        "title": "Computer 1",
                        "type": "item",
                        "description": "0.30m x 0.28m x 0.20m (DWH)",
                        "icon": <ItemIcon src={computer1Panel} />,
                        "panelIcon": computer1Panel,
                        "boardIcon": computer1Board,
                        "height": 12,
                        "width": 17,
                        "zIndex":5
                    },
                    {
                        "key": "computer2",
                        "title": "Computer 2",
                        "type": "item",
                        "description": "0.66m x 0.20m x 0.53m (DWH)",
                        "icon": <ItemIcon src={computer2Panel} />,
                        "panelIcon": computer2Panel,
                        "boardIcon": computer2Board,
                        "height": 10,
                        "width": 27,
                        "zIndex":5
                    },
                    {
                        "key": "computer3",
                        "title": "Computer 3",
                        "type": "item",
                        "description": "1.35m x 0.74m x 0.71m (DWH)",
                        "icon": <ItemIcon src={computer3Panel} />,
                        "panelIcon": computer3Panel,
                        "boardIcon": computer3Board,
                        "height": 30,
                        "width": 60,
                        "zIndex":5
                    },
                    {
                        "key": "computer4",
                        "title": "Computer 4",
                        "type": "item",
                        "description": "0.76m x 0.43m x 0.51m (DWH)",
                        "icon": <ItemIcon src={computer4Panel} />,
                        "panelIcon": computer4Panel,
                        "boardIcon": computer4Board,
                        "height": 12,
                        "width": 29,
                        "zIndex":5
                    },
                    {
                        "key": "computer5",
                        "title": "Printer 1",
                        "type": "item",
                        "description": "0.43m x 0.51m x 0.46m (DWH)",
                        "icon": <ItemIcon src={computer5Panel} />,
                        "panelIcon": computer5Panel,
                        "boardIcon": computer5Board,
                        "height": 20,
                        "width": 20,
                        "rotation": 0,
                        "zIndex":5
                    },
                    {
                        "key": "computer6",
                        "title": "Printer 2",
                        "type": "item",
                        "description": "0.53m x 0.38m x 0.46m (DWH)",
                        "icon": <ItemIcon src={computer6Panel} />,
                        "panelIcon": computer6Panel,
                        "boardIcon": computer6Board,
                        "height": 15,
                        "width": 21,
                        "zIndex":5
                    },
                    {
                        "key": "computer7",
                        "title": "Projector Screen",
                        "type": "item",
                        "description": "2.21m x 0.81m x 2.95m (DWH)",
                        "icon": <ItemIcon src={computer7Panel} />,
                        "panelIcon": computer7Panel,
                        "boardIcon": computer7Board,
                        "height": 31,
                        "width": 63,
                        "zIndex":5
                    }

                ]
            },
            {
                "key": "radiators",
                "title": "Radiators",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "radiator1",
                        "title": "Radiator 1",
                        "type": "item",
                        "description": "1.02m x 0.10m x 0.66m (DWH)",
                        "icon": <ItemIcon src={radiator1Panel} />,
                        "panelIcon": radiator1Panel,
                        "boardIcon": radiator1Board,
                        "height": 5,
                        "width": 40,
                        "zIndex":4
                    },
                    {
                        "key": "radiator2",
                        "title": "Radiator 2",
                        "type": "item",
                        "description": "0.94m x 0.20m x 0.61m (DWH)",
                        "icon": <ItemIcon src={radiator2Panel} />,
                        "panelIcon": radiator2Panel,
                        "boardIcon": radiator2Board,
                        "height": 7,
                        "width": 40,
                        "zIndex":4
                    },
                    /*
                    {
                        "key": "radiator3",
                        "title": "Radiator 3",
                        "type": "item",
                        "description": "1'4\" x 1'0\" x 2'7\"",
                        "icon": <ItemIcon src={radiator3Panel} />,
                        "panelIcon": radiator3Panel,
                        "boardIcon": radiator3Board,
                        "height": 15,
                        "width": 20
                    },
                    */
                    {
                        "key": "radiator4",
                        "title": "Radiator 3",
                        "type": "item",
                        "description": "0.46m x 0.13m x 1.07m (DWH)",
                        "icon": <ItemIcon src={radiator4Panel} />,
                        "panelIcon": radiator4Panel,
                        "boardIcon": radiator4Board,
                        "height": 5,
                        "width": 21,
                        "zIndex":4
                    }

                ]
            },
            {
                "key": "televisions",
                "title": "Televisions",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "television1",
                        "title": "Television 1",
                        "type": "item",
                        "description": "2.24m x 0.03m x 1.27m (DWH)",
                        "icon": <ItemIcon src={television1Panel} />,
                        "panelIcon": television1Panel,
                        "boardIcon": television1Board,
                        "height": 5,
                        "width": 70,
                        "zIndex":4
                    },
                    {
                        "key": "television2",
                        "title": "Television 2",
                        "type": "item",
                        "description": "1.35m x 0.28m x 0.84m (DWH)",
                        "icon": <ItemIcon src={television2Panel} />,
                        "panelIcon": television2Panel,
                        "boardIcon": television2Board,
                        "height": 11,
                        "width": 72,
                        "zIndex":4
                    },
                    {
                        "key": "television3",
                        "title": "Television 3",
                        "type": "item",
                        "description": "1.68m x 0.20m x 1.07m (DWH)",
                        "icon": <ItemIcon src={television3Panel} />,
                        "panelIcon": television3Panel,
                        "boardIcon": television3Board,
                        "height": 10,
                        "width": 70,
                        "zIndex":4
                    }

                ]
            },
            {
                "key": "white-goods",
                "title": "White Goods",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [

                    {
                        "key": "white-goods1",
                        "title": "Air Fryer",
                        "type": "item",
                        "description": "0.23m x 0.30m x 0.30m (DWH)",
                        "icon": <ItemIcon src={whiteGoods1Panel} />,
                        "panelIcon": whiteGoods1Panel,
                        "boardIcon": whiteGoods1Board,
                        "rotation": 0,
                        "height": 25,
                        "width": 16,
                        "zIndex":5
                    },
                    {
                        "key": "white-goods2",
                        "title": "Dishwasher",
                        "type": "item",
                        "description": "0.58m x 0.66m x 0.86m (DWH)",
                        "icon": <ItemIcon src={whiteGoods2Panel} />,
                        "panelIcon": whiteGoods2Panel,
                        "boardIcon": whiteGoods2Board,
                        "height": 23,
                        "width": 26,
                        "zIndex":3
                    },
                    {
                        "key": "white-goods3",
                        "title": "Microwave",
                        "type": "item",
                        "description": "0.51m x 0.43m x 0.30m (DWH)",
                        "icon": <ItemIcon src={whiteGoods3Panel} />,
                        "panelIcon": whiteGoods3Panel,
                        "boardIcon": whiteGoods3Board,
                        "height": 11,
                        "width": 18,
                        "zIndex":5
                    },
                    {
                        "key": "white-goods4",
                        "title": "Refrigerator 1",
                        "type": "item",
                        "description": "0.91m x 0.81m x 1.78m (DWH)",
                        "icon": <ItemIcon src={whiteGoods4Panel} />,
                        "panelIcon": whiteGoods4Panel,
                        "boardIcon": whiteGoods4Board,
                        "height": 35,
                        "width": 38,
                        "zIndex":2
                    },
                    {
                        "key": "white-goods5",
                        "title": "Refrigerator 2",
                        "type": "item",
                        "description": "0.61m x 0.66m x 1.78m (DWH)",
                        "icon": <ItemIcon src={whiteGoods5Panel} />,
                        "panelIcon": whiteGoods5Panel,
                        "boardIcon": whiteGoods5Board,
                        "height": 35,
                        "width": 38,
                        "zIndex":2
                    },
                    {
                        "key": "white-goods6",
                        "title": "Washing Machine",
                        "type": "item",
                        "description": "593 x 600 x 849 mm",
                        "icon": <ItemIcon src={whiteGoods6Panel} />,
                        "panelIcon": whiteGoods6Panel,
                        "boardIcon": whiteGoods6Board,
                        "height": 27,
                        "width": 27,
                        "zIndex":2
                    },
                    {
                        "key": "white-goods7",
                        "title": "Wall Oven",
                        "icon": <ItemIcon src={kitchenWallOvens1Panel} />,
                        "panelIcon": kitchenWallOvens1Panel,
                        "boardIcon": kitchenWallOvens1Board,
                        "type": "item",
                        "description": "0.76m x 0.69m x 0.71m (DWH)",
                        "height": 34,
                        "width": 38,
                        "zIndex":3
                    },
                    {
                        "key": "white-goods8",
                        "title": "Range 1",
                        "icon": <ItemIcon src={kitchenGasDoubleOvenRange1Panel} />,
                        "panelIcon": kitchenGasDoubleOvenRange1Panel,
                        "boardIcon": kitchenGasDoubleOvenRange1Board,
                        "type": "item",
                        "description": "0.76m x 0.79m x 1.19m (DWH)",
                        "height": 30,
                        "width": 31,
                        "zIndex":3
                    },
                    {
                        "key": "Range 2",
                        "title": "Range 2",
                        "icon": <ItemIcon src={kitchenRanges1Panel} />,
                        "panelIcon": kitchenRanges1Panel,
                        "boardIcon": kitchenRanges1Board,
                        "type": "item",
                        "description": "0.58m x 0.53m x 0.58m (DWH)",
                        "height": 30,
                        "width": 33,
                        "zIndex":3
                    },


                ]
            },
            {
                "key": "others",
                "title": "Others",
                "icon": <ElectronicsIcon />,
                "type": "menu",
                "children": [
                    {
                        "key": "other-electronics1",
                        "title": "Coffee Machine 1",
                        "type": "item",
                        "description": "325 x 586 x 657 mm",
                        "icon": <ItemIcon src={otherElectronics1Panel} />,
                        "panelIcon": otherElectronics1Panel,
                        "boardIcon": otherElectronics1Board,
                        "height": 10,
                        "width": 10,
                        "zIndex":5
                    },
                    {
                        "key": "other-electronics2",
                        "title": "Coffee Machine 2",
                        "type": "item",
                        "description": "447 x 456 x 359 mm",
                        "icon": <ItemIcon src={otherElectronics2Panel} />,
                        "panelIcon": otherElectronics2Panel,
                        "boardIcon": otherElectronics2Board,
                        "height": 14,
                        "width": 11,
                        "zIndex":5
                    },
                    {
                        "key": "other-electronics3",
                        "title": "Office Phone",
                        "type": "item",
                        "description": "189 x 303 x 51 mm",
                        "icon": <ItemIcon src={otherElectronics3Panel} />,
                        "panelIcon": otherElectronics3Panel,
                        "boardIcon": otherElectronics3Board,
                        "height": 15,
                        "width": 15,
                        "rotation": 0,
                        "zIndex":5
                    },
                    {
                        "key": "other-electronics4",
                        "title": "Sound Box",
                        "type": "item",
                        "description": "0.28m x 0.43m x 1.09m (DWH)",
                        "icon": <ItemIcon src={otherElectronics4Panel} />,
                        "panelIcon": otherElectronics4Panel,
                        "boardIcon": otherElectronics4Board,
                        "height": 15,
                        "width": 10,
                        "zIndex":3
                    },
                    {
                        "key": "other-electronics5",
                        "title": "Sweeping Robot",
                        "type": "item",
                        "description": "0.36m x 0.36m x 0.10m (DWH)",
                        "icon": <ItemIcon src={otherElectronics5Panel} />,
                        "panelIcon": otherElectronics5Panel,
                        "boardIcon": otherElectronics5Board,
                        "height": 8,
                        "width": 8,
                        "zIndex":4
                    },
                    {
                        "key": "other-electronics6",
                        "title": "Vacuum Cleaner",
                        "type": "item",
                        "description": "0.25m x 0.23m x 1.07m (DWH)",
                        "icon": <ItemIcon src={otherElectronics6Panel} />,
                        "panelIcon": otherElectronics6Panel,
                        "boardIcon": otherElectronics6Board,
                        "rotation": 180,
                        "height": 28,
                        "width": 16,
                        "zIndex":5
                    }
                ]
            }
        ]
    },
    {
        "key": "lightings",
        "title": "Lightings",
        "icon": <LightingIcon />,
        "type": "menu",
        "children": [
            {
                "key": "ceilinglights",
                "title": "Ceiling Lights",
                "icon": <LightingIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Ceiling Light 1",
                        "icon": <ItemIcon src={ceilingLight1Panel} />,
                        "boardIcon": ceilingLight1Board,
                        "panelIcon": ceilingLight1Panel,
                        "type": "item",
                        "description": "0.71m x 0.71m x 0.99m (DWH)",
                        "height": 28,
                        "width": 28,
                        "zIndex":4
                    },
                    {
                        "title": "Ceiling Light 2",
                        "icon": <ItemIcon src={ceilingLight2Panel} />,
                        "boardIcon": ceilingLight2Board,
                        "panelIcon": ceilingLight2Panel,
                        "type": "item",
                        "description": "0.15m x 0.15m x 1.19m (DWH)",
                        "height": 6,
                        "width": 6,
                        "zIndex":4
                    },
                    {
                        "title": "Ceiling Light 3",
                        "icon": <ItemIcon src={ceilingLight3Panel} />,
                        "boardIcon": ceilingLight3Board,
                        "panelIcon": ceilingLight3Panel,
                        "type": "item",
                        "description": "0.97m x 0.97m x 0.51m (DWH)",
                        "height": 38,
                        "width": 38,
                        "zIndex":4
                    },
                    {
                        "title": "Ceiling Light 4",
                        "icon": <ItemIcon src={ceilingLight4Panel} />,
                        "boardIcon": ceilingLight4Board,
                        "panelIcon": ceilingLight4Panel,
                        "type": "item",
                        "description": "0.61m x 0.61m x 1.02m (DWH)",
                        "height": 20,
                        "width": 20,
                        "zIndex":4
                    },
                    {
                        "title": "Ceiling Light 5",
                        "icon": <ItemIcon src={ceilingLight5Panel} />,
                        "boardIcon": ceilingLight5Board,
                        "panelIcon": ceilingLight5Panel,
                        "type": "item",
                        "description": "0.89m x 0.10m x 0.58m (DWH)",
                        "height": 35,
                        "width": 4,
                        "zIndex":4
                    }
                ]
            },
            {
                "key": "floorlamps",
                "title": "Floor Lamps",
                "icon": <LightingIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Floor Lamp 1",
                        "icon": <ItemIcon src={floorLamp1Panel} />,
                        "boardIcon": floorLamp1Board,
                        "panelIcon": floorLamp1Panel,
                        "type": "item",
                        "description": "0.38m x 0.69m x 1.52m (DWH)",
                        "height": 27,
                        "width": 21,
                        "zIndex":4
                    },
                    {
                        "title": "Floor Lamp 2",
                        "icon": <ItemIcon src={floorLamp2Panel} />,
                        "boardIcon": floorLamp2Board,
                        "panelIcon": floorLamp2Panel,
                        "type": "item",
                        "description": "0.43m x 0.43m x 1.30m (DWH)",
                        "height": 21,
                        "width": 21,
                        "zIndex":4
                    },
                    {
                        "title": "Floor Lamp 3",
                        "icon": <ItemIcon src={floorLamp3Panel} />,
                        "boardIcon": floorLamp3Board,
                        "panelIcon": floorLamp3Panel,
                        "type": "item",
                        "description": "0.51m x 1.55m x 1.75m (DWH)",
                        "height": 25,
                        "width": 8,
                        "zIndex":4
                    },
                    {
                        "title": "Floor Lamp 4",
                        "icon": <ItemIcon src={floorLamp4Panel} />,
                        "boardIcon": floorLamp4Board,
                        "panelIcon": floorLamp4Panel,
                        "type": "item",
                        "description": "0.30m x 0.30m x 1.73m (DWH)",
                        "height": 18,
                        "width": 18,
                        "zIndex":4
                    },
                    {
                        "title": "Floor Lamp 5",
                        "icon": <ItemIcon src={floorLamp5Panel} />,
                        "boardIcon": floorLamp5Board,
                        "panelIcon": floorLamp5Panel,
                        "type": "item",
                        "description": "0.79m x 0.61m x 2.16m (DWH)",
                        "height": 20,
                        "width": 31,
                        "zIndex":4
                    }

                ]
            },
            {
                "key": "tablelamps",
                "title": "Table Lamps",
                "icon": <LightingIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Table Lamp 1",
                        "icon": <ItemIcon src={tableLamp1Panel} />,
                        "boardIcon": tableLamp1Board,
                        "panelIcon": tableLamp1Panel,
                        "type": "item",
                        "description": "0.46m x 0.46m x 0.56m (DWH)",
                        "height": 23,
                        "width": 24,
                        "zIndex":5
                    },
                    {
                        "title": "Table Lamp 2",
                        "icon": <ItemIcon src={tableLamp2Panel} />,
                        "boardIcon": tableLamp2Board,
                        "panelIcon": tableLamp2Panel,
                        "type": "item",
                        "description": "0.43m x 0.30m x 0.58m (DWH)",
                        "height": 17,
                        "width": 24,
                        "zIndex":5
                    },
                    {
                        "title": "Table Lamp 3",
                        "icon": <ItemIcon src={tableLamp3Panel} />,
                        "boardIcon": tableLamp3Board,
                        "panelIcon": tableLamp3Panel,
                        "type": "item",
                        "description": "0.20m x 0.61m x 0.53m (DWH)",
                        "height": 22,
                        "width": 7,
                        "zIndex":5
                    },
                    {
                        "title": "Table Lamp 4",
                        "icon": <ItemIcon src={tableLamp4Panel} />,
                        "boardIcon": tableLamp4Board,
                        "panelIcon": tableLamp4Panel,
                        "type": "item",
                        "description": "0.33m x 0.28m x 0.46m (DWH)",
                        "height": 16,
                        "width": 16,
                        "zIndex":5
                    }

                ]
            },
            {
                "key": "walllamps",
                "title": "Wall Lamps",
                "icon": <LightingIcon />,
                "type": "menu",
                "children": [
                    {
                        "title": "Wall Lamp 1",
                        "icon": <ItemIcon src={wallLamp1Panel} />,
                        "boardIcon": wallLamp1Board,
                        "panelIcon": wallLamp1Panel,
                        "type": "item",
                        "description": "0.15m x 0.23m x 0.48m (DWH)",
                        "height": 20,
                        "width": 14,
                        "zIndex":3
                    },
                    {
                        "title": "Wall Lamp 2",
                        "icon": <ItemIcon src={wallLamp2Panel} />,
                        "boardIcon": wallLamp2Board,
                        "panelIcon": wallLamp2Panel,
                        "type": "item",
                        "description": "0.18m x 0.20m x 0.56m (DWH)",
                        "height": 16,
                        "width": 16,
                        "zIndex":3
                    },
                    {
                        "title": "Wall Lamp 3",
                        "icon": <ItemIcon src={wallLamp3Panel} />,
                        "boardIcon": wallLamp3Board,
                        "panelIcon": wallLamp3Panel,
                        "type": "item",
                        "description": "0.46m x 0.15m x 0.10m (DWH)",
                        "height": 21,
                        "width": 87,
                        "zIndex":3
                    },
                    {
                        "title": "Wall Lamp 4",
                        "icon": <ItemIcon src={wallLamp4Panel} />,
                        "boardIcon": wallLamp4Board,
                        "panelIcon": wallLamp4Panel,
                        "type": "item",
                        "description": "1.14m x 0.13m x 0.41m (DWH)",
                        "height": 5,
                        "width": 45,
                        "zIndex":3
                    },
                    {
                        "title": "Wall Lamp 5",
                        "icon": <ItemIcon src={wallLamp5Panel} />,
                        "boardIcon": wallLamp5Board,
                        "panelIcon": wallLamp5Panel,
                        "type": "item",
                        "description": "0.51m x 0.30m x 0.79m (DWH)",
                        "height": 18,
                        "width": 28,
                        "zIndex":3
                    }

                ]
            },
        ]
    },
    
];