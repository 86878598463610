import { Alert, Button, Flex, Form, Radio , message as antmessage} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useTranslation } from 'react-i18next';



function DeleteForm({ cancel }) {

    const { deleteUser, error, message } = useUserAPI();
    const { user, dispatch } = useAuthContext();
    const [radioVal, setRadioVal] = useState("");
    const [messageVal, setMessageVal] = useState("");
    const [deleted, setDeleted] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    

    const onTextAreaChange = (e) => {
        setMessageVal(e.target.value);
    };

    const onRadioValChange = (e) => {
        setRadioVal(e.target.value);
    };

    const handleEmailClick = () => {
        window.open('https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@decobot.ai', '_blank');
    };



    return (
        <>
            {!deleted && <Form style={{ maxWidth: 600 }} layout='vertical'>
                <Form.Item>
                    <Flex vertical gap={25}>
                        <span style={{ fontSize: '22px' }}>{t(`We're sorry to see you go!`)}</span>
                        <span style={{ fontSize: '14px', width: '75%' }}>{t(`To help us improve, could you please take a moment to let us know why you're leaving?`)}</span>
                    </Flex>
                </Form.Item>
                <Form.Item>
                    <Flex vertical gap={25}>
                        <Radio.Group value={radioVal} onChange={onRadioValChange}>
                            <Flex vertical gap={20}>
                                <Radio value={"I found a better service"}>{t('I found a better service')}.</Radio>
                                <Radio value={"The features didn't meet my needs"}>{t(`The features didn't meet my needs.`)}</Radio>
                                <Radio value={" I had technical issues"}> {t('I had technical issues.')}.</Radio>
                                <Radio value={'I found it difficult to use'}> {t('I found it difficult to use.')}.</Radio>
                                <Radio value={'Other'}> {t('Other (please specify)')}</Radio>
                            </Flex>
                        </Radio.Group>
                        <TextArea
                            value={messageVal}
                            disabled={radioVal !== "Other"}
                            onChange={onTextAreaChange}
                            placeholder={t('Write your message here...')}
                            style={{ height: 120, backgroundColor: '#F9FBFD' }}
                        />
                        <span style={{ fontSize: 16, width: '100%' }}>
                            {t('Your feedback is valuable to us.Thank you for your time!')}
                        </span>
                    </Flex>

                </Form.Item>
                <Form.Item>
                    <Flex justify='flex-start' gap={15}>
                        <Button type='primary' onClick={() => cancel()}>{t('Keep Decobot.ai')}</Button>
                        <Button danger variant='outlined' onClick={async () => {
                            if (radioVal) {
                                setDeleted(true);
                                await deleteUser(user.userIdMongo, radioVal, messageVal);
                                localStorage.removeItem('user');
                            }
                            else {
                                antmessage.error('Please provide a feedback')
                            }
                        }} >{t('Continue to Cancel')}</Button>
                    </Flex>
                </Form.Item>
                {error && <Alert type='danger' message={message} />}
            </Form>}
            {deleted && <Form style={{ maxWidth: 600 }} layout='vertical'>
                <Form.Item>
                    <Flex vertical gap={25}>
                        <span style={{ fontSize: '22px' }}>{t('Your account has been successfully deleted.')}</span>
                        <span>{t(`Thank you for choosing Decobot.ai. We're sorry to see you go, and we hope to have the opportunity to serve you again in the future.`)}</span>
                        <span>{t(`If you have any further feedback or questions, please don't hesitate to contact us at`)} <span onClick={handleEmailClick} style={{ color: 'blue', cursor: 'pointer' }}>info@decobot.ai</span></span>
                    </Flex>
                </Form.Item>
                <Form.Item>
                    <Button style={{
                        backgroundColor: '#272CA1',
                        color: 'white'
                    }} onClick={() => dispatch({ type: 'LOGOUT' })}>{t('Done')}</Button>
                </Form.Item>
            </Form>}
        </>


    )
}

export default DeleteForm
