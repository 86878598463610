import { Alert, Button, Card, Col, Divider, Flex, Row } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import config from '../../config/config.json';
import { useAuthContext } from '../../hooks/contextHooks/useAuthContext';
import PaymentForm from '../paymentForm/paymentForm';


function PaymentMenu() {

    const [clientSecret, setClientSecret] = useState(null);
    const [paymentSuccedeed, setPaymentSucceeded] = useState(false);
    const { user } = useAuthContext();

    // Handles Cs
    const handleClientSecret = async (amount,content) => {
        try {
            const { data } = await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/payment/create-payment-intent', {
                currency: 'usd',
                amount: amount * 100,
                email: user.email,
                userId: user.userIdMongo
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            setClientSecret(data.clientSecret);
            await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/payment/save-payment-intent',{
                currency: 'usd',
                amount: amount,
                email: user.email,
                userId: user.userIdMongo,
                content: content
            },{
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
        } catch (e) {
            console.error('Error creating payment intent:', e);
        }
    };

    return (
        <div>
            <span style={{
                fontSize: 18,
            }}> Premium Content </span>
            <Divider />
            {clientSecret === null && <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                        title='3D Content'
                        hoverable
                    >
                        <Flex vertical justify='center' align='center' gap={10}>
                            <span>$15</span>
                            <Button onClick={() => handleClientSecret(15,"3D")}> Purchase </Button>
                        </Flex>

                    </Card>
                </Col>
                <Col xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                        title='AI Content'
                        hoverable
                    >
                        <Flex vertical  justify='center' align='center' gap={10}>
                            <span>$10</span>
                            <Button onClick={() => handleClientSecret(10,"AI")}> Purchase </Button>
                        </Flex>

                    </Card>
                </Col>
                <Col xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                        hoverable
                        title='Additional 2D Content'
                    >
                        <Flex vertical  justify='center' align='center' gap={10}>
                            <span>$5</span>
                            <Button  onClick={() => handleClientSecret(5,"2D")}> Purchase </Button>
                        </Flex>

                    </Card>
                </Col>
            </Row>}
            {clientSecret !== null && !paymentSuccedeed &&
                <div>
                    <PaymentForm
                        clientSecret={clientSecret}
                        setPaymentSucceeded={setPaymentSucceeded}
                    />
                </div>
            }
            {paymentSuccedeed && (
                <div>
                    <Alert
                        message="Success!"
                        description="Your payment has been successful!"
                        type="success"
                        showIcon
                    />
                </div>
            )}
        </div>
    )
}

export default PaymentMenu
