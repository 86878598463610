import { useState } from "react"
import axios from "axios";
import { useProjectContext } from "../../contextHooks/useProjectContext";
import { useAuthContext } from "../../contextHooks/useAuthContext";
import config from '../../../config/config.json';
import html2canvas from 'html2canvas';

export const useProjectAPI = () => {
    const {user} = useAuthContext();
    const {pDispatch,trigger} = useProjectContext();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");


    /**
     * Creates a new Project
     * @param {*Object} obj The Object
     */
    const createProject = async (obj) => {
        try {
            const result = await axios.post(config.REACT_APP_API_MAIN_ROUTE + "/projects",obj, {
                headers : {
                    Authorization : "Bearer " + user.token
                }
            });
            pDispatch({type:'TRIGGER'});
            return result.data;
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    }


    /**
     *
     * @param {*string} projectId The Parameter id
     * @param {*Object} obj The Request Body
     */
    const updateProject = async (projectId, obj) => {
        try {
            await axios.put(config.REACT_APP_API_MAIN_ROUTE + '/projects/' + projectId,
                obj,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
            pDispatch({ type: 'TRIGGER'})
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    }


     /**
     *
     * @param {*string} projectId The Project Id
     */
     const deleteProject = async (projectId) => {
        try {
            const result = await axios.delete(config.REACT_APP_API_MAIN_ROUTE + '/projects/' + projectId,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
            if(result){
                pDispatch({ type: 'TRIGGER' });
            }

        }
        catch (e) {
            setError(true);
            setMessage("An error occured, Could not delete the Project")
        }
    }


    /**
     * 
     */
    const getSnapshotDataPrev = async (projectId) => {
        try{
            const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + '/snapshots/'  + projectId ,{
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            return res.data;
        } catch(e){
            console.log(e);
        }
    };


    const saveSnapshotToDb = async (projectId,userId,snapshots) => {
        try {
            await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/snapshots',
                {
                    project_id : projectId,
                    user_id: userId,
                    snapshots: snapshots
                },
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    };
    /**
     * Save Last State Image
     * @param {*string} projectId 
     * @param {*string} userId 
     * @param {*} targetDiv 
     */
    const saveLastStateImage = async (projectId,userId,targetDiv) => {
        html2canvas(targetDiv, { scale: 0.5 }).then(canvas => {
            // Convert the canvas to a blob
            // Create a smaller canvas for resizing
            const scaleFactor = 0.5; // Adjust the scale factor to your preference (e.g., 0.5 for half-size)
            const resizedCanvas = document.createElement('canvas');
            resizedCanvas.width = canvas.width * scaleFactor;
            resizedCanvas.height = canvas.height * scaleFactor;

            // Draw the resized image on the smaller canvas
            const ctx = resizedCanvas.getContext('2d');
            ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
            resizedCanvas.toBlob(async blob => {
                // Create a new File object from the blob
                const file = new File([blob], 'screenshot.png', {
                    type: 'image/png',
                    lastModified: Date.now()
                });

                // Prepare the form data
                const formData = new FormData();
                formData.append('lastImg', file);
                formData.append('userId', userId); // Add your userId dynamically
                formData.append('projectId', projectId);
                formData.append('filename', 'lastImg.png');

                // Use Axios to send the POST request
                try {
                    const response = await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/screenshots/uploadlast', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: "Bearer " + user.token
                        }
                    });
                } catch (error) {
                    console.log('Error uploading last State:', error);
                }
            }, 'image/png');
        });
    };

    const getLastStateImage = async (projectId) => {
        try {
            const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + '/screenshots/lastImg/'  + projectId ,{
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            return res.data;
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    };
   

    return {error,message,updateProject, deleteProject, createProject,saveSnapshotToDb,getSnapshotDataPrev,saveLastStateImage,getLastStateImage};
}