import React, { useCallback } from 'react';
import html2canvas from 'html2canvas';
import { useAuthContext } from '../../contextHooks/useAuthContext';
import axios from 'axios';
import { message } from 'antd';
import config from '../../../config/config.json';
import { useScreenshotContext } from '../../contextHooks/useScreenshotContext';
import { useTranslation } from 'react-i18next';

export const UseScreenShotHooks = (id, screenshots = [], setScreenshots = () => { }) => {
    const { user } = useAuthContext();
    const { ssTrigger } = useScreenshotContext();
    const {t} = useTranslation();

    const fetchScreenshot = useCallback(async () => {
        const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/screenshots/all/" + user.userIdMongo, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
        let array = res.data.data;
        let updatedArray = array.map((item) => {
            let data = item.img.data.data;
            const base64String = btoa(
                new Uint8Array(data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            const mimeType = res.headers['content-type']; // Make sure your backend sets this header
            let newVal = `data:${mimeType};base64,${base64String}`;
            return { ...item, src: newVal };
        })
        setScreenshots(updatedArray);
    }, [user, ssTrigger]);

    /**
     * Capture ScreenShot
     */

    const captureScreenshot = () => {
        // Select the element to be captured
        const targetDiv = document.getElementById('whiteboard-content');
        // Use html2canvas to take the screenshot
        html2canvas(targetDiv, { scale: 1 }).then(canvas => {
            // Convert the canvas to a blob
            // Create a smaller canvas for resizing
            const scaleFactor = 1; // Adjust the scale factor to your preference (e.g., 0.5 for half-size)
            const resizedCanvas = document.createElement('canvas');
            resizedCanvas.width = canvas.width * scaleFactor;
            resizedCanvas.height = canvas.height * scaleFactor;

            // Draw the resized image on the smaller canvas
            const ctx = resizedCanvas.getContext('2d');
            ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
            resizedCanvas.toBlob(async blob => {
                // Create a new File object from the blob
                const file = new File([blob], 'screenshot.png', {
                    type: 'image/png',
                    lastModified: Date.now()
                });

                // Prepare the form data
                const formData = new FormData();
                formData.append('screenshot', file);
                formData.append('userId', user.userIdMongo); // Add your userId dynamically
                formData.append('projectId', id);
                formData.append('filename', 'screenshot.png');

                // Use Axios to send the POST request
                try {
                    const response = await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/screenshots/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: "Bearer " + user.token
                        }
                    });
                    await fetchScreenshot();
                    //console.log('Screenshot uploaded successfully:', response.data);
                } catch (error) {
                    console.error('Error uploading screenshot:', error);
                }
            }, 'image/png');
        });
        message.success(t("Screenshot saved successfully"));
    };
    return { captureScreenshot, fetchScreenshot }
}


