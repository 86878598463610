import React, { useEffect } from 'react';
import { Flex } from 'antd';
import styles from '../../../../pages/signin/signin.module.css';
import img from './interactiveImg.png';
import { useTranslation } from 'react-i18next';
import { useLngContext } from '../../../../hooks/contextHooks/useLngContext';
import i18next from 'i18next';
export default function InteractiveSphere() {
    const {t} = useTranslation();
    const {languageCode} = useLngContext();

    useEffect(() => {
        i18next.changeLanguage(languageCode);
    },[languageCode])

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            style={{
                height: "100vh",
                width: "50vw",
                background: "transparent",
                position: "relative",
                backgroundColor:'#7230FF'
            }}
        >
            <div>
                <img src={img} width={'95%'}/> 
            </div>
            <div
                style={{
                    position: "absolute",
                    zIndex: 1,
                    top: "2px",
                }}
            >
                <Flex vertical align='flex-start' justify='flex-start' style={{
                    width: "50vw",
                    gap: 12,
                    padding: '7vh',
                    alignItems:'center'
                }}>

                    <span className={styles.title}>{t('StartDesigningYourHouse')}</span>
                    <span className={styles.subheader}>
                       {t('Create your perfect house with just a few clicks!')}
                    </span>

                </Flex>

            </div>
        </Flex>

    )
}
