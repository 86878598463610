import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import enTranslation from './locales/gb/translation.json';
import trTranslation from './locales/tr/translation.json';
import uaTranslation from './locales/ua/translation.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      gb: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
      ua: {
        translation: uaTranslation,
      }
    },
    lng: 'gb', // default language
    fallbackLng: 'gb',
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
  });

export default i18n;
